import { Input, Directive } from '@angular/core';
import { IComponent } from '../../types/step-component.interface';
import { StepDefinition } from '../../types/step-definition';
import { Observable } from 'rxjs';

@Directive()
export abstract class AbstractStepComponent {
  @Input() metadata: IComponent;

  hasStepControls = true;

  getSubComponent(componentName: string) {
    return this.metadata.subcomponents.find((component: StepDefinition) => component.sysComponentName === componentName);
  }

  abstract canGoToNextStep(): boolean | Observable<boolean>;
  abstract saveHandler(): void;
}
