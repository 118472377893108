import { Directive } from '@angular/core';
import { UntypedFormControl, NG_VALIDATORS, Validator } from '@angular/forms';

@Directive({
  selector: '[nospaces][ngModel]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: NoSpacesValidator, multi: true }
  ]
})
export class NoSpacesValidator implements Validator {

  spaceRegex = /\s/;
  validate(control: UntypedFormControl) {
    if (control.value && this.spaceRegex.test(control.value)) {
      return { nospaces: true };
    }
    return null;
  }
}
