import { Component, OnInit } from '@angular/core';
import { ProgramGroup } from '../../../types/program-groups';
import { StoreService } from '../../../services/store.service';
import { PROGRAM_GROUP_INFO } from '../../../shared/constants';

@Component({
  selector: 'app-footer',
  template: `
    <div class="container lls-container">
      Need help? <a [href]="'mailto:' + mail">Email us</a> or call: {{ callText }}
    </div>
  `,
  styles: [`
    :host {
      display: block;
      background-color: #eee;
      border-top: 4px solid #e1e1e1;
      padding: 2em 0;
    }
  `]
})
export class FooterComponent implements OnInit {
  footerText: string;
  callText: string;
  mail: string;

  constructor(private storeService: StoreService) {}

  ngOnInit() {
    const pgName = this.storeService.getCurrentState().pgConfig.programGroupName;
    this.mail = PROGRAM_GROUP_INFO[pgName].contactMail;
    this.footerText = pgName;
    this.callText = PROGRAM_GROUP_INFO[pgName].callNumber;
  }
}
