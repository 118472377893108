import { AfterViewInit, Component, Input, ViewChild } from '@angular/core';
import { IComponent } from '../../../types/step-component.interface';
import { AbstractStepComponent } from '../../core/abstract-step-component';
import { StoreService } from '../../../services/store.service';
import { SubcomponentComponent } from '../../core/subcomponent.component';
import { ContactInfoComponent } from './subcomponents/contact-info/contact-info.component';
import { markAllFieldsAsTouched, scrollToErrorField, validateForm } from '../../../shared/utils';
import { UntypedFormGroup } from '@angular/forms';
import { DateOfBirthComponent } from './subcomponents/date-of-birth.component';

@Component({
  selector: 'app-event-personal-info-step',
  template: `
    <div *ngIf="(storeService.vm$ | async) as vm">
      <subcomponent #contactInfo [parentMetadata]="metadata" name="CONTACT_INFO"></subcomponent>
      <subcomponent #dateOfBirth [parentMetadata]="metadata" name="DATE_OF_BIRTH"></subcomponent>
    </div>
  `,
  styles: [`
    .contact-info-heading {
      font-weight: 100;
      font-size: 2.25em;
    }
  `]
})
export class PersonalInfoComponent extends AbstractStepComponent implements AfterViewInit {
  @Input() metadata: IComponent;
  @ViewChild('contactInfo') contactInfo: SubcomponentComponent;
  @ViewChild('dateOfBirth') dateOfBirth: SubcomponentComponent;

  contactInfoForm: UntypedFormGroup;
  dateOfBirthForm: UntypedFormGroup;

  constructor(
    public storeService: StoreService
  ){
    super();
  }

  ngAfterViewInit() {
    this.contactInfoForm = this.getContactInfoComponent().ngForm.form;
    this.dateOfBirthForm = this.getDateOfBirthComponent()?.form.form;
  }

  canGoToNextStep(): boolean {
    markAllFieldsAsTouched(this.contactInfoForm);
    validateForm(this.contactInfoForm);
    if (this.dateOfBirthForm) {
      markAllFieldsAsTouched(this.dateOfBirthForm);
      validateForm(this.dateOfBirthForm);
      if (!this.contactInfoForm.valid) {
        this.scrollToContactErrorField();
      } else {
        return this.dateOfBirthForm.valid;
      }
    } else {
      if (this.contactInfoForm.valid) {
        return true;
      } else {
        this.scrollToContactErrorField();
      }
    }
  }

  saveHandler(): void {
    this.storeService.updateState({ contactInfo: this.contactInfoForm.value });
    if (this.dateOfBirthForm) {
      this.storeService.updateState({ dateOfBirth: this.dateOfBirthForm.value });
    }
  }

  private getContactInfoComponent(): ContactInfoComponent {
    return this.contactInfo.componentInstance;
  }

  private getDateOfBirthComponent(): DateOfBirthComponent {
    return this.dateOfBirth.componentInstance;
  }

  private scrollToContactErrorField(): boolean {
    const form = this.contactInfoForm;
    scrollToErrorField(
      form,
      ['firstName', 'lastName', 'address1', 'postalCode',
        'city', 'stateID', 'emailAddress', 'phoneNumber'],
      'contact-'
    );
    return false;
  }
}
