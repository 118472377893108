<div *ngIf="(storeService.vm$ | async) as vm">
  <div>
    <input
      id="alternate-billing"
      type="checkbox"
      name="billing"
      [ngModel]="vm.useAlternateBilling"
      (ngModelChange)="storeService.updateState({ useAlternateBilling: $event })"
      #billing="ngModel">
    <label for="alternate-billing" class="d-inline">
      Use Alternate Billing Address
    </label>
  </div>

  <form id="alt-billing" *ngIf="billing.value" #form="ngForm" class="mt-3">
    <h3 class="mt-3">Alternate Billing Address</h3>
    <div class="form-row">
      <div class="form-group col-md-6">
        <label for="firstName">First Name</label>
        <input
          type="text"
          class="form-control"
          id="firstName"
          name="firstName"
          [(ngModel)]="alternateBillingData.firstName"
          required
          #firstName="ngModel">
        <control-errors [control]="firstName" name="First Name"></control-errors>
      </div>
      <div class="form-group col-md-6">
        <label for="lastName">Last Name</label>
        <input
          type="text"
          class="form-control"
          id="lastName"
          name="lastName"
          [(ngModel)]="alternateBillingData.lastName"
          required
          #lastName="ngModel">
        <control-errors [control]="lastName" name="Last Name"></control-errors>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-md-6">
        <label for="address1">Address</label>
        <input
          type="text"
          class="form-control"
          name="address1"
          id="address1"
          [(ngModel)]="alternateBillingData.address1"
          required
          #address1="ngModel">
        <control-errors [control]="address1" name="Address"></control-errors>
      </div>
      <div class="form-group col-md-6">
        <label for="address2">Apt., Suite, Unit (optional)</label>
        <input type="text" class="form-control" name="address2" id="address2" [(ngModel)]="alternateBillingData.address2">
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-md-3">
        <label for="postalCode">Zip Code</label>
        <input
          type="text"
          class="form-control"
          name="postalCode"
          id="postalCode"
          maxlength="5"
          [pattern]="zipValidator"
          [(ngModel)]="alternateBillingData.postalCode"
          required
          #postalCode="ngModel">
        <control-errors [control]="postalCode" name="Zip Code"></control-errors>
      </div>
      <div class="form-group col-md-6">
        <label for="city">City</label>
        <input
          type="text"
          class="form-control"
          name="city"
          id="city"
          [(ngModel)]="alternateBillingData.city"
          required
          #city="ngModel">
        <control-errors [control]="city" name="City"></control-errors>
      </div>
      <div class="form-group col-md-3">
        <label for="state">State</label>
        <select class="form-control form-select" name="state" id="state" [(ngModel)]="alternateBillingData.state" required #state="ngModel">
          <option class="form-control" *ngFor="let state of states" [ngValue]="state.name">{{state.description}}</option>
        </select>
        <div *ngIf="state.touched && state.invalid" class="invalid-feedback">
          State is required
        </div>
      </div>
    </div>
  </form>
</div>
