import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable, of } from 'rxjs';
import { StoreService } from '../services/store.service';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class StepDefinitionsResolver implements Resolve<Observable<any>> {

  constructor(
    private http: HttpClient,
    private storeService: StoreService,
    private toastrService: ToastrService
  ) {}

  resolve() {
    const pgName = this.storeService.getCurrentState().pgConfig.programGroupName;
    //add cache busting for step definitions since updates may not be seen without a cache refresh
    const endpoint = '/assets/mocks/step-definitions-' + pgName.toLowerCase() + '.json?t=' + (new Date()).getTime();
    return this.http.get(endpoint).pipe(
      catchError(err => {
        this.toastrService.error('Something went wrong.');
        return of(null);
      })
    );
  }
}
