import { AfterViewInit, Component, Input, OnDestroy, QueryList, ViewChildren } from '@angular/core';
import { NgForm } from '@angular/forms';
import { IComponent } from '../../../../../../types/step-component.interface';
import { Subscription } from 'rxjs';
import { StoreService } from '../../../../../../services/store.service';

@Component({
  selector: 'app-alternate-billing',
  templateUrl: 'alternate-billing.component.html'
})
export class AlternateBillingComponent implements AfterViewInit, OnDestroy {
  @Input() metadata: IComponent;
  @ViewChildren('form') ngForm$: QueryList<NgForm>;

  alternateBillingData: any = {state: null};
  zipValidator = /^\d{5}(?:[-\s]\d{4})?$/;
  states = this.storeService.getCurrentState().states;
  private subscription: Subscription;
  private formSubscription: Subscription;

  constructor(
    public storeService: StoreService
    ) {
  }

  ngAfterViewInit() {
    this.subscription = this.ngForm$.changes.subscribe(res => {
      if (this.ngForm$.last) {
        this.formSubscription = this.ngForm$.last.valueChanges.subscribe((values) => {
          this.storeService.updateState({ alternateBillingInfo: values });
        });
      } else {
        this.storeService.updateState({ alternateBillingInfo: null });
      }
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    if (this.formSubscription) {
      this.formSubscription.unsubscribe();
    }
  }
}

