<table>
  <thead>
  <tr>
    <th *ngFor="let header of columnDefs; let i = index">
      <span>
        {{ header.name }}
        <i *ngIf="isSortableColumn(header) && getSortDir(header) === 'asc'" class="fas fa-sort-amount-up" (click)="sortBy($event, header)"></i>
        <i *ngIf="isSortableColumn(header) && getSortDir(header) === 'desc'" class="fas fa-sort-amount-down" (click)="sortBy($event, header)"></i>
      </span>
    </th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let row of rows
        | tableSort : currentData.activeSort?.column?.sortField : currentData.activeSort.dir
        | paginate : { currentPage: currentData.currentPage, itemsPerPage: itemsPerPage, id: currentData.tableStorageKey }"
      (click)="onSelectRow(row, $event)" [ngClass]="{'is-selected': isEqual(row, currentData.selectedRow)}">
    <td *ngFor="let col of columnDefs">
      <ng-container *ngTemplateOutlet="col.cell.template; context: { $implicit: row }"></ng-container>
    </td>
    <td class="checked">
      <i class="fas fa-check"></i>
    </td>
  </tr>
  </tbody>
</table>

<pagination-controls *ngIf="currentData.hasPagination" [id]="currentData.tableStorageKey" (pageChange)="onPageChange($event)"></pagination-controls>
