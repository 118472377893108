import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'tableSort',
  pure: false
})
export class TableSortPipe implements PipeTransform {
  transform(rows: any[], column: string, dir: string): any {
    if (!column || !dir) {
      return rows;
    }

    return rows.sort((a, b) => {
      if (a[column] < b[column] ) { return dir === 'desc' ? -1 : 1; }
      if (a[column] > b[column] ) { return dir === 'desc' ? 1 : -1; }
      return 0;
    });
  }
}
