import { Component, Input } from '@angular/core';
import { IComponent } from '../../../../types/step-component.interface';
import { StoreService } from '../../../../services/store.service';

@Component({
  selector: 'app-kickstart',
  template: `
    <h3>{{metadata.attributes.HEADING}}</h3>
    <form #form="ngForm" class="mb-3">
      <input
        id="kickstart"
        type="checkbox"
        name="payment-checkbox"
        ngModel
        (ngModelChange)="storeService.updateState({
        showKickstartForm: $event,
        amount: null,
        useAlternateBilling: $event ? storeService.getCurrentState().useAlternateBilling : $event
        })"
      >
      <label for="kickstart" class="d-inline">
        {{metadata.attributes.CHECKBOX_LABEL}}
      </label>
    </form>
  `
})
export class KickstartComponent {
  @Input() metadata: IComponent;

  constructor(
    public storeService: StoreService
  ) {}
}
