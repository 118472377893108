import { Component, Input, ViewChild } from '@angular/core';
import { IComponent } from '../../../../types/step-component.interface';
import { StoreService } from '../../../../services/store.service';
import { NgForm } from '@angular/forms';
import { markAllFieldsAsTouched, validateForm } from '../../../../shared/utils';
import { BackendService } from '../../../../services/backend.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-event-search-by-zip',
  template: `
    <ng-container *ngIf="(storeService.vm$ | async) as vm">
      <h3>{{metadata.attributes.HEADING}}</h3>
      <label *ngIf="metadata.attributes.LABEL" for="zip">{{metadata.attributes.LABEL}}</label>
      <form (ngSubmit)="searchByZip()" #form="ngForm">
        <input class="form-control zip-code"
            name="zip"
            placeholder="ZIP code"
            [ngModel]="vm.zipCode"
            (ngModelChange)="handleZipCodeChange($event)"
            [pattern]="validator"
            required
            maxlength="5"
            #zip="ngModel"
        >
        <control-errors [control]="zip" name="Zip code"></control-errors>
        <button type="submit" class="btn btn-primary mt-3 mb-3">Continue</button>
      </form>
    </ng-container>
  `,
  styles: [`
    .zip-code {
      width: 200px;
    }
  `]
})
export class EventSearchByZipComponent {
  @Input() metadata: IComponent;
  @ViewChild('form') form: NgForm;
  zipCode: any;
  validator = /^\d{5}(?:[-\s]\d{4})?$/;

  constructor(
    private backendService: BackendService,
    public storeService: StoreService,
    public location: Location
  ) {
  }

  handleZipCodeChange(zipCode: string) {
    this.zipCode = zipCode;
  }

  searchByZip() {
    markAllFieldsAsTouched(this.form.form);
    validateForm(this.form.form);
    if (this.form.invalid) {
      return;
    }
    this.storeService.updateState({zipCode: this.zipCode});
    this.backendService.loadEvents(this.zipCode).subscribe();
    this.location.go(`event-zip-search/${this.zipCode}`);
  }
}
