import { Component, ComponentFactoryResolver, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { StepHostDirective } from '../../../directives/step-host.directive';
import { ComponentMappings } from '../../../shared/component-mappings';
import { StepDefinition } from '../../../types/step-definition';
import { AbstractStepComponent } from '../abstract-step-component';
import { StoreService } from '../../../services/store.service';
import { WizardService } from '../../../services/wizard.service';
import { combineLatest, isObservable } from 'rxjs';
import { ProgressService } from '../../../services/progress.service';
import { Angulartics2 } from 'angulartics2';
import { Angulartics2GoogleTagManager } from 'angulartics2';
import { DeepLinkingService } from '../../../services/deep-linking.service';

@Component({
  selector: 'app-wizard',
  templateUrl: './wizard.component.html',
  styleUrls: ['./wizard.component.scss']
})
export class WizardComponent implements OnInit {
  @ViewChild(StepHostDirective, {static: true}) stepHost: StepHostDirective;

  currentComponentInstance: AbstractStepComponent;
  currentStep: number;
  currentStepObject: StepDefinition;
  stepDefinitions: StepDefinition[];
  isLoading = false;

  constructor(
    public storeService: StoreService,
    private angulartics: Angulartics2,
    private gtm: Angulartics2GoogleTagManager,
    private activatedRoute: ActivatedRoute,
    private deepLinkingService: DeepLinkingService,
    private componentFactoryResolver: ComponentFactoryResolver,
    private progressService: ProgressService,
    private wizardService: WizardService
  ) {}

  ngOnInit() {
    // initialize GTM
    this.setupGtmTracking();
    this.storeService.updateState({urlParams: location.pathname});
    const deepLinking = this.activatedRoute.snapshot.data?.deepLinking;
    this.stepDefinitions = this.activatedRoute.snapshot.data.stepDefinitions;
    if (deepLinking) {
      this.currentStep = this.deepLinkingService.initialize(
        deepLinking,
        this.stepDefinitions,
        this.activatedRoute);
      this.storeService.updateState({currentStep: this.currentStep});
    }
    this.storeService.currentStep$.subscribe((o: number) => {
      this.currentStep = o;
      this.loadCurrentStep();
    });
  }

  nextStep() {
    this.isLoading = true;
    const canGoResult = this.canGoToNextStep();
    if (isObservable(canGoResult)) {
      this.progressService.block();
      canGoResult.subscribe(canGo => {
        if (canGo) {
          this.goNextStep();
        } else {
          this.isLoading = false;
        }
        this.progressService.unblock();
      }, () => {
        this.progressService.unblock();
      });
    } else if (canGoResult === true) {
      this.goNextStep();
    } else {
      this.isLoading = false;
    }
  }

  previousStep() {
    this.wizardService.previousStep();
  }

  private goNextStep() {
    this.currentComponentInstance.saveHandler();
    this.wizardService.nextStep();
  }

  private getComponentClass(name: string) {
    return ComponentMappings[name];
  }

  private canGoToNextStep() {
    return (this.currentComponentInstance.canGoToNextStep && this.currentComponentInstance.canGoToNextStep())
      || !this.currentComponentInstance.canGoToNextStep;
  }

  private loadCurrentStep() {
    this.isLoading = false;
    const step = this.stepDefinitions[this.storeService.getCurrentState().currentStep];
    this.currentStepObject = step;

    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(
      this.getComponentClass(step.sysComponentName)
    );

    const viewContainerRef = this.stepHost.viewContainerRef;
    viewContainerRef.clear();

    const componentRef = viewContainerRef.createComponent(componentFactory);
    (componentRef.instance as any).metadata = step;
    this.currentComponentInstance = componentRef.instance as AbstractStepComponent;
  }

  private setupGtmTracking() {
    /* tslint:disable */
    const envConfig = this.storeService.getCurrentState().envConfig;
    const gtmId = envConfig.googleTagManagerConfig[this.storeService.getCurrentState().pgConfig.programGroupName.toLowerCase()];
    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=<any>d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      '//www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer',gtmId);
    const iframeNode = document.createElement('iframe');
    iframeNode.setAttribute('src', `https://www.googletagmanager.com/ns.html?id=${gtmId}`);
    iframeNode.setAttribute('height', '0');
    iframeNode.setAttribute('width', '0');
    iframeNode.setAttribute('style', 'display:none;visibility:hidden');
    const noScript = document.createElement('noscript');
    noScript.appendChild(iframeNode);
    document.getElementsByTagName('body')[0].appendChild(noScript);
    this.gtm.startTracking();
    /* tslint:enable */

    combineLatest([
      this.storeService.currentStep$,
      this.activatedRoute.data
    ]).subscribe(([currentStep, data]) => {
      const state = this.storeService.getCurrentState();
      const stepTitle = data.stepDefinitions
        ? data.stepDefinitions[currentStep].attributes.STEP_TITLE
        : state.currentStep;
      this.angulartics.eventTrack.next({
        action: 'content-view',
        properties: {
          gtmCustom: {
            step: stepTitle
          }
        },
      });
    });
  }
}
