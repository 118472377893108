import { AfterViewInit, Component, OnDestroy, ViewChild } from '@angular/core';
import { StoreService } from '../../../../services/store.service';
import { NgForm } from '@angular/forms';
import { WizardService } from '../../../../services/wizard.service';
import { markAllFieldsAsTouched, scrollToErrorField, validateForm } from '../../../../shared/utils';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-sign-in-create-account',
  templateUrl: './create-account.component.html',
  styles: [`
    ul {
        list-style: none;
        padding: 0;
    }

    ul li {
        padding-left: 1.1em;
        background-position-y: center;
        background-repeat: no-repeat;
        background-image: url(data:image/svg+xml;charset=US-ASCII,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22utf-8%22%3F%3E%3C!DOCTYPE%20svg%20PUBLIC%20%22-%2F%2FW3C%2F%2FDTD%20SVG%201.1%2F%2FEN%22%20%22http%3A%2F%2Fwww.w3.org%2FGraphics%2FSVG%2F1.1%2FDTD%2Fsvg11.dtd%22%3E%3Csvg%20version%3D%221.1%22%20id%3D%22Layer_1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20x%3D%220px%22%20y%3D%220px%22%20%20width%3D%2214.938px%22%20height%3D%2215.125px%22%20viewBox%3D%220%200%2014.938%2015.125%22%20enable-background%3D%22new%200%200%2014.938%2015.125%22%20xml%3Aspace%3D%22preserve%22%3E%3Cpath%20fill%3D%22%23A5A5A5%22%20d%3D%22M7.49%2C0.159c-3.866%2C0-7%2C3.134-7%2C7s3.134%2C6.999%2C7%2C6.999c3.865%2C0%2C7-3.133%2C7-6.999S11.356%2C0.159%2C7.49%2C0.159z%20%20M7.49%2C12.848c-3.142%2C0-5.688-2.548-5.688-5.688c0-3.142%2C2.547-5.688%2C5.688-5.688c3.141%2C0%2C5.688%2C2.546%2C5.688%2C5.688%20C13.177%2C10.3%2C10.63%2C12.848%2C7.49%2C12.848z%22%2F%3E%3C%2Fsvg%3E);
    }

    ul li.checked {
        background-image: url(data:image/svg+xml;charset=US-ASCII,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22utf-8%22%3F%3E%3C!DOCTYPE%20svg%20PUBLIC%20%22-%2F%2FW3C%2F%2FDTD%20SVG%201.1%2F%2FEN%22%20%22http%3A%2F%2Fwww.w3.org%2FGraphics%2FSVG%2F1.1%2FDTD%2Fsvg11.dtd%22%3E%3Csvg%20version%3D%221.1%22%20id%3D%22Layer_1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20x%3D%220px%22%20y%3D%220px%22%20%20width%3D%2214.938px%22%20height%3D%2215.125px%22%20viewBox%3D%220%200%2014.938%2015.125%22%20enable-background%3D%22new%200%200%2014.938%2015.125%22%20xml%3Aspace%3D%22preserve%22%3E%3Cpath%20fill%3D%22%23A37E34%22%20d%3D%22M7.49%2C0.159c-3.866%2C0-7%2C3.134-7%2C7s3.134%2C6.999%2C7%2C6.999s7-3.133%2C7-6.999C14.49%2C3.293%2C11.356%2C0.159%2C7.49%2C0.159%20z%20M7.49%2C12.847c-3.141%2C0-5.688-2.547-5.688-5.688c0-3.142%2C2.547-5.688%2C5.688-5.688s5.688%2C2.546%2C5.688%2C5.688%20C13.177%2C10.3%2C10.63%2C12.847%2C7.49%2C12.847z%20M10.24%2C4.181C9.944%2C4.003%2C9.563%2C4.109%2C9.392%2C4.417L7.045%2C8.633l-1.308-1.59%20C5.5%2C6.77%2C5.187%2C6.628%2C4.89%2C6.806C4.592%2C6.984%2C4.469%2C7.412%2C4.661%2C7.687L6.48%2C9.901c0.137%2C0.177%2C0.292%2C0.282%2C0.454%2C0.312l0.002%2C0.003%20l0.017%2C0.001c0.036%2C0.006%2C0.586%2C0.106%2C0.822-0.316l2.694-4.839C10.64%2C4.754%2C10.537%2C4.359%2C10.24%2C4.181z%22%2F%3E%3C%2Fsvg%3E);
    }

    .password-requirements {
        background-color: #eee;
        border-radius: 4px;
        padding: 0.75em;
        border: 1px solid #D1D1D1;
        margin-bottom: 1.5em;
    }

    .notification-error {
        background-color: #ff6565;
        color: #7f0000;
        border-color: #cb0000;
        font-weight: 600;
        line-height: 1.2;
        border-radius: 4px;
        padding: 1.5em 1em;
    }

    .invalid-password-confirmation {
      border-color: #cb0000 !important;
      background: none !important;
    }

    @media only screen and (min-width: 768px) {
      .show-password-hint {
        display: block;
      }

      .hide-password-hint {
        display: none;
      }
    }
  `]
})
export class CreateAccountComponent implements OnDestroy {
  @ViewChild('form') form: NgForm;

  password = '';
  passwordConfirm = '';
  showPasswordHint = false;
  onDestroy$ = new Subject<void>();

  constructor(
    public storeService: StoreService,
    private wizardService: WizardService
  ) {}

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  openResetPasswordForm(email) {
    this.storeService.updateState({ signinState: 'forgot-password', email });
  }

  submit() {
    this.storeService.updateState({
      signupData: this.form.value,
      signinResult: {
        firstName: this.form.form.get('firstName').value,
        lastName: this.form.form.get('lastName').value,
        username: this.form.form.get('email').value,
        password: this.form.form.get('password').value,
      }
    });
    markAllFieldsAsTouched(this.form.form);
    validateForm(this.form.form);
    if (this.form.form.invalid) {
      const form = this.form.form;
      scrollToErrorField(
        form,
        ['firstName', 'lastName', 'email', 'password', 'passwordConfirm'],
        'account-'
      );
      return;
    } else {
      this.wizardService.nextStep();
    }
  }
}
