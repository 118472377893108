import { Component, Input, OnInit } from '@angular/core';
import { IComponent } from '../../../../../../types/step-component.interface';
import { StoreService } from '../../../../../../services/store.service';

@Component({
  selector: 'app-create-team-frstartend',
  template: `
    <div class="mt-4 mb-4" *ngIf="storeService.getCurrentState()?.createTeam?.teamType === 'SCHOOL'">
      <div class="form-row">
        <div class="form-group col-md-6">
          <label for="select">{{ metadata.attributes.LABEL }}</label>
          <select id="select"
            class="form-control form-select"
            [(ngModel)]="startDate"
            (change)="selectStartOption($event)"
            [ngClass]="{'ng-invalid': !valid, 'ng-valid': valid, 'ng-touched': touched}"
          >
            <option *ngFor="let option of options" [value]="option.val">{{option.label}}</option>
          </select>
        </div>
        <div class="form-group col-md-6">
          <label for="select">{{ metadata.attributes.LABEL2 }}</label>
          <select id="select"
            class="form-control form-select"
            [(ngModel)]="endDate"
            (change)="selectEndOption($event)"
            [ngClass]="{'ng-invalid': !valid, 'ng-valid': valid, 'ng-touched': touched}"
          >
            <option *ngFor="let option of options" [value]="option.val">{{option.label}}</option>
          </select>
        </div>
      </div>
      <div *ngIf="!valid" class="alert alert-danger" role="alert">
        {{ metadata?.attributes?.SELECT_ERROR_MESSAGE }}
      </div>
    </div>
  `
})
export class CreateTeamFundraiserStartEnd implements OnInit {
  @Input() metadata: IComponent;

  options: any;
  startDate: any;
  endDate: any;
  campaignStart = this.storeService.getCurrentState().selectedEvent?.campaignStartDate;
  campaignEnd = this.storeService.getCurrentState().selectedEvent?.campaignEndDate;
  valid = true;
  touched = false;

  constructor(
    public storeService: StoreService
  ) {}

  ngOnInit() {
    this.setOptions();
    let start = this.storeService.getCurrentState().createTeam?.fundraisingActivityStartMonth;
    if (start) {
      this.startDate = start;
    } else {
       this.updateStartDate(this.options[0].val);
    }

    let end = this.storeService.getCurrentState().createTeam?.fundraisingActivityEndMonth;
    if (end) {
      this.endDate = end;
    } else {
       this.updateEndDate(this.options[0].val);
    }
  }

  selectStartOption(event: any) {
    this.updateStartDate(event.target.value);
    this.validate();
  }

  selectEndOption(event: any) {
    this.updateEndDate(event.target.value);
    this.validate();
  }

  updateStartDate(value: number) {
    this.startDate = value;
    const createTeam = this.storeService.getCurrentState().createTeam || {};
    createTeam.fundraisingActivityStartMonth = value;
    this.storeService.updateState({createTeam: Object.assign({}, createTeam)});
  }

  updateEndDate(value: number) {
    this.endDate = value;
    const createTeam = this.storeService.getCurrentState().createTeam || {};
    createTeam.fundraisingActivityEndMonth = value;
    this.storeService.updateState({createTeam: Object.assign({}, createTeam)});
  }

  validate(): boolean {
    this.touched = true;
    let startOpt = this.options.filter((opt) => opt.val == this.startDate)[0];
    let endOpt = this.options.filter((opt) => opt.val == this.endDate)[0];
    this.valid = endOpt.index >= startOpt.index;
    return this.valid;
  }

  setOptions() {
     const campaignStartDate = new Date(this.campaignStart);
     const startYear = campaignStartDate.getFullYear();
     const campaignEndDate = new Date(this.campaignEnd);
     const endYear = campaignEndDate.getFullYear();
     this.options = [
       { index: 0, val: 6, label: 'June ' + startYear },
       { index: 1, val: 7, label: 'July ' + startYear },
       { index: 2, val: 8, label: 'August ' + startYear },
       { index: 3, val: 9, label: 'September ' + startYear },
       { index: 4, val: 10, label: 'October ' + startYear },
       { index: 5, val: 11, label: 'November ' + startYear },
       { index: 6, val: 12, label: 'December ' + startYear },
       { index: 7, val: 1, label: 'January ' + endYear },
       { index: 8, val: 2, label: 'February ' + endYear },
       { index: 9, val: 3, label: 'March ' + endYear },
       { index: 10, val: 4, label: 'April ' + endYear },
       { index: 11, val: 5, label: 'May ' + endYear }
     ];
  }
}
