import { Component, Input } from '@angular/core';
import { IComponent } from '../../../../types/step-component.interface';
import { StoreService } from '../../../../services/store.service';

@Component({
  selector: 'app-registered-event-details',
  template: `
    <ng-container *ngIf="(storeService.vm$ | async) as vm">
      <div *ngIf="metadata.attributes.PRE_DESCRIPTION" [innerHTML]="metadata.attributes.PRE_DESCRIPTION"></div>

      <h3>{{metadata.attributes.HEADING}}</h3>
      <div class="mt-3 detail">
        <div *ngIf="vm.selectedTeam">Team Name: {{ vm.selectedTeam?.teamName }}</div>
        <div>{{ vm.selectedEvent.eventName }}</div>
        <div>{{ vm.selectedEvent.eventLocation }}</div>
        <div>{{ vm.selectedEvent.walkDateAsString }}</div>
        <div *ngIf="vm.fundraisingLevel">Fundraising Commitment: {{ '$' + vm.fundraisingLevel.amount }}</div>
        <div *ngIf="calculateRegistrationFee() > 0">
          Registration Fee: {{ '$' + calculateRegistrationFee() }}
        </div>
        <div *ngIf="vm.amount">Kick-start donation: {{ '$' + vm.amount }}</div>
      </div>
      <subcomponent
        *ngIf="vm.pgConfig.programGroupName === 'tnt'"
        [parentMetadata]="metadata"
        name="REGISTERED_CANDIDATE_DETAILS"></subcomponent>
    </ng-container>
    <div [innerHTML]="metadata.attributes.DESCRIPTION" class="mt-3"></div>
  `,
  styles: [`
    .detail {
      padding: 0.75em;
      border: 1px solid #D1D1D1;
    }
  `]
})
export class RegisteredEventDetailsComponent {
  @Input() metadata: IComponent;

  constructor(public storeService: StoreService) {}

  calculateRegistrationFee() {
    let couponAmount = 0;
    if (this.storeService.getCurrentState().coupon) {
      couponAmount = this.storeService.getCurrentState().coupon.couponAmountReduction;
    }
    return (this.storeService.getCurrentState().selectedEvent.registrationFee ? this.storeService.getCurrentState().selectedEvent.registrationFee - couponAmount : 0);
  }
}
