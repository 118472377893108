import {AfterViewInit, Component, Input} from '@angular/core';
import { IComponent } from '../../../../types/step-component.interface';
import { StoreService } from '../../../../services/store.service';

@Component({
  selector: 'app-selected-event-details-w-reg-fee',
  template: `
    <ng-container *ngIf="(storeService.vm$ | async) as vm">
      <h4><b>{{metadata.attributes.HEADING}}</b></h4>
      <div class="mt-3 detail" *ngIf="vm.formDefaultLoading">
        Loading ...
      </div>
      <div class="mt-3 detail" *ngIf="!vm.formDefaultLoading">
        <h6><b>{{ vm.selectedEvent.eventDescription }}</b></h6>
        <div>{{ vm.formDefault?.dateStr }}</div>
        <div>Registration Fee: {{ '$' + vm.selectedEvent.registrationFee }}</div>
      </div>
    </ng-container>
  `,
  styles: [`
    .detail {
      padding: 0.75em;
      border: 1px solid #D1D1D1;
    }
  `]
})
export class SelectedEventDetailsWRegFeeComponent implements AfterViewInit {
  @Input() metadata: IComponent;

  constructor(public storeService: StoreService) {}

  ngAfterViewInit() {
    this.storeService.updateState({eventDetailsFetched: true});
  }
}
