import { Directive } from '@angular/core';
import { UntypedFormControl, NG_VALIDATORS, Validator } from '@angular/forms';

@Directive({
  selector: '[tc]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: TermsAndConditionsValidator, multi: true }
  ]
})
export class TermsAndConditionsValidator implements Validator {
  validate(control: UntypedFormControl) {
    if ( !control.value) {
      return { tc: true };
    }
    return null;
  }
}
