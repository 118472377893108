import { Component, ViewChild } from '@angular/core';
import { StoreService } from '../../../../services/store.service';
import { HttpClient } from '@angular/common/http';
import { NgForm } from '@angular/forms';
import { markAllFieldsAsTouched, validateForm } from '../../../../shared/utils';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-sign-in-forgot-password',
  templateUrl: './forgot-password.component.html'
})
export class ForgotPasswordComponent {
  @ViewChild('form') form: NgForm;
  email = this.storeService.getCurrentState().email;

  constructor(
    private http: HttpClient,
    private storeService: StoreService,
    private toastrService: ToastrService
  ) {}

  openRegisterForm() {
    this.storeService.updateState({ signinState: 'register' });
  }

  submit() {
    markAllFieldsAsTouched(this.form.form);
    validateForm(this.form.form);
    if (this.form.valid) {
      this.http.get('/OnlineRegistration/service/api/forgotPassword', {
        params: {
          pgName: this.storeService.getCurrentState().pgConfig.programGroupName,
          username: this.email
        }
      }).subscribe(result => {
        this.storeService.updateState({ signinState: 'sign-in', recoverPassword: true });
      }, () => {
        this.toastrService.error('Something went wrong.');
      });
    }
  }
}
