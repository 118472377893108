import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { IComponent } from '../../../../../../types/step-component.interface';
import { StoreService } from '../../../../../../services/store.service';
import { BackendService } from '../../../../../../services/backend.service';
import { WayToParticipate } from '../../../../../../types/way-to-participate.type';
import { IValidationSubComponent } from '../../../../../../types/subcomponent-validation.interface';
import { scrollIntoView } from '../../../../../../shared/utils';

@Component({
  selector: 'app-join-team',
  template: `
    <div id="join-team" *ngIf="(storeService.vm$ | async) as vm">
      <h3>{{metadata.attributes.HEADING}}</h3>
      <div class="detail" [innerHTML]="metadata.attributes.DESCRIPTION"></div>

      <ng-container *ngIf="!vm.teamsLoading">
        <subcomponent *ngIf="!vm.teams || vm.teams.length === 0" [parentMetadata]="metadata" name="NO_TEAM_FOUND"></subcomponent>
        <subcomponent *ngIf="!vm.teams || vm.teams.length === 0" [parentMetadata]="metadata" name="NO_CANDIDATE_FOUND"></subcomponent>
        <subcomponent *ngIf="vm.teams && vm.teams.length > 0" [parentMetadata]="metadata" name="JOIN_TEAM_SEARCH"></subcomponent>
        <subcomponent *ngIf="vm.teams && vm.teams.length > 0" [parentMetadata]="metadata" name="SEARCH_ALL_TEAMS"></subcomponent>
        <subcomponent *ngIf="vm.filteredTeams && vm.filteredTeams.length > 0" [parentMetadata]="metadata" name="TEAM_SEARCH_RESULT"></subcomponent>
        <subcomponent *ngIf="vm.teams && vm.selectedTeam" [parentMetadata]="metadata" name="SELECTED_TEAM_DETAILS"></subcomponent>
        <subcomponent *ngIf="vm.teams && vm.selectedTeam" [parentMetadata]="metadata" name="SELECTED_CANDIDATE_DETAILS"></subcomponent>
        <subcomponent *ngIf="vm.teams.length > 0 && vm.filteredTeams && vm.filteredTeams.length === 0" [parentMetadata]="metadata" name="NO_TEAM_RESULT_FOUND"></subcomponent>
        <subcomponent *ngIf="vm.teams.length > 0 && vm.filteredTeams && vm.filteredTeams.length === 0" [parentMetadata]="metadata" name="NO_CANDIDATE_RESULT_FOUND"></subcomponent>
      </ng-container>

      <div *ngIf="vm.teamsLoading" class="spinner-border">
        <span class="sr-only">Loading...</span>
      </div>

      <div *ngIf="validated && !vm.selectedTeam" class="alert alert-danger" role="alert">
        {{ metadata?.attributes?.SELECT_ERROR_MESSAGE }}
      </div>
      <div *ngIf="validated && vm.selectedTeam && !vm.isUserNotRegisteredForTeam" class="alert alert-danger" role="alert">
        User is already registered.
      </div>
    </div>
  `
})
export class JoinTeamComponent implements IValidationSubComponent, OnInit, AfterViewInit {
  @Input() metadata: IComponent;

  validated = false;

  constructor(
    public storeService: StoreService,
    private backendService: BackendService,
  ) {
    this.storeService.updateState({ wayToParticipate: WayToParticipate.JOIN_TEAM });
  }

  ngOnInit() {
    this.storeService.updateState({ joinTeamCheckRegStatus: this.metadata.attributes.CHECK_FOR_REG_STATUS });

    if ((!this.storeService.getCurrentState().teams
      && this.metadata.attributes.LOAD_TEAMS_AT_INIT
      && !this.storeService.getCurrentState().teamsLoading) ||
      !this.isCorrectTeamsLoaded()) {
      this.backendService.loadTeams().subscribe(() => {});
    }
  }

  ngAfterViewInit() {
    this.storeService.teams$.subscribe((o) => {
      if (o) {
        setTimeout(() => {
          scrollIntoView('join-team');
        }, 0);
      }
    });
  }

  validate(): boolean {
    this.validated = true;
    const currentState = this.storeService.getCurrentState();
    if (
      this.storeService.getCurrentState().joinTeamCheckRegStatus
      && currentState.signinResult && currentState.signinResult.constituentID
    ) {
      return currentState.isUserNotRegisteredForTeam;
    } else {
      return !!this.storeService.getCurrentState().selectedTeam;
    }
  }

  private isCorrectTeamsLoaded() {
    const currentState = this.storeService.getCurrentState();
    if (currentState.pgConfig.programGroupName !== 'ltn') {
      return true;
    }

    return currentState.teams && currentState.teams.length > 0 && currentState.teams[0] &&
      currentState.teams[0].walksiteID === currentState.selectedEvent.eventID + '';
  }
}
