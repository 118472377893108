<h4>{{ metadata.attributes.HEADING }}</h4>
<div *ngIf="(storeService.vm$ | async) as vm" id="event-search-result">
  <app-table
    [rows]="vm.filteredEvents"
    compareIdentifier="eventID"
    tableStorageKey="eventSearch"
    (selectRow)="onSelectRow($event)"
  >
    <ng-container *ngIf="hasColumn('date')" appColumnDef="Date" sortField="walkDate">
      <span *appCellDef="let row">{{ row.walkDateAsString }}</span>
    </ng-container>
    <ng-container *ngIf="hasColumn('event name')" appColumnDef="Experience" sortField="eventName">
      <span *appCellDef="let row">{{ row.eventName }}</span>
    </ng-container>
    <ng-container *ngIf="hasColumn('location')" appColumnDef="Location" sortField="eventLocation">
      <div *appCellDef="let row">
        <div>{{ row.eventLocationCity }}, {{ row.eventLocationState }} {{ row.eventLocationFullState }}</div>
        <div>{{ row.eventLocation }}</div>
      </div>
    </ng-container>
  </app-table>
  <div *ngIf="validated && !vm.selectedEvent" class="alert alert-danger" role="alert">
    Please select an event.
  </div>
  <div *ngIf="validated && vm.selectedEvent && loginError" class="alert alert-danger" role="alert">
    User is already registered.
  </div>
</div>
