import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { IComponent } from '../../../../types/step-component.interface';
import { StoreService } from '../../../../services/store.service';
import { IValidationSubComponent } from '../../../../types/subcomponent-validation.interface';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { scrollIntoView } from '../../../../shared/utils';

@Component({
  selector: 'app-fundraising-level',
  template: `
    <ng-container *ngIf="(storeService.vm$ | async) as vm">
      <h3 id="fundraising">{{metadata.attributes.HEADING}}</h3>
      <p>{{metadata.attributes.DESCRIPTION}}</p>

      <app-table
          *ngIf="(rows$ | async) as fundraisingLevels"
          [rows]="fundraisingLevels"
          tableStorageKey="fundraising-level"
          (selectRow)="onSelectRow($event)"
      >
        <ng-container appColumnDef="Fundraising Commitment">
          <span *appCellDef="let row">{{'$' + row.amount}}</span>
        </ng-container>
        <ng-container appColumnDef="Description">
          <span *appCellDef="let row">{{ row.description }}</span>
        </ng-container>
      </app-table>
      <div *ngIf="!rows$" class="spinner-border">
        <span class="sr-only">Loading...</span>
      </div>
      <div *ngIf="validated && !vm.fundraisingLevel" class="alert alert-danger" role="alert">
        Choosing Fundraising Commitment Level is required.
      </div>
    </ng-container>
  `,
  styles: [`
      :host::ng-deep app-table tr > td:nth-child(1) {
        min-width: 130px;
      }
  `]
})
export class FundraisingLevelComponent implements IValidationSubComponent, OnInit, AfterViewInit {
  @Input() metadata: IComponent;

  rows$: Observable<any>;
  validated = false;

  constructor(public storeService: StoreService){
  }

  ngOnInit() {
    this.rows$ = this.storeService.fundraisingLevels$;
  }

  ngAfterViewInit() {
    if (this.storeService.getCurrentState().sportCourses) {
      this.storeService.sportCourse$.pipe(
        switchMap(val => {
          return this.rows$;
        })
      ).subscribe((o) => {
        if (o) {
          scrollIntoView('fundraising');
        }
      });
    }
  }

  validate(): boolean {
    this.validated = true;
    return !!this.storeService.getCurrentState().fundraisingLevel;
  }

  onSelectRow(row) {
    const tablesData = this.storeService.getCurrentState().tableData;
    const tableData = tablesData.find(o => o.tableStorageKey === 'training-location');
    if (tableData) {
      tableData.selectedRow = null;
    }
    this.storeService.updateState({
      fundraisingLevel: row,
      trainingLocation: null,
      tableData: tablesData
    });
  }
}
