import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import {Observable, of} from 'rxjs';
import { StoreService } from '../services/store.service';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { ProgramGroupConfig } from '../types/program-groups';
import { ProgressService } from '../services/progress.service';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class InitResolver implements Resolve<Observable<any>> {
  constructor(
    private http: HttpClient,
    private progressService: ProgressService,
    private storeService: StoreService,
    private toastrService: ToastrService
  ) {}

  resolve() {
    this.progressService.block();
    return this.http.get('/OnlineRegistration/service/api/init').pipe(
      catchError(err => {
        this.toastrService.error('Something went wrong.');
        return of(null);
      }),
      map((response: any) => {
        const pgRes: ProgramGroupConfig = JSON.parse(response.pgRes);
        pgRes.programGroupName = pgRes.programGroupName.toLowerCase();
        const states = JSON.parse(response.states);
        states.unshift({
          name: null,
          description: 'Select a state'
        });
        return {
          pgConfig: pgRes,
          states
        };
      }),
      switchMap((response: any) => {
        const pgName = response.pgConfig.programGroupName.toLowerCase();
        //add cache busting for configs definitions since updates may not be seen without a cache refresh
        return this.http.get('/assets/jsons/configs/' + response.pgConfig.programGroupName + '.json?t=' + (new Date()).getTime() )
          .pipe(
            catchError(err => {
              this.toastrService.error('Something went wrong.');
              return of(null);
            }),
            map((staticResponse: any) => {
              return {
                ...response,
                staticConfig: staticResponse
              };
            })
          );
      }),
      switchMap((response: any) => {
        const pgName = response.pgConfig.programGroupName.toLowerCase();
        return this.http.get('/OnlineRegistration/service/api/survivorType')
          .pipe(
            catchError(err => {
              this.toastrService.error('Something went wrong.');
              return of(null);
            }),
            map((survivorTypes: any) => {
              const types = survivorTypes;
              types.unshift({
                description: 'Unspecified',
                name: 'Unspecified',
                id: null
              });
              return {
                ...response,
                survivorTypes: types
              };
            })
          );
      }),
      tap((response: any) => {
        this.storeService.updateState(response);
        this.progressService.unblock();
      })
    );
  }
}
