import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { routing } from './app.routing';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { NgSelectModule } from '@ng-select/ng-select';

import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { FooterComponent } from './components/core/footer/footer.component';
import { HeaderComponent } from './components/core/header/header.component';
import { EventSearchComponent } from './components/steps/event-search/event-search.component';
import { ConfirmationComponent } from './components/steps/confirmation/confirmation.component';
import { FundraisingGoalComponent } from './components/steps/event-specific-information/subcomponents/fundraising-goal.component';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { DonationPageConfigResolver } from './resolvers/donation-page-config.resolver';
import { InitResolver } from './resolvers/init.resolver';
import { SignInComponent } from './components/steps/sign-in/sign-in.component';
import { WizardComponent } from './components/core/wizard/wizard.component';
import { StepHostDirective } from './directives/step-host.directive';
import { EventSpecificInfoComponent } from './components/steps/event-specific-information/event-specific-info.component';
import { NoEventFoundComponent } from './components/steps/event-search/subcomponents/no-event-found.component';
import { SelectedEventDetailsComponent } from './components/steps/event-search/subcomponents/selected-event-details.component';
import { StepDefinitionsResolver } from './resolvers/step-definitions.resolver';
import { StoreService } from './services/store.service';
import { EventSearchByZipComponent } from './components/steps/event-search/subcomponents/event-search-by-zip.component';
import { EventSearchByKeywordComponent } from './components/steps/event-search/subcomponents/event-search-by-keyword.component';
import { EventSearchByTypeComponent } from './components/steps/event-search/subcomponents/event-search-by-type.component';
import { SubcomponentComponent } from './components/core/subcomponent.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { TableSortPipe } from './shared/table-sort.pipe';
import { SubcomponentHostDirective } from './directives/subcomponent-host.directive';
import { SportCourseComponent } from './components/steps/event-specific-information/subcomponents/sport-course.component';
import { FundraisingLevelComponent } from './components/steps/event-specific-information/subcomponents/fundraising-level.component';
import { TrainingLocationComponent } from './components/steps/event-specific-information/subcomponents/training-location.component';
import { TableComponent } from './components/core/table/table.component';
import {
  EventSearchResultComponent
} from './components/steps/event-search/subcomponents/event-search-result/event-search-result.component';
import { SelectedEventDetailsWRegFeeComponent } from './components/steps/event-search/subcomponents/selected-event-details-w-reg-fee.component';
import { AppCellDefDirective } from './directives/cell-def.directive';
import { AppColumnDefDirective } from './directives/column-def.directive';
import { LoginComponent } from './components/steps/sign-in/subcomponents/login.component';
import { CreateAccountComponent } from './components/steps/sign-in/subcomponents/create-account.component';
import { ForgotPasswordComponent } from './components/steps/sign-in/subcomponents/forgot-password.component';
import { AtLeastOneNumberValidator } from './shared/validators/at-least-one-number.validator';
import { AtLeastOneUppercaseCharValidator } from './shared/validators/at-least-one-uppercase-char.validator';
import { AtLeastOneSpecialCharValidator } from './shared/validators/at-least-one-special-char.validator';
import { ControlErrorsComponent } from './components/core/control-errors.component';
import { WizardService } from './services/wizard.service';
import { EmailAvailabilityValidator } from './shared/validators/email-availability.validator';
import { SocialLoginComponent } from './components/steps/sign-in/subcomponents/social-login.component';
import { ProgressService } from './services/progress.service';
import { FacebookService } from './services/facebook.service';
import { WaysToParticipateComponent } from './components/steps/event-specific-information/subcomponents/ways-to-participant/ways-to-participate.component';
import {
  ParticipateJoinTeamComponent
} from './components/steps/event-specific-information/subcomponents/ways-to-participant/participate-join-team.component';
import {
  ParticipateCreateTeamComponent
} from './components/steps/event-specific-information/subcomponents/ways-to-participant/participate-create-team.component';
import { ParticipateIndividualComponent } from './components/steps/event-specific-information/subcomponents/ways-to-participant/participate-individual.component';
import { NoTeamResultFoundComponent } from './components/steps/event-specific-information/subcomponents/ways-to-participant/join-team/no-team-result.component';
import { JoinTeamComponent } from './components/steps/event-specific-information/subcomponents/ways-to-participant/join-team/join-team.component';
import { JoinTeamSearchComponent } from './components/steps/event-specific-information/subcomponents/ways-to-participant/join-team/team-search.component';
import { TeamSearchResultComponent } from './components/steps/event-specific-information/subcomponents/ways-to-participant/join-team/search-result.component';
import { SelectedTeamDetailsComponent } from './components/steps/event-specific-information/subcomponents/ways-to-participant/join-team/team-details.component';
import { NoTeamFoundComponent } from './components/steps/event-specific-information/subcomponents/ways-to-participant/join-team/no-team-found.component';
import { TShirtComponent } from './components/steps/event-specific-information/subcomponents/t-shirt.component';
import { CreateTeamComponent } from './components/steps/event-specific-information/subcomponents/ways-to-participant/create-team/create-team.component';
import { CreateTeamNameComponent } from './components/steps/event-specific-information/subcomponents/ways-to-participant/create-team/create-team-name.component';
import { CreateTeamTypeComponent } from './components/steps/event-specific-information/subcomponents/ways-to-participant/create-team/create-team-type.component';
import { CreateTeamFundraiserStartEnd } from './components/steps/event-specific-information/subcomponents/ways-to-participant/create-team/create-team-frstartend.component';
import { UploadTeamImageComponent } from './components/steps/event-specific-information/subcomponents/ways-to-participant/create-team/upload-team-image.component';
import { NationalTeamComponent } from './components/steps/event-specific-information/subcomponents/ways-to-participant/create-team/national-team.component';
import { EqualOrGreaterThanFundraisingCommittmentValidator } from './shared/validators/equal-or-greater-than-fundraising-commitment.validator';
import { TeamNameAvailabilityValidator } from './shared/validators/team-name-availability.validator';
import { SelectedCandidateDetailsComponent } from './components/steps/event-specific-information/subcomponents/ways-to-participant/join-team/candidate-detail.component';
import { PersonalInfoComponent } from './components/steps/personal-info/personal-info.component';
import { ContactInfoComponent } from './components/steps/personal-info/subcomponents/contact-info/contact-info.component';
import { TermsConditionsComponent } from './components/steps/payment/subcomponents/terms-conditions.component';
import { SignatureComponent } from './components/steps/payment/subcomponents/signature.component';
import { DateOfBirthComponent } from './components/steps/personal-info/subcomponents/date-of-birth.component';
import { SignatureValidator } from './shared/validators/signature.validator';
import { MonthValidator } from './shared/validators/month.validator';
import { DayValidator } from './shared/validators/day.validator';
import { YearValidator } from './shared/validators/year.validator';
import { PaymentInfoComponent } from './components/steps/payment/payment-info.component';
import { CaptchaComponent } from './components/steps/payment/subcomponents/captcha.component';
import { WaiverComponent } from './components/steps/payment/subcomponents/waiver.component';
import { KickstartComponent } from './components/steps/payment/subcomponents/kickstart.component';
import { PaymentFormComponent } from './components/steps/payment/subcomponents/payment-form/payment-form.component';
import { TermsAndConditionsValidator } from './shared/validators/terms-and-conditions.validator';
import { RecaptchaModule } from 'ng-recaptcha';
import { BackendService } from './services/backend.service';
import { SafePipe } from './pipes/safe.pipe';
import { AmountValidator } from './shared/validators/amount.validator';
import { AlternateBillingComponent } from './components/steps/payment/subcomponents/payment-form/alternate-billing/alternate-billing.component';
import { RegisteredEventDetailsComponent } from './components/steps/confirmation/subcomponents/event-details.component';
import { PromoCodeComponent } from './components/steps/payment/subcomponents/promo-code.component';
import { AmountComponent } from './components/steps/payment/subcomponents/amount.component';
import { RegFeeComponent } from './components/steps/payment/subcomponents/reg-fee.component';
import { DoubleTheDonationComponent } from './components/steps/payment/subcomponents/double-the-donation.component';
import { FundraisingMattersComponent } from './components/steps/confirmation/subcomponents/fundraising-matters.component';
import { RegisteredCandidateDetailsComponent } from './components/steps/confirmation/subcomponents/candidate-details.component';
import { YoutubeVideoComponent } from './components/steps/confirmation/subcomponents/youtube-video.component';
import { BusinessService } from './services/business.service';
import { RegistrationFeeComponent } from './components/steps/confirmation/subcomponents/registration-fee.component';
import { Angulartics2Module } from 'angulartics2';
import { DeepLinkingService } from './services/deep-linking.service';
import { NoCandidateResultFoundComponent } from './components/steps/event-specific-information/subcomponents/ways-to-participant/join-team/no-candidate-result.component';
import { NoCandidateFoundComponent } from './components/steps/event-specific-information/subcomponents/ways-to-participant/join-team/no-candidate-found.component';
import { BirthdayValidator } from './shared/validators/birthday.validator';
import { PolicyModalComponent } from './shared/components/policy-modal.component';
import { CvvModalComponent } from './shared/components/cvv-modal.component';
import { PrimaryContactEmailAvailabilityValidator } from "./shared/validators/primary-contact-email-availability.validator";
import { AtLeastOneLowercaseCharValidator } from './shared/validators/at-least-one-lowercase-char.validator';
import { MaxLength128Validator } from "./shared/validators/max-length-128.validator";
import { NoSpacesValidator } from "./shared/validators/no-spaces.validator";
import { ValidCharsOnlyValidator } from "./shared/validators/valid-chars-only.validator";

const maskConfigFunction: () => Partial<IConfig> = () => {
  return {
    validation: false,
  };
};

@NgModule({
    imports: [
      Angulartics2Module.forRoot(),
      BrowserModule,
      FormsModule,
      HttpClientModule,
      NgbModule,
      NgxPaginationModule,
      routing,
      NgxMaskModule.forRoot(maskConfigFunction),
      RecaptchaModule,
      BrowserAnimationsModule,
      ToastrModule.forRoot({
          timeOut: 5000,
          positionClass: 'toast-top-center',
          preventDuplicates: true,
        }),
      NgSelectModule
    ],
    declarations: [
      SafePipe,
      SafeHtmlPipe,
      EqualOrGreaterThanFundraisingCommittmentValidator,
      AtLeastOneNumberValidator,
      AtLeastOneUppercaseCharValidator,
      AtLeastOneSpecialCharValidator,
      EmailAvailabilityValidator,
      PrimaryContactEmailAvailabilityValidator,
      TeamNameAvailabilityValidator,
      SignatureValidator,
      MonthValidator,
      DayValidator,
      YearValidator,
      TermsAndConditionsValidator,
      AmountValidator,
      BirthdayValidator,
      AppCellDefDirective,
      AppColumnDefDirective,
      StepHostDirective,
      SubcomponentHostDirective,
      SubcomponentComponent,
      TableSortPipe,
      ControlErrorsComponent,
      AlternateBillingComponent,
      AppComponent,
      WizardComponent,
      EventSearchComponent,
      EventSpecificInfoComponent,
      ConfirmationComponent,
      PersonalInfoComponent,
      HeaderComponent,
      FooterComponent,
      SignInComponent,
      LoginComponent,
      CreateAccountComponent,
      ForgotPasswordComponent,
      WizardComponent,
      EventSearchByZipComponent,
      EventSearchByKeywordComponent,
      EventSearchByTypeComponent,
      EventSearchResultComponent,
      NoEventFoundComponent,
      SelectedEventDetailsComponent,
      SportCourseComponent,
      FundraisingLevelComponent,
      FundraisingGoalComponent,
      TrainingLocationComponent,
      TableComponent,
      SelectedEventDetailsComponent,
      SelectedEventDetailsWRegFeeComponent,
      LoginComponent,
      SocialLoginComponent,
      SelectedEventDetailsWRegFeeComponent,
      WaysToParticipateComponent,
      ParticipateJoinTeamComponent,
      ParticipateCreateTeamComponent,
      ParticipateIndividualComponent,
      NoTeamResultFoundComponent,
      JoinTeamComponent,
      JoinTeamSearchComponent,
      TeamSearchResultComponent,
      SelectedTeamDetailsComponent,
      NoTeamFoundComponent,
      TShirtComponent,
      CreateTeamComponent,
      CreateTeamNameComponent,
      CreateTeamTypeComponent,
      CreateTeamFundraiserStartEnd,
      UploadTeamImageComponent,
      NationalTeamComponent,
      SelectedCandidateDetailsComponent,
      ContactInfoComponent,
      TermsConditionsComponent,
      SignatureComponent,
      DateOfBirthComponent,
      PaymentInfoComponent,
      CaptchaComponent,
      WaiverComponent,
      KickstartComponent,
      PromoCodeComponent,
      AmountComponent,
      RegFeeComponent,
      PaymentFormComponent,
      FundraisingMattersComponent,
      DoubleTheDonationComponent,
      RegisteredEventDetailsComponent,
      RegisteredCandidateDetailsComponent,
      YoutubeVideoComponent,
      RegistrationFeeComponent,
      NoCandidateResultFoundComponent,
      NoCandidateFoundComponent,
      PolicyModalComponent,
      CvvModalComponent,
      AtLeastOneLowercaseCharValidator,
      MaxLength128Validator,
      NoSpacesValidator,
      ValidCharsOnlyValidator
    ],
    providers: [
      BackendService,
      BusinessService,
      ProgressService,
      FacebookService,
      DonationPageConfigResolver,
      InitResolver,
      StoreService,
      StepDefinitionsResolver,
      WizardService,
      DeepLinkingService
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
