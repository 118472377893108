import { Component, Input, ViewChild } from '@angular/core';
import { IComponent } from '../../../../types/step-component.interface';
import { StoreService } from '../../../../services/store.service';
import { SignatureComponent } from './signature.component';
import { PolicyModalComponent } from '../../../../shared/components/policy-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {NgForm} from "@angular/forms";

@Component({
  selector: 'app-waiver',
  template: `
    <div *ngIf="(storeService.vm$ | async) as vm" class="pt-3">
      <h3>{{metadata.attributes.HEADING}}</h3>
      <form #form="ngForm" id="waiver-tc">
        <input
          type="checkbox"
          name="tc"
          id="tc"
          required
          tc
          ngModel
          (ngModelChange)="storeService.updateState({termsAndConditions: $event})"
          #tc="ngModel">
        <label *ngIf="!vm.under18" class="mb-0 ml-1 d-inline" for="tc">I am at least 18 years old</label>
        <label *ngIf="vm.under18" class="mb-0 ml-1 mt-1 d-inline" for="tc">
          This is to certify that I, as a parent/guardian with legal responsibility for this participant, do
          consent and agree to indemnify and hold harmless the release from any and all liabilities
          incident to my minor child's involvement or participation in these programs above, even if
          arising from their negligence, to the fullest extent permitted by law.
        </label>
        <label class="mt-1" for="tc">
          I agree that electronic submission of this waiver constitutes signature, and in doing so, I
          acknowledge and represent that I have read and understand <a (click)="openPolicyModal()" style="cursor: pointer; text-decoration: underline;">this release</a> and agree to it
          voluntarily.
        </label>
        <div *ngIf="tc.errors?.tc" class="invalid-feedback">
          You have to agree to 'Terms & Conditions'.
        </div>
      </form>
      <div id="waiver" class="mb-3">
        <app-signature #signature [metadata]="metadata"></app-signature>
      </div>
    </div>
  `
})
export class WaiverComponent {
  @Input() metadata: IComponent;
  @ViewChild('form') form: NgForm;
  @ViewChild('signature') signature: SignatureComponent;

  constructor(
    public storeService: StoreService,
    private modalService: NgbModal
  ){
  }

  openPolicyModal() {
    const modalRef = this.modalService.open(PolicyModalComponent, {size: 'lg', backdrop : 'static', keyboard: false});
    modalRef.componentInstance.description = this.metadata.attributes.INLINE_DESCRIPTION;
    modalRef.componentInstance.heading = this.metadata.attributes.INLINE_HEADING;
  }
}
