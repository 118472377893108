import { Component, Input, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { IComponent } from '../../../types/step-component.interface';
import { HttpClient, HttpParams } from '@angular/common/http';
import { StoreService } from '../../../services/store.service';
import { AbstractStepComponent } from '../../core/abstract-step-component';
import {Observable, Subject} from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { ProgressService } from '../../../services/progress.service';
import { ActivatedRoute } from '@angular/router';
import { SubcomponentComponent } from '../../core/subcomponent.component';
import { EventSearchResultComponent } from './subcomponents/event-search-result/event-search-result.component';
import { NoEventFoundComponent } from './subcomponents/no-event-found.component';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-event-search-step',
  templateUrl: 'event-search.component.html'
})
export class EventSearchComponent extends AbstractStepComponent implements OnDestroy, OnInit {
  @ViewChildren('searchResult') searchResult: QueryList<SubcomponentComponent>;
  @ViewChildren('noEventFound') noEventFound: QueryList<SubcomponentComponent>;
  @Input() metadata: IComponent;

  private onDestroy$ = new Subject<void>();

  constructor(
    private activatedRoute: ActivatedRoute,
    public storeService: StoreService,
    private progressService: ProgressService,
    private http: HttpClient,
    private toastrService: ToastrService
  ){
    super();
  }

  ngOnInit() {
    // if there is a search by zip, then we don't want to load all events by default (such as TNT)
    if (this.getSubComponent('EVENT_SEARCH_BY_ZIP')) {
      this.hasStepControls = false;
    } else {
      if (!this.storeService.getCurrentState().events) {
        this.loadAllEvents();
      }
    }

    this.storeService.events$.pipe(
      takeUntil(this.onDestroy$),
      filter(e => !!e && e.length > 0)
    ).subscribe(() => {
      this.hasStepControls = true;
    });
  }

  ngOnDestroy() {
    this.onDestroy$.next();
  }

  canGoToNextStep(): boolean | Observable<boolean> {
    const searchResultComponent = this.searchResult.last?.componentInstance as EventSearchResultComponent;
    const noEventFoundComponent = this.noEventFound.last?.componentInstance as NoEventFoundComponent;
    if (noEventFoundComponent) {
      noEventFoundComponent.showValidationMessage = true;
      return false;
    } else {
      return searchResultComponent?.validate();
    }
  }

  saveHandler() {
    return;
  }

  private loadAllEvents() {
    const pgName = this.storeService.getCurrentState().pgConfig.programGroupName;
    const params = new HttpParams().set('programGroupName', pgName.toUpperCase());
    const endpoint = `/OnlineRegistration/service/api/events`;
    this.progressService.block();
    this.http.get(endpoint, {params}).subscribe((res: any) => {
      this.storeService.updateState({ events: res });
      this.progressService.unblock();
    },() => {
      this.progressService.unblock();
      this.toastrService.error('Something went wrong.');
    });
  }
}
