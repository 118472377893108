import { Component, Input } from '@angular/core';
import { IComponent } from '../../../../types/step-component.interface';
import { StoreService } from '../../../../services/store.service';

@Component({
  selector: 'app-reg-fee',
  template: `
    <div *ngIf="(storeService.vm$ | async) as vm">
      <div class="mt-4 mb-4 box">
        <div class="credit-cards">
          <div class="credit-cards__text">The following will be charged to your credit card:</div>
          <div>
            <img src="assets/images/credit-cards.png" class="img">
          </div>
        </div>
        <div class="registration-fee">
          <div class="d-flex">
            <div class="ml-4">Registration fee:</div>
            <div class="amount font-weight-bold">{{ vm.selectedEvent.registrationFee | currency }}</div>
          </div>
          <div *ngIf="vm.coupon" class="d-flex">
            <div class="ml-4">Promo code discount:</div>
            <div class="amount font-weight-bold">-{{ vm.coupon.couponAmountReduction | currency }}</div>
          </div>
          <div *ngIf="vm.amount" class="d-flex">
            <div class="ml-4">Kick-start donation:</div>
            <div class="amount font-weight-bold">{{ vm.amount | currency }}</div>
          </div>
          <hr *ngIf="vm.coupon || vm.amount" class="ml-4 amount"/>
          <div *ngIf="vm.coupon || vm.amount" class="d-flex">
            <div class="ml-4">Total:</div>
            <div class="amount font-weight-bold">{{ vm.finalRegFeeAmount | currency }}</div>
          </div>
        </div>
      </div>
      <p class="mb-3">{{metadata.attributes.DESCRIPTION}}</p>
    </div>
  `,
  styles: [`
      .box {
          display: flex;
          flex-direction: column;
      }

      .registration-fee {
          background-color: #eee;
          border: 1px solid #D1D1D1;
          padding: 20px;
      }

      .credit-cards {
          display: flex;
          border: 1px solid #D1D1D1;
          padding: 20px;
          justify-content: space-between;
      }

      .amount {
          margin-right: 60px;
          margin-left: auto;
      }

      .img {
          width: 9.55em;
      }

      @media only screen and (max-width: 500px) {
          .credit-cards {
              flex-direction: column-reverse;
          }

          .credit-cards__text {
              margin-top: 0.5em;
          }
      }
  `]
})
export class RegFeeComponent {
  @Input() metadata: IComponent;

  constructor(
    public storeService: StoreService
  ) {}
}
