import { Component, Input } from '@angular/core';
import { IComponent } from '../../../../types/step-component.interface';
import { StoreService } from '../../../../services/store.service';
import * as moment from 'moment';

@Component({
  selector: 'app-selected-candidate-details',
  template: `
    <ng-container *ngIf="(storeService.vm$ | async) as vm">
      <h3>{{metadata.attributes.HEADING}}</h3>
      <div class="mt-3 detail">
        <div>{{ vm.selectedTeam.walksiteName }}</div>
        <div>Team Name: {{ vm.selectedTeam.teamName }}</div>
        <div>Candidate You Are Supporting: {{ vm.selectedTeam.captainName }}</div>
        <div>Kickoff Day: {{ convertDate(vm.selectedEvent.kickoffDate) }}</div>
        <div>Grand Finale: {{ convertDate(vm.selectedTeam.walkDate) }}</div>
      </div>
    </ng-container>
    <div [innerHTML]="metadata.attributes.DESCRIPTION" class="mt-3"></div>
  `,
  styles: [`
    .detail {
      padding: 0.75em;
      border: 1px solid #D1D1D1;
    }
  `]
})
export class RegisteredCandidateDetailsComponent {
  @Input() metadata: IComponent;

  constructor(public storeService: StoreService) {}

  convertDate(date: number) {
    return moment.unix(date / 1000).format('LLLL');
  }
}
