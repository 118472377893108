import { Component, Input, QueryList, ViewChildren } from '@angular/core';
import { StoreService } from '../../../services/store.service';
import { AbstractStepComponent } from '../../core/abstract-step-component';
import { IComponent } from '../../../types/step-component.interface';
import { SubcomponentComponent } from '../../core/subcomponent.component';
import { PaymentFormComponent } from './subcomponents/payment-form/payment-form.component';
import { HttpClient } from '@angular/common/http';
import {catchError, map, switchMap, tap} from 'rxjs/operators';
import {
  markAllFieldsAsTouched,
  scrollIntoView,
  validateForm
} from '../../../shared/utils';
import { BusinessService } from '../../../services/business.service';
import { AmountComponent } from './subcomponents/amount.component';
import { TermsConditionsComponent } from './subcomponents/terms-conditions.component';
import { SignatureComponent } from './subcomponents/signature.component';
import { Observable, of } from 'rxjs';
import { DoubleTheDonationComponent } from './subcomponents/double-the-donation.component';
import { WayToParticipate } from '../../../types/way-to-participate.type';
import { WaiverComponent } from './subcomponents/waiver.component';
import { ToastrService } from 'ngx-toastr';
import { Angulartics2 } from 'angulartics2';
import { CaptchaComponent } from './subcomponents/captcha.component';

declare var doublethedonation: any;
declare var DDCONF: any;

@Component({
  selector: 'app-payment-step',
  template: `
    <div *ngIf="(storeService.vm$ | async) as vm">
      <subcomponent [parentMetadata]="metadata" name="PROMO_CODE"></subcomponent>
      <subcomponent [parentMetadata]="metadata" name="KICKSTART"></subcomponent>
      <subcomponent *ngIf="vm.showKickstartForm" #amountCmp [parentMetadata]="metadata" name="AMOUNT"></subcomponent>
      <subcomponent *ngIf="vm.finalRegFeeAmount > 0" [parentMetadata]="metadata" name="REG_FEE"></subcomponent>
      <subcomponent
              *ngIf="vm.showKickstartForm || (vm.pgConfig.programGroupName === 'tnt' && vm.finalRegFeeAmount > 0)"
              #paymentCmp [parentMetadata]="metadata" name="PAYMENT_FORM"></subcomponent>
      <subcomponent *ngIf="vm.showKickstartForm" [parentMetadata]="metadata" #dtdCmp name="DOUBLE_THE_DONATION"></subcomponent>
      <subcomponent [parentMetadata]="metadata" #waiverCmp name="WAIVER"></subcomponent>
      <subcomponent [parentMetadata]="metadata" #tcCmp name="T&C"></subcomponent>
      <subcomponent [parentMetadata]="metadata" #signatureCmp name="SIGNATURE"></subcomponent>
      <subcomponent *ngIf="vm.displayCaptcha" [parentMetadata]="metadata" #captchaCmp name="CAPTCHA"></subcomponent>
    </div>
  `
})
export class PaymentInfoComponent extends AbstractStepComponent {
  @Input() metadata: IComponent;
  @ViewChildren('amountCmp') amountCmp: QueryList<SubcomponentComponent>;
  @ViewChildren('dtdCmp') dtdCmp: QueryList<SubcomponentComponent>;
  @ViewChildren('paymentCmp') paymentCmp: QueryList<SubcomponentComponent>;
  @ViewChildren('signatureCmp') signatureCmp: QueryList<SubcomponentComponent>;
  @ViewChildren('tcCmp') tcCmp: QueryList<SubcomponentComponent>;
  @ViewChildren('waiverCmp') waiverCmp: QueryList<SubcomponentComponent>;
  @ViewChildren('captchaCmp') captchaCmp: QueryList<SubcomponentComponent>;

  constructor(
    public storeService: StoreService,
    private angulartics2: Angulartics2,
    private businessService: BusinessService,
    private http: HttpClient,
    private toastrService: ToastrService
  ){
    super();
  }

  canGoToNextStep(): Observable<boolean> {
    const dtdCmp = this.dtdCmp.last?.componentInstance as DoubleTheDonationComponent;
    if (dtdCmp) {
      const matchingCompanyName = dtdCmp.dtdInput.nativeElement.querySelector('.dtd-company-selected-name')?.textContent;
      this.storeService.updateState({ matchingCompanyName: matchingCompanyName ? matchingCompanyName : null});
    }

    const state = this.storeService.getCurrentState();

    this.storeService.updateState({ submitted: true });

    const paymentFormComponent = this.paymentCmp.last?.componentInstance as PaymentFormComponent;
    const captchaComponent = this.captchaCmp.last?.componentInstance as CaptchaComponent;
    const amountForm = (this.amountCmp.last?.componentInstance as AmountComponent)?.form?.form;
    const signatureForm = (this.signatureCmp.last?.componentInstance as SignatureComponent)?.form?.form;
    const tcForm = (this.tcCmp.last?.componentInstance as TermsConditionsComponent)?.form?.form;
    const waiverSignatureForm = (this.waiverCmp.last?.componentInstance as WaiverComponent)?.signature?.form?.form;
    const waiverTcForm = (this.waiverCmp.last?.componentInstance as WaiverComponent)?.form?.form;
    let alternateBillingForm;

    if (state.useAlternateBilling) {
      alternateBillingForm = paymentFormComponent?.alternateBilling?.ngForm$.last.form;
      markAllFieldsAsTouched(alternateBillingForm);
      validateForm(alternateBillingForm);
    }

    if (amountForm) {
      markAllFieldsAsTouched(amountForm);
      validateForm(amountForm);
    }

    if (paymentFormComponent) {
      paymentFormComponent.isValid();
    }

    if (alternateBillingForm) {
      markAllFieldsAsTouched(alternateBillingForm);
      validateForm(alternateBillingForm);
    }

    if (tcForm) {
      markAllFieldsAsTouched(tcForm);
      validateForm(tcForm);
    }
    if (signatureForm) {
      markAllFieldsAsTouched(signatureForm);
      validateForm(signatureForm);
    }
    if (waiverSignatureForm) {
      markAllFieldsAsTouched(waiverSignatureForm);
      validateForm(waiverSignatureForm);
    }

    if (waiverTcForm) {
      markAllFieldsAsTouched(waiverTcForm);
      validateForm(waiverTcForm);
    }

    if (amountForm && !amountForm.valid) {
      scrollIntoView('amount');
      return of(false);
    }

    if (paymentFormComponent && !paymentFormComponent.isValid(true)) {
      return of(false);
    }

    if (alternateBillingForm && !alternateBillingForm.valid) {
      scrollIntoView('alt-billing');
      return of(false);
    }

    if (tcForm && !tcForm.valid) {
      scrollIntoView('tc');
      return of(false);
    }

    if (waiverSignatureForm && !waiverSignatureForm.valid) {
      scrollIntoView('waiver');
      return of(false);
    }

    if (waiverTcForm && !waiverTcForm.valid) {
      scrollIntoView('waiver-tc');
      return of(false);
    }

    if (signatureForm && !signatureForm.valid) {
      scrollIntoView('signature');
      return of(false);
    }

    if (state.displayCaptcha && !state.lastCaptchaResponse) {
      return of(false);
    }

    if (paymentFormComponent) {
      return this.postCreateTeam().pipe(
        switchMap(() => {
          return paymentFormComponent.tokenizeUserDetails().pipe(
            map((res: any) => {
              if (res.success) {
                return res;
              } else {
                this.toastrService.error('Registration has failed.');
                return false;
              }
            }),
            catchError(err => {
              captchaComponent?.reset();
              if (err?.reason === 'unsupported_credit_card_type') {
                this.toastrService.error(err.message);
              } else if (err?.error?.errors[0]?.includes('Unexpected Charging Error: Transaction Failed.')) {
                this.toastrService.error(
                  'Transaction Failed. Something could be wrong with the information you entered. Please check your address and card information carefully and ensure it is correct before trying again.',
                  null,
                  { toastClass: 'ngx-toastr ngx-toastr-big', timeOut: 8000 }
                );
              } else {
                this.handleCommonErrors(err);
              }
              return of(null);
            })
          );
        }),
        switchMap((ccResult: any) => {
          if (!ccResult) {
            return of(false);
          } else if (dtdCmp && ccResult.success && ccResult.orderId) {
            return this.postDtd(ccResult).pipe(
              map(() => {
                return ccResult;
              }),
              catchError(err => {
                captchaComponent?.reset();
                this.toastrService.error('Something went wrong.');
                return of(null);
              })
            );
          } else {
            return of(ccResult);
          }
        }),
        map((res: any) => {
          this.trackSessionDuration();
          this.trackRegistrationData(this.storeService.getCurrentState(), res);
          return !!res?.success;
        }),
      );
    } else {
      return this.postCreateTeam().pipe(
        switchMap(() => {
          return this.http.post('/OnlineRegistration/service/api/register', this.businessService.getPostData()).pipe(
            map((res) => res),
            catchError(err => {
              captchaComponent?.reset();
              this.handleCommonErrors(err);
              return of(null);
            })
          );
        }),
        map((res: any) => {
          this.trackSessionDuration();
          this.trackRegistrationData(this.storeService.getCurrentState(), res);
          const isRegistered = !!res?.isRegistered;
          this.storeService.updateState({isRegistered});
          if (!isRegistered) {
            this.toastrService.error('Something went wrong.');
          }
          return isRegistered;
        }),
      );
    }
  }

  saveHandler(): void {
    return;
  }

  private handleCommonErrors(response: any) {
    if (response?.error?.fieldErrors?.captcha) {
      this.toastrService.error('Please enter a valid captcha value!');
    } else {
      this.toastrService.error('Something went wrong.');
    }
  }

  private postCreateTeam() {
    if (this.storeService.getCurrentState().wayToParticipate !== WayToParticipate.CREATE_TEAM
    || !this.storeService.getCurrentState().createTeam.base64Image) {
      return of(null);
    }

    const teamName = this.storeService.getCurrentState().createTeam.teamName;
    const eventID = this.storeService.getCurrentState().selectedEvent.eventID;
    const file = this.storeService.getCurrentState().createTeam.base64Image;
    const programGroupName = this.storeService.getCurrentState().pgConfig.programGroupName.toUpperCase();
    const formData = new FormData();
    formData.append('file', file);
    formData.append('teamName', teamName);
    formData.append('programGroupName', programGroupName);
    formData.append('eventID', String(eventID));
    return this.http.post('/OnlineRegistration/service/api/uploadImage', formData)
      .pipe(
        tap((res: any) => {
          this.storeService.updateState({
            createTeam: {
              ...this.storeService.getCurrentState().createTeam,
              teamImagePath: res.imagePath
            }
          });
        })
      );
  }

  private postDtd(ccResult: any) {
    return new Observable(subscriber => {
      const state = this.storeService.getCurrentState();
      const dtdCmp = this.dtdCmp.last?.componentInstance as DoubleTheDonationComponent;
      const donationId = 'CREDIT_CARD-' + ccResult.orderId;
      const campaign = ccResult.donationType;
      const dtdCompanyId = dtdCmp.dtdInput.nativeElement.querySelector('[name="doublethedonation_company_id"]').value;

      doublethedonation.plugin.set_donation_id(donationId);
      doublethedonation.plugin.set_donation_campaign(campaign);
      if (dtdCompanyId) {
        doublethedonation.plugin.set_company(dtdCompanyId);
      } else {
        const domain = doublethedonation.integrations.core.strip_domain(state.contactInfo.emailAddress);
        doublethedonation.plugin.email_domain(domain);
      }

      doublethedonation.integrations.core.register_donation({
        '360matchpro_public_key': DDCONF.API_KEY,
        campaign,
        donation_identifier: donationId,
        donation_amount: this.businessService.getDonationAmount(),
        doublethedonation_company_id: dtdCompanyId,
        doublethedonation_status: dtdCmp.dtdInput.nativeElement.querySelector('[name="doublethedonation_status"]').value,
        donor_first_name: state.contactInfo.firstName,
        donor_last_name: state.contactInfo.lastName,
        donor_email: state.contactInfo.emailAddress,
        donor_phone: state.contactInfo.phoneNumber,
        doublethedonation_company_name_input: dtdCmp.dtdInput.nativeElement.querySelector('[name="doublethedonation_company_name"]').value,
        pdf_form_fields: {
          'Employee Address1': state.contactInfo.address1,
          'Employee Address2': state.contactInfo.address2,
          'Employee City': state.contactInfo.city,
          'Employee State': state.contactInfo.stateID,
          'Employee Zip': state.contactInfo.postalCode,
          'Employee Phone': state.contactInfo.phoneNumber
        }
      }, (resp) => {
        if (resp.esign_url) {
          doublethedonation.plugin.set_docusign_envelope_url(resp.esign_url, 'Your donation is eligible for a matching gift. Please click here to fill out the form!');
        }
      });

      subscriber.next();
      subscriber.complete();
    });
  }

  private trackSessionDuration() {
    this.angulartics2.eventTrack.next({
      action: 'timing',
      properties: {
        gtmCustom: {
          category: 'Time to Conversion',
          label: 'duration',
          value: {
            conversationTime: new Date().getTime() - this.storeService.getCurrentState().sessionStartTime,
            wayToParticipate: this.storeService.getCurrentState().wayToParticipate
          }
        }
      },
    });
  }

  private trackRegistrationData(state, res) {
    this.angulartics2.eventTrack.next({
      action: 'registration',
      properties: {
        gtmCustom: {
          confirmationNumber: res.customerServiceCode || null,
        }
      },
    });
    if (state.amount ) {
      this.trackRegistrationDonationData(state, res);
    }
    if (state.finalRegFeeAmount) {
      this.trackRegistrationRegFeeData(state, res);
    }
  }

  private trackRegistrationDonationData(state, res) {
    this.angulartics2.eventTrack.next({
      action: 'donation',
      properties: {
        gtmCustom: {
          amount: state.amount,
          confirmationNumber: res.customerServiceCode || null,
        }
      },
    });
  }

  private trackRegistrationRegFeeData(state, res) {
    this.angulartics2.eventTrack.next({
      action: 'registrationFee',
      properties: {
        gtmCustom: {
          amount: state.registrationFeeAmount,
          confirmationNumber: res.customerServiceCode || null,
        }
      },
    });
  }
}
