import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { distinctUntilChanged, filter, map, shareReplay, tap } from 'rxjs/operators';
import { ProgramGroupConfig } from '../types/program-groups';
import { SimpleType } from '../types/simple-type';
import { StaticConfig } from '../types/static-config';
import { Event } from '../types/event.interface';
import { FundraisingLevel, SportCourse, TrainingTeam } from '../types/event-specific';
import { SigninResult } from '../types/auth-types';
import { Team } from '../types/team.interface';
import { WayToParticipate } from '../types/way-to-participate.type';
import { TShirt } from '../types/t-shirt.interface';
import { CreateTeam } from '../types/create-team.interface';
import { Contact } from '../types/contact-data';
import { DateOfBirth } from '../types/date-of-birth.interface';
import { AlternateBilling } from '../types/alternate-billing-data';
import { Company } from '../types/company.interface';
import { DonationPageConfig } from '../types/donation-page-config.interface';
import { EnvConfig } from '../types/env-config.interface';
import { Coupon } from '../types/coupon.interface';
import { TableData } from '../types/table-data.interface';
import { Location } from '@angular/common';
import { NationalTeam } from '../types/national-team.interface';

export interface StoreState {
  envConfig: EnvConfig;
  sportCourse: SportCourse;
  fundraisingLevel: FundraisingLevel;
  trainingLocation: TrainingTeam;
  sportCourses: any[];
  fundraisingLevels: FundraisingLevel[];
  trainingLocations: TrainingTeam[];
  wayToParticipate: WayToParticipate;
  fundraisingGoalPersonal: number;
  fundraisingGoalTeam: number;
  pgConfig: ProgramGroupConfig;
  donationPageConfig: DonationPageConfig;
  states: SimpleType[];
  survivorTypes: SimpleType[];
  staticConfig: StaticConfig;
  events: Event[];
  teams: Team[];
  teamsLoading: boolean;
  selectedTeamLoading: boolean;
  formDefaultLoading: boolean;
  createTeam: CreateTeam;
  tShirt: TShirt;
  eventSearchKeyword: string;
  teamSearchKeyword: string;
  isSchool: boolean;
  selectedEventType: string;
  selectedEvent: Event;
  selectedTeam: Team;
  signinState: string;
  signinResult: Partial<SigninResult>;
  signupData: any;
  currentStep: number;
  zipCode: string;
  contactInfo: Contact;
  useAlternateBilling: boolean;
  alternateBillingInfo: AlternateBilling;
  dateOfBirth: DateOfBirth;
  termsAndConditions: boolean;
  signature: string;
  selectedCompany: Company;
  isRegistered: boolean;
  amount: number;
  lastCaptchaResponse: string;
  submitted: boolean;
  coupon: Coupon;
  finalRegFeeAmount: number;
  registrationFeeAmount: number;
  displayCaptcha: boolean;
  tableData: TableData[];
  urlParams: string;
  nationalTeam: Partial<NationalTeam>;
  formDefault: any;
  eventTypeOptions: string[];
  teamImageSrc: string;
  recoverPassword: boolean;
  email: string;
  under18: boolean;
  matchingCompanyName: string;
  showKickstartForm: boolean;
  sessionStartTime: number;
  eventDetailsFetched: boolean;
  isUserNotRegisteredForTeam: boolean;
  joinTeamCheckRegStatus: boolean;
  nextIsDisabled: boolean;
  showWaysToParticipate: boolean;
  isSchoolFilter: boolean;
}

@Injectable()
export class StoreService {

  constructor(public location: Location) {
  }

  private state: Partial<StoreState> = {
    envConfig: null,
    sportCourse: null,
    fundraisingLevel: null,
    trainingLocation: null,
    sportCourses: null,
    fundraisingLevels: null,
    trainingLocations: null,
    fundraisingGoalPersonal: null,
    fundraisingGoalTeam: null,
    pgConfig: null,
    donationPageConfig: null,
    states: null,
    staticConfig: null,
    events: null,
    eventSearchKeyword: '',
    teamSearchKeyword: '',
    isSchool: false,
    selectedEventType: null,
    selectedEvent: null,
    signinState: 'sign-in',
    signinResult: null,
    signupData: null,
    currentStep: 0,
    zipCode: null,
    teams: null,
    teamsLoading: false,
    selectedTeamLoading: false,
    formDefaultLoading: false,
    selectedTeam: null,
    wayToParticipate: null,
    tShirt: null,
    createTeam: null,
    contactInfo: null,
    useAlternateBilling: false,
    alternateBillingInfo: null,
    dateOfBirth: null,
    termsAndConditions: false,
    signature: '',
    selectedCompany: null,
    isRegistered: false,
    amount: null,
    lastCaptchaResponse: null,
    submitted: false,
    coupon: null,
    finalRegFeeAmount: null,
    registrationFeeAmount: null,
    displayCaptcha: false,
    tableData: [],
    urlParams: '',
    nationalTeam: null,
    formDefault: null,
    eventTypeOptions: null,
    teamImageSrc: '',
    recoverPassword: false,
    email: '',
    under18: false,
    matchingCompanyName: null,
    showKickstartForm: false,
    sessionStartTime: null,
    eventDetailsFetched: false,
    isUserNotRegisteredForTeam: true,
    joinTeamCheckRegStatus: false,
    nextIsDisabled: false,
    showWaysToParticipate: false,
    isSchoolFilter: false
  };
  private store = new BehaviorSubject<Partial<StoreState>>(this.state);

  currentStep$ = this.store.pipe(
    map(s => s.currentStep),
    distinctUntilChanged(),
  );
  events$ = this.store.pipe(
    map(s => s.events),
    distinctUntilChanged()
  );
  teams$ = this.store.pipe(
    map(s => s.teams),
    distinctUntilChanged()
  );
  createTeam$ = this.store.pipe(
    map(s => s.createTeam),
    distinctUntilChanged()
  );
  wayToParticipate$ = this.store.pipe(
    map(s => s.wayToParticipate),
    distinctUntilChanged()
  );
  fundraisingGoalPersonal$ = this.store.pipe(
    map(s => s.fundraisingGoalPersonal),
    distinctUntilChanged()
  );
  fundraisingGoalTeam$ = this.store.pipe(
    map(s => s.fundraisingGoalTeam),
    distinctUntilChanged()
  );
  tShirt$ = this.store.pipe(
    map(s => s.tShirt),
    distinctUntilChanged()
  );
  eventSearchKeyword$ = this.store.pipe(
    map(s => s.eventSearchKeyword),
    distinctUntilChanged(),
  );
  teamSearchKeyword$ = this.store.pipe(
    map(s => s.teamSearchKeyword),
    distinctUntilChanged(),
  );
  isSchool$ = this.store.pipe(
    map(s => s.isSchool),
    distinctUntilChanged(),
  );
  selectedEventType$ = this.store.pipe(
    map(s => s.selectedEventType),
    distinctUntilChanged(),
  );
  contactInfo$ = this.store.pipe(
    map(s => s.contactInfo),
    distinctUntilChanged(),
  );
  useAlternateBilling$ = this.store.pipe(
    map(s => s.useAlternateBilling),
    distinctUntilChanged(),
  );
  lastCaptchaResponse$ = this.store.pipe(
    map(s => s.lastCaptchaResponse),
    distinctUntilChanged(),
  );
  matchingCompanyName$ = this.store.pipe(
    map(s => s.matchingCompanyName),
    distinctUntilChanged(),
  );
  submitted$ = this.store.pipe(
    map(s => s.submitted),
    distinctUntilChanged(),
  );
  finalRegFeeAmount$ = this.store.pipe(
    map(s => s.finalRegFeeAmount),
    distinctUntilChanged(),
  );
  amount$ = this.store.pipe(
    map(s => s.amount),
    distinctUntilChanged(),
  );
  tableData$ = this.store.pipe(
    map(s => s.tableData),
    distinctUntilChanged(),
  );
  teamsLoading$ = this.store.pipe(
    map(s => s.teamsLoading),
    distinctUntilChanged(),
  );
  selectedTeamLoading$ = this.store.pipe(
    map(s => s.selectedTeamLoading),
    distinctUntilChanged(),
  );
  formDefaultLoading$ = this.store.pipe(
    map(s => s.formDefaultLoading),
    distinctUntilChanged(),
  );
  formDefault$ = this.store.pipe(
    map(s => s.formDefault),
    distinctUntilChanged(),
  );

  filteredEvents$ = combineLatest([this.events$, this.eventSearchKeyword$, this.selectedEventType$]).pipe(
    map(([events, keyword, selectedEventType]) => {
      if (events) {
        if (keyword) {
          events = events.filter((event: any) =>
            this.getEventSearchProperties(event).find((o: string) => o.includes(keyword.toLowerCase()))
          );
        }
        if (selectedEventType && selectedEventType !== 'All Experiences') {
          events = events.filter((event: any) => event.programName === selectedEventType);
        }
        return events;
      } else {
        return events;
      }
    })
  );
  filteredTeams$ = combineLatest([this.teams$, this.teamSearchKeyword$, this.isSchool$]).pipe(
    map(([teams, keyword, isSchool]) => {
      if (teams) {
        if (keyword) {
          teams = teams.filter((team: any) =>
            this.getTeamSearchProperties(team).find((o: string) => o.includes(keyword.toLowerCase()))
          );
        }
        if (isSchool) {
          teams = teams.filter((team) => team.teamType == 'SCHOOL');
        }
        return teams;
      } else {
        return teams;
      }
    })
  );
  init$ = this.store.pipe(
    filter(s => !!s.pgConfig && !!s.staticConfig),
    map(s => {
      return {
        pgConfig: s.pgConfig,
        staticConfig: s.staticConfig
      };
    }),
    distinctUntilChanged((a, b) => _.isEqual(a, b))
  );
  pgConfig$ = this.store.pipe(
    filter(s => !!s.pgConfig),
    map(s => s.pgConfig),
    distinctUntilChanged()
  );
  selectedEvent$ = this.store.pipe(
    map(s => s.selectedEvent),
    distinctUntilChanged(),
  );
  selectedTeam$ = this.store.pipe(
    map(s => s.selectedTeam),
    distinctUntilChanged(),
  );
  signinState$ = this.store.pipe(
    map(s => s.signinState),
    distinctUntilChanged(),
  );
  states$ = this.store.pipe(
    map(s => s.states),
    distinctUntilChanged(),
  );

  sportCourse$ = this.store.pipe(
    map(s => s.sportCourse),
    distinctUntilChanged(),
  );
  fundraisingLevel$ = this.store.pipe(
    map(s => s.fundraisingLevel),
    distinctUntilChanged(),
  );
  trainingLocation$ = this.store.pipe(
    map(s => s.trainingLocation),
    distinctUntilChanged(),
  );
  sportCourses$ = this.store.pipe(
    map(s => s.sportCourses),
    distinctUntilChanged(),
  );
  fundraisingLevels$ = this.store.pipe(
    map(s => s.fundraisingLevels),
    distinctUntilChanged(),
  );
  trainingLocations$ = this.store.pipe(
    map(s => s.trainingLocations),
    distinctUntilChanged(),
  );
  isRegistered$ = this.store.pipe(
    map(s => s.isRegistered),
    distinctUntilChanged(),
  );
  coupon$ = this.store.pipe(
    map(s => s.coupon),
    distinctUntilChanged(),
  );
  displayCaptcha$ = this.store.pipe(
    map(s => s.displayCaptcha),
    distinctUntilChanged(),
  );
  eventTypeOptions$ = this.store.pipe(
    map(s => s.eventTypeOptions),
    distinctUntilChanged(),
  );
  recoverPassword$ = this.store.pipe(
    map(s => s.recoverPassword),
    distinctUntilChanged(),
  );
  under18$ = this.store.pipe(
    map(s => s.under18),
    distinctUntilChanged(),
  );
  showKickstartForm$ = this.store.pipe(
    map(s => s.showKickstartForm),
    distinctUntilChanged(),
  );
  eventDetailsFetched$ = this.store.pipe(
    map(s => s.eventDetailsFetched),
    distinctUntilChanged(),
  );
  isUserNotRegisteredForTeam$ = this.store.pipe(
    map(s => s.isUserNotRegisteredForTeam),
    distinctUntilChanged(),
  );
  nextIsDisabled$ = this.store.pipe(
    map(s => s.nextIsDisabled),
    distinctUntilChanged(),
  );
  zipCodePresent$ = this.store.pipe(
    map(s => !!s.zipCode),
    // distinctUntilChanged(), // disabling since we watch to rescroll if we update the zip code
  );
  zipCode$ = this.store.pipe(
    map(s => s.zipCode),
    distinctUntilChanged(),
  );
  showWaysToParticipate$ = this.store.pipe(
    map(s => s.showWaysToParticipate),
    distinctUntilChanged(),
  );
  isSchoolFilter$ = this.store.pipe(
    map(s => s.isSchoolFilter),
    distinctUntilChanged(),
  );

  vm$ = combineLatest(
    [
      this.events$,
      this.eventSearchKeyword$,
      this.teamSearchKeyword$,
      this.isSchool$,
      this.filteredEvents$,
      this.filteredTeams$,
      this.pgConfig$,
      this.selectedEvent$,
      this.signinState$,
      this.sportCourse$,
      this.fundraisingLevel$,
      this.trainingLocation$,
      this.sportCourses$,
      this.fundraisingLevels$,
      this.trainingLocations$,
      this.fundraisingGoalPersonal$,
      this.fundraisingGoalTeam$,
      this.currentStep$,
      this.teams$,
      this.teamsLoading$,
      this.selectedTeamLoading$,
      this.formDefaultLoading$,
      this.selectedTeam$,
      this.wayToParticipate$,
      this.tShirt$,
      this.createTeam$,
      this.states$,
      this.contactInfo$,
      this.useAlternateBilling$,
      this.isRegistered$,
      this.lastCaptchaResponse$,
      this.submitted$,
      this.coupon$,
      this.finalRegFeeAmount$,
      this.amount$,
      this.displayCaptcha$,
      this.tableData$,
      this.formDefault$,
      this.eventTypeOptions$,
      this.recoverPassword$,
      this.under18$,
      this.matchingCompanyName$,
      this.showKickstartForm$,
      this.eventDetailsFetched$,
      this.isUserNotRegisteredForTeam$,
      this.nextIsDisabled$,
      this.zipCodePresent$,
      this.zipCode$,
      this.showWaysToParticipate$,
      this.isSchoolFilter$
    ]
  ).pipe(
    map(([
      events,
      eventSearchKeyword,
      teamSearchKeyword,
      isSchool,
      filteredEvents,
      filteredTeams,
      pgConfig,
      selectedEvent,
      signinState,
      sportCourse,
      fundraisingLevel,
      trainingLocation,
      sportCourses,
      fundraisingLevels,
      trainingLocations,
      fundraisingGoalPersonal,
      fundraisingGoalTeam,
      currentStep,
      teams,
      teamsLoading,
      selectedTeamLoading,
      formDefaultLoading,
      selectedTeam,
      wayToParticipate,
      tShirt,
      createTeam,
      states,
      contactInfo,
      useAlternateBilling,
      isRegistered,
      lastCaptchaResponse,
      submitted,
      coupon,
      finalRegFeeAmount,
      amount,
      displayCaptcha,
      tableData,
      formDefault,
      eventTypeOptions,
      recoverPassword,
      under18,
      matchingCompanyName,
      showKickstartForm,
      eventDetailsFetched,
      isUserNotRegisteredForTeam,
      nextIsDisabled,
      zipCodePresent,
      zipCode,
      showWaysToParticipate,
      isSchoolFilter
    ]) => {
      return {
        events,
        eventSearchKeyword,
        teamSearchKeyword,
        isSchool,
        filteredEvents,
        filteredTeams,
        pgConfig,
        selectedEvent,
        signinState,
        sportCourse,
        fundraisingLevel,
        trainingLocation,
        sportCourses,
        fundraisingLevels,
        trainingLocations,
        fundraisingGoalPersonal,
        fundraisingGoalTeam,
        currentStep,
        teams,
        teamsLoading,
        selectedTeamLoading,
        formDefaultLoading,
        selectedTeam,
        wayToParticipate,
        tShirt,
        createTeam,
        states,
        contactInfo,
        useAlternateBilling,
        isRegistered,
        lastCaptchaResponse,
        submitted,
        coupon,
        finalRegFeeAmount,
        amount,
        displayCaptcha,
        tableData,
        formDefault,
        eventTypeOptions,
        recoverPassword,
        under18,
        matchingCompanyName,
        showKickstartForm,
        eventDetailsFetched,
        isUserNotRegisteredForTeam,
        nextIsDisabled,
        zipCodePresent,
        zipCode,
        showWaysToParticipate
      };
    }),
    tap(vm => {
      if ((<any>window).verboseLogging) {
        console.log('New VM State: ', vm);
      }
    }),
    // This shareReplay is needed for situations when the vm$ is under ngIf on top of bln-data-table. When it resolves, the async
    // vm$ inside of the bln-data-table must get the latest value
    // We need to pass refCount: true because the default is false and the stream will keep going even if subscribers count is zero.
    // We do this because we want the HTTP calls to be cancelled if navigated from the page
    // https://blog.strongbrew.io/share-replay-issue/
    shareReplay({ bufferSize: 1, refCount: true })
  );

  getCurrentState(): StoreState {
    return this.state as StoreState;
  }

  updateState(newState: Partial<StoreState>) {
    this.state = { ...this.state, ...newState };
    this.computeSideEffects(this.state);
    this.store.next(this.state);

    if ((<any>window).verboseLogging) {
      let customizer = (v,k) => {
        if (k && !_.isNumber(k) && k.toLowerCase().includes('password')) {
          return '********';
        }
      }

      console.log('New State: ', _.cloneDeepWith(this.state, customizer));
    }
  }

  unselectTableRow(tableStorageKey: string, filteredData, updatedObject: any, selectedRow: string, location?: string) {
    const tableData = this.getCurrentState().tableData;
    const currentTableData = tableData.find((data: any) => data.tableStorageKey === tableStorageKey);
    return filteredData.subscribe((data: any) => {
      if (!data.find((o: any) => _.isEqual(o, currentTableData.selectedRow))) {
        currentTableData.selectedRow = null;
        updatedObject[selectedRow] = null;
        if (location) {
          this.location.go(location);
        }
      }
      if (currentTableData.currentPage > Math.ceil((data.length + 1) / 6)) {
        currentTableData.currentPage = Math.ceil((data.length + 1) / 6);
      }
      updatedObject.tableData = tableData;
      this.updateState(updatedObject);
    });
  }

  private computeSideEffects(newState: Partial<StoreState>) {
    newState.finalRegFeeAmount = Number(newState.selectedEvent?.registrationFee);
    newState.registrationFeeAmount = newState.finalRegFeeAmount;
    if (newState.coupon?.isValid && newState.coupon?.couponAmountReduction > 0) {
      const deductedAmount = newState.selectedEvent.registrationFee - newState.coupon.couponAmountReduction;
      newState.finalRegFeeAmount = Math.max(deductedAmount, 0);
      newState.registrationFeeAmount = newState.finalRegFeeAmount;
    }
    if (newState.amount) {
      newState.finalRegFeeAmount += +newState.amount;
    }

    // @TODO: instead of programgroupname check, have a better backend
    if (!newState?.donationPageConfig?.enableCaptcha || this.state.pgConfig.programGroupName === 'mwoy') {
      newState.displayCaptcha = false;
    } else {
      // newState.displayCaptcha = newState.isCCDonation; @TODO: it seems legacy is broken, and always shows the captcha
      newState.displayCaptcha = true;
    }
  }

  private getEventSearchProperties(event: any) {
    // @TODO: use from backend
    const eventSearchProperties = [
      event.eventLocationCity.toLowerCase(),
      event.eventLocationState.toLowerCase(),
      event.eventLocation.toLowerCase(),
      event.walkDateAsString.toLowerCase()
    ];
    if (this.getCurrentState().zipCode) {
      eventSearchProperties.push(event.eventName.toLowerCase());
    }
    return eventSearchProperties;
  }

  private getTeamSearchProperties(team: any) {
    // @TODO: use from backend
    return [
      team.captainName ? team.captainName.toLowerCase() : '',
      team.teamName ? team.teamName.toLowerCase() : '',
    ];
  }
}

