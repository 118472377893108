import { Component, Input, OnInit } from '@angular/core';
import { IComponent } from '../../../../types/step-component.interface';
import { StoreService } from '../../../../services/store.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-event-search-by-type',
  template: `
    <ng-container *ngIf="(storeService.vm$ | async) as vm">
      <div class="mb-4 mt-4">
        <label for="eventSearch">{{ metadata.attributes.LABEL }}</label>
        <select id="eventSearch" class="form-control form-select" [(ngModel)]="selectedEventType" (change)="selectOption()">
          <option *ngFor="let option of vm.eventTypeOptions">{{option}}</option>
        </select>
      </div>
    </ng-container>
  `,
  styles: [`
      select.ng-touched.ng-valid {
          border-color: #ced4da;
          background: none;
      }
  `]
})
export class EventSearchByTypeComponent implements OnInit {
  @Input() metadata: IComponent;
  subscription: Subscription;

  selectedEventType: string;

  constructor(
    public storeService: StoreService,
    private http: HttpClient,
    private toastrService: ToastrService
  ) {
  }

  ngOnInit() {
    const currentState = this.storeService.getCurrentState();
    this.selectedEventType = currentState.selectedEventType ?
      currentState.selectedEventType : 'All Experiences';
    if (!currentState.eventTypeOptions) {
      const pgId = currentState.pgConfig.programGroupID;
      const params = new HttpParams().set('programGroupID', pgId);
      this.http.get('/OnlineRegistration/service/api/eventType', {params}).subscribe((eventTypes: any[]) => {
        let eventTypeOptions: string[] = eventTypes.map(o => o.description);
        eventTypeOptions = eventTypeOptions.map(o => o === 'Marathon' ? 'Run/Walk' : o);
        eventTypeOptions.unshift('All Experiences');
        eventTypeOptions = eventTypeOptions.filter(o => o.toUpperCase() !== 'UNDECIDED' && o.toUpperCase() !== 'DEPARTMENTAL');
        this.storeService.updateState({ eventTypeOptions });
      }, () => {
        this.toastrService.error('Something went wrong.');
      });
    }
  }

  selectOption() {
    this.subscription = this.storeService.unselectTableRow(
      'eventSearch',
      this.storeService.filteredEvents$,
      {
        selectedEventType: this.selectedEventType === 'Run/Walk' ? 'Marathon' : this.selectedEventType,
      },
      'selectedEvent',
      `event-zip-search/${this.storeService.getCurrentState().zipCode}`
    );
    this.subscription.unsubscribe();
  }
}
