import { Component, HostBinding, OnInit } from '@angular/core';
import { StoreService } from './services/store.service';
import { Title } from '@angular/platform-browser';
import { ProgressService } from './services/progress.service';
import { loadJsScript } from './shared/utils';
import { apiBaseUrl } from './shared/constants';
import { HttpClient } from '@angular/common/http';
import { FacebookLogin } from '@capacitor-community/facebook-login';
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';

declare var Five9Chat: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  @HostBinding('class') programGroupClass;

  programGroupName: string;

  constructor(
    public progressService: ProgressService,
    private http: HttpClient,
    private storeService: StoreService,
    private titleService: Title
  ) {}

  ngOnInit() {
    (<any>window).verboseLogging = false;
    (<any>window).enableVerboseLogging = () => {
        (<any>window).verboseLogging = true;
    };

    this.storeService.updateState({sessionStartTime: new Date().getTime()});
    this.http.get(`${apiBaseUrl}/config`).subscribe((env: any) => {
      this.storeService.updateState({ envConfig: env });
      this.storeService.init$.subscribe(initData => {
        this.programGroupClass = 'program-group-' + initData.pgConfig.programGroupName;
        this.programGroupName = initData.pgConfig.programGroupName;
        this.titleService.setTitle(initData.staticConfig.pageTitle);

        loadJsScript(`https://${env.envPrefix}pages.lls.org/content360/lls/javascript/five9chat.js`, () => {
          const host = `${env.envPrefix}webservices.lls.org`;
          Five9Chat.initialize(host, null, this.programGroupName, null);
        });
        this.loadStylesheet(`https://${env.envPrefix}pages.lls.org/content360/lls/css/five9chat.css`);
        this.initializeFacebook(env.facebookAppId, env.facebookGraphApiVersion);
        this.initializeGoogle(env.googleAppId);
      });

      // double the donation configs
      (window as any).DDCONF = {
        HOST: env.dtdPrefix,
        HIDE_NOT_FOUND: true,
        API_KEY: `${env.dtdPublicKey}`
      };
      this.loadStylesheet(`${env.dtdPrefix}/api/css/ddplugin.css`);
      loadJsScript(`${env.dtdPrefix}/api/js/ddplugin.js`);
    });
  }

  private loadStylesheet(url: string) {
    const node = document.createElement('link');
    node.href = url;
    node.rel = 'stylesheet';
    node.type = 'text/css';
    document.getElementsByTagName('head')[0].appendChild(node);
  }

  private initializeFacebook(facebookAppId, facebookGraphApiVersion) {
      FacebookLogin.initialize({ appId: facebookAppId, version: facebookGraphApiVersion });
  }

  private initializeGoogle(googleAppId) {
      GoogleAuth.initialize({
        clientId: googleAppId,
        scopes: ['profile', 'email']
      });
  }
}
