import { Directive } from '@angular/core';
import { UntypedFormControl, NG_VALIDATORS, Validator } from '@angular/forms';

@Directive({
  selector: '[maxlength128][ngModel]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: MaxLength128Validator, multi: true }
  ]
})
/**
 * There is a 'maxlength' attribute that functions similarly, except that it will quietly truncate
 * input to the max length. This is problematic in cases such as pasting long text into a new password input.
 * The password would be truncated to the correct length, but the user is unlikely to notice, so they wouldn't know
 * what their password is.
 */
export class MaxLength128Validator implements Validator {
  validate(control: UntypedFormControl) {
    if (control.value && control.value.length > 128) {
      return { maxlength128: true };
    }
    return null;
  }
}
