import { Component, Input } from '@angular/core';
import { IComponent } from '../../../../../types/step-component.interface';
import { StoreService } from '../../../../../services/store.service';
import { WayToParticipate } from '../../../../../types/way-to-participate.type';

@Component({
  selector: 'app-participate-create-team',
  template: `
    <ng-container *ngIf="(storeService.vm$ | async) as vm">
      <button class="btn btn-secondary mr-2"
          [class.active]="vm.wayToParticipate === 'create_team'"
          (click)="showCreateTeam()">{{metadata.attributes.LABEL}}</button>
    </ng-container>
  `
})
export class ParticipateCreateTeamComponent {
  @Input() metadata: IComponent;

  constructor(public storeService: StoreService) {}

  showCreateTeam() {
    this.storeService.updateState({wayToParticipate: WayToParticipate.CREATE_TEAM});
  }
}
