import { Directive } from '@angular/core';
import { UntypedFormControl, NG_VALIDATORS, Validator } from '@angular/forms';

@Directive({
  selector: '[amount]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: AmountValidator, multi: true }
  ]
})
export class AmountValidator implements Validator {
  validate(control: UntypedFormControl) {
    if (control?.value && control.value[0] === '$') {
      if ( !/^-?\d*\.?\d*$/.test(control.value.substring(1)) || +control.value.substring(1) <= 0) {
        return { amount: true };
      }
    } else {
      if ( !/^-?\d*\.?\d*$/.test(control.value) || +control.value <= 0) {
        return { amount: true };
      }
    }
    return null;
  }
}
