<form #form="ngForm">
  <div>
    <label class="control-label hosted-field--label" for="card-number">Credit Card Number</label>
    <div id="card-number" class="hosted-field form-control d-inline-block mt-1"></div>
    <div *ngIf="numberValidationMessage" class="invalid-feedback mb-2">
      {{numberValidationMessage}}
    </div>
  </div>
  <div class="d-flex justify-content-between">
    <div class="mr-3">
      <label class="control-label hosted-field--label" for="expiration-month">Expiration Month</label>
      <div id="expiration-month" class="hosted-field form-control d-inline-block mt-1"></div>
      <div *ngIf="monthValidationMessage" class="invalid-feedback mb-2">
        {{monthValidationMessage}}
      </div>
    </div>
    <div class="mr-3">
      <label class="control-label hosted-field--label" for="expiration-month">Expiration Year</label>
      <div id="expiration-year" class="hosted-field form-control d-inline-block mt-1"></div>
      <div *ngIf="yearValidationMessage" class="invalid-feedback mb-2">
        {{yearValidationMessage}}
      </div>
    </div>
    <div>
      <label class="control-label hosted-field--label" for="cvv">CVV</label>
      <div id="cvv" class="hosted-field form-control d-inline-block mt-1"></div>
      <div *ngIf="cvvValidationMessage" class="invalid-feedback mb-2">
        {{cvvValidationMessage}}
      </div>
    </div>
  </div>
</form>
<div style="float: right">
  <a (click)="openCvvModal($event)" style="cursor: pointer">What's a CVV?</a>
</div>

<app-alternate-billing #alternateBilling></app-alternate-billing>
