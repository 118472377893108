<div class="container lls-container">
  <ng-container *ngIf="(storeService.vm$ | async) as vm">
    <div class="registering-for">
      <div *ngIf="vm.currentStep !== 0 && vm.selectedEvent">
        Event: <span class="font-weight-bold">{{ vm?.selectedEvent?.eventName }}</span>
      </div>
      <div *ngIf="vm.currentStep !== 0 && vm.selectedTeam">
        Team: <span class="font-weight-bold">{{ vm?.selectedTeam?.teamName }}</span>
      </div>
    </div>
  </ng-container>

  <ul class="stepper">
    <li *ngFor="let item of stepDefinitions; let i = index"
      [class.completed]="i < currentStep"
      [class.active]="i === currentStep"
    >
          <span class="step-title">
            <span class="circle" [ngClass]="{current: i === currentStep}">{{ i + 1 }}</span>
            <span *ngIf="i === currentStep" class="step-name">
              {{ item.attributes.STEP_TITLE ? item.attributes.STEP_TITLE : item.sysComponentName }}
            </span>
          </span>
    </li>
  </ul>

  <h2 class="mobile-step-title">
    {{ currentStepObject.attributes.STEP_TITLE }}
  </h2>

  <ng-template step-host></ng-template>

  <ng-container *ngIf="(storeService.vm$ | async) as vm">
    <div *ngIf="currentComponentInstance.hasStepControls" class="controls" id="step-controls">
      <button *ngIf="currentStep > 0 && currentStep < stepDefinitions.length - 1" (click)="previousStep()" class="btn btn-secondary mr-1">Previous</button>
      <button *ngIf="currentStep < stepDefinitions.length - 2" (click)="nextStep()" class="btn btn-primary" [disabled]="isLoading || vm.nextIsDisabled">
        <i *ngIf="isLoading" class="fa fa-spinner fa-spin"></i> Next
      </button>
      <button *ngIf="currentStep === stepDefinitions.length - 2" (click)="nextStep()" class="btn btn-primary" [disabled]="isLoading || vm.nextIsDisabled">
        <i *ngIf="isLoading" class="fa fa-spinner fa-spin"></i> Complete Registration
      </button>
    </div>
  </ng-container>
</div>
