import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { WayToParticipate } from '../types/way-to-participate.type';
import { StoreService } from './store.service';
import { ProgressService } from './progress.service';
import { ActivatedRoute } from '@angular/router';
import { BackendService } from './backend.service';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class DeepLinkingService {
  constructor(
    private http: HttpClient,
    private progressService: ProgressService,
    private backendService: BackendService,
    private storeService: StoreService,
    private toastrService: ToastrService
  ) {}

  initialize(deepLinking: string, stepDefinitions, activatedRoute: ActivatedRoute) {
    let params: any;
    let deepLinkingType = '';
    switch (deepLinking) {
      case 'event-search':
        deepLinkingType = 'EVENT_SEARCH';
        break;
      case 'ltn-event-selected':
        deepLinkingType = 'EVENT_SEARCH';
        this.handleEventSelected(activatedRoute.snapshot.params.eventId).subscribe(() => {
          this.progressService.unblock();
        });
        break;
      case 'event-selected-team-selected':
        deepLinkingType = 'EVENT_SEARCH';
        const eventId = activatedRoute.snapshot.params.eventId;
        const zipCode$ = new Observable<string>((observer) => {
          if (this.storeService.getCurrentState().pgConfig.programGroupName === 'tnt') {
            this.http.get(`/OnlineRegistration/service/api/event?eventId=${eventId}`)
              .subscribe((event: any) => {
                this.storeService.updateState({ zipCode: event.oocpePostalCode });
                observer.next(event.oocpePostalCode);
                observer.complete();
              }, () => {
                observer.next(null);
                observer.complete();
              });
          } else {
            observer.next(null);
            observer.complete();
          }
        });

        zipCode$.pipe(
          switchMap((zipCode) => {
            console.log('ZIIIP', zipCode);
            return this.handleEventSelected(eventId, zipCode);
          }),
          switchMap(() => {
            return this.backendService.loadTeams();
          })
        ).subscribe((teams) => {
          this.progressService.unblock();
          const teamId = activatedRoute.snapshot.params.teamId;
          const selectedTeam = this.storeService.getCurrentState().teams.find(o => o.teamResultDTOID === teamId);
          this.storeService.updateState({ wayToParticipate: WayToParticipate.JOIN_TEAM });
          this.storeService.updateState({ selectedTeam });
          this.updateTableData('team', teams, +teamId, selectedTeam, 'teamResultDTOID');
        });
        break;
      case 'national-team-selected':
        deepLinkingType = 'EVENT_SEARCH';
        params = activatedRoute.snapshot.params;
        this.storeService.updateState({nationalTeam: {teamID: params.teamId, teamType: params.teamType}});
        break;
      case 'tnt-event-search-by-zip':
        deepLinkingType = 'EVENT_SEARCH';
        params = activatedRoute.snapshot.params;
        this.storeService.updateState({zipCode: params.zipCode});
        this.backendService.loadEvents(params.zipCode).subscribe(() => {});
        break;
      case 'tnt-selected-event':
        deepLinkingType = 'EVENT_SEARCH';
        params = activatedRoute.snapshot.params;
        this.storeService.updateState({zipCode: params.zipCode});
        this.backendService.loadEvents(params.zipCode).subscribe(res => {
          if (params.eventId) {
            const selectedEvent = _.cloneDeep(res.find(event => event.eventID === +params.eventId));
            this.storeService.updateState({ selectedEvent });
            this.updateTableData('eventSearch', res, +params.eventId, selectedEvent, 'eventID');
            this.backendService.loadFormDefault(params.eventId);
          }
        });
        break;
      case 'mwoy-team-search':
        deepLinkingType = 'EVENT_SPECIFIC_INFORMATION';
        break;
      case 'mwoy-team-selected':
        deepLinkingType = 'EVENT_SPECIFIC_INFORMATION';
        params = activatedRoute.snapshot.params;
        this.backendService.loadTeams().subscribe(res => {
          if (params.teamId) {
            const selectedTeam = _.cloneDeep(res.find(team => team.teamResultDTOID === params.teamId));
            this.updateTableData('team', res, +params.teamId, selectedTeam, 'teamResultDTOID');
            this.storeService.updateState({ selectedTeamLoading: true });
            this.http.get(`/OnlineRegistration/service/api/event?eventId=${selectedTeam.walksiteID}`).subscribe((selectedEvent: any) => {
              this.storeService.updateState({ selectedEvent });
              this.storeService.updateState({ selectedTeamLoading: false });
              this.storeService.updateState({selectedTeam});
              this.backendService.loadFormDefault(selectedEvent.eventID);
            }, () => {
              this.toastrService.error('Something went wrong.');
            });
          }
        });
        break;
    }
    return stepDefinitions.findIndex(x => x.sysComponentName === deepLinkingType);
  }

  private handleEventSelected(eventId: string, zipCode?: string): Observable<any> {
    return this.backendService.loadEvents(zipCode).pipe(
      tap(res => {
        const selectedEvent = _.cloneDeep(res.find(event => event.eventID === +eventId));
        this.storeService.updateState({selectedEvent});
        this.updateTableData('eventSearch', res, +eventId, selectedEvent, 'eventID');
        this.backendService.loadFormDefault(eventId);
      })
    );
  }

  // @TODO: this is duplicate from table.component.ts, move it to store.service generically
  private updateTableData(tableStorageKey: string, rows: any[], rowId: number, selectedRow: any, searchProperty: string) {
    const currentPage = Math.ceil((rows.findIndex(x => +x[searchProperty] === +rowId) + 1) / 6);
    let tableData = this.storeService.getCurrentState()?.tableData;
    const specificTableData = tableData.find(o => o.tableStorageKey === tableStorageKey);
    if (specificTableData) {
      _.merge(specificTableData, {
        tableStorageKey,
        currentPage,
        activeSort: {
          column: '',
          dir: ''
        },
        selectedRow,
        hasPagination: rows.length > 6,
      });
    } else {
      tableData = [
        ...tableData,
        {
          tableStorageKey,
          currentPage,
          activeSort: {
            column: '',
            dir: ''
          },
          selectedRow,
          hasPagination: rows.length > 6,
        }
      ];
    }
    this.storeService.updateState({ tableData });
  }
}
