import {
  Component,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import * as _ from 'lodash';
import { IComponent } from '../../../../types/step-component.interface';
import { StoreService } from '../../../../services/store.service';
import { IValidationSubComponent } from '../../../../types/subcomponent-validation.interface';

@Component({
  selector: 'app-t-shirt',
  template: `
    <ng-container *ngIf="(storeService.vm$ | async) as vm">
      <h3>{{metadata.attributes.HEADING}}</h3>
      <p>{{metadata.attributes.DESCRIPTION}}</p>

      <ng-container *ngIf="tShirts">
        <div *ngIf="vm.pgConfig.programGroupName === 'ltn'" class="box">
          <div class="mr-2 mt-2"><img src="assets/images/shirt.svg"></div>
          <div *ngFor="let tShirt of tShirts">
            <div [ngClass]="{'is-selected': tShirt.description === selectedTshirt}" class="tShirt" (click)="selectTShirt(tShirt.description)">
              {{tShirt.name}}
            </div>
          </div>
        </div>
        <select *ngIf="vm.pgConfig.programGroupName === 'tnt' && !isIE" #selectChrome
                [ngModel]="selectedTshirt"
                (ngModelChange)="selectTShirt($event)"
                (blur)="close()"
                class="form-control form-select"
        >
          <option [value]="" hidden>
            Shirt size
          </option>
          <option *ngFor="let tShirt of tShirts" [ngValue]="tShirt.description">
            {{ tShirt.description }}
          </option>
        </select>
        <ng-select *ngIf="vm.pgConfig.programGroupName === 'tnt' && isIE" #select
                   [items]="tShirts"
                   bindLabel="description"
                   placeholder="Shirt size"
                   class="custom"
                   bindValue="description"
                   (close)="close()"
                   (ngModelChange)="selectTShirt($event)"
                   [ngModel]="selectedTshirt">
        </ng-select>
      </ng-container>
      <div *ngIf="!tShirts" class="spinner-border">
        <span class="sr-only">Loading...</span>
      </div>
      <div *ngIf="validated && !vm.tShirt" class="alert alert-danger mt-2" role="alert">
        Choosing T Shirt size is required.
      </div>
    </ng-container>
  `,
  styles: [`
    .box {
      display: flex;
      justify-content: space-between;
      padding: 0.75em;
      border: 1px solid #D1D1D1;
    }

    .ng-select.custom {
      border: none;
    }

    .ng-select.custom ::ng-deep .ng-select-container {
      height: 48px;
      font-size: 16px;
      font-weight: 400;
      border-color: #ced4da;
    }

    .ng-select.custom.ng-touched.ng-valid ::ng-deep .ng-select-container {
      border-color: #3eb16d;
      background-position: calc(100% - 20px) center;
      padding-right: 2.3em;
      background-image: url(data:image/svg+xml;charset=US-ASCII,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22utf-8%22%3F%3E%3C!DOCTYPE%20svg%20PUBLIC%20%22-%2F%2FW3C%2F%2FDTD%20SVG%201.1%2F%2FEN%22%20%22http%3A%2F%2Fwww.w3.org%2FGraphics%2FSVG%2F1.1%2FDTD%2Fsvg11.dtd%22%3E%3Csvg%20version%3D%221.1%22%20id%3D%22Layer_1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20x%3D%220px%22%20y%3D%220px%22%20%20width%3D%2215.999px%22%20height%3D%2216px%22%20viewBox%3D%220%200%2015.999%2016%22%20enable-background%3D%22new%200%200%2015.999%2016%22%20xml%3Aspace%3D%22preserve%22%3E%3Cpath%20fill%3D%22%233EB16E%22%20d%3D%22M8%2C0C3.581%2C0%2C0%2C3.582%2C0%2C8c0%2C4.418%2C3.581%2C8%2C8%2C8c4.418%2C0%2C7.999-3.582%2C7.999-8S12.418%2C0%2C8%2C0z%20M8%2C14.5%20c-3.59%2C0-6.5-2.91-6.5-6.5c0-3.59%2C2.91-6.5%2C6.5-6.5s6.499%2C2.91%2C6.499%2C6.5C14.499%2C11.59%2C11.59%2C14.5%2C8%2C14.5z%20M11.144%2C4.597%20c-0.34-0.203-0.774-0.083-0.97%2C0.27L7.491%2C9.686L5.997%2C7.867c-0.271-0.312-0.63-0.473-0.969-0.27C4.688%2C7.8%2C4.548%2C8.29%2C4.768%2C8.604%20l2.079%2C2.531c0.155%2C0.201%2C0.333%2C0.322%2C0.519%2C0.357l0.001%2C0.002l0.02%2C0.002c0.042%2C0.006%2C0.67%2C0.122%2C0.94-0.361l3.079-5.531%20C11.601%2C5.251%2C11.483%2C4.8%2C11.144%2C4.597z%22%2F%3E%3C%2Fsvg%3E);
      background-repeat: no-repeat;
    }

    .ng-select.custom.ng-touched.ng-invalid ::ng-deep .ng-select-container {
      border-color: #cb0000;
    }

    .ng-select.custom ::ng-deep .ng-placeholder {
      color: #495057;
    }

    .tShirt {
      padding: 0.5em 0.7em;
      border-radius: 4em;
    }

    .tShirt.is-selected {
      background-color: #febd0f;
    }

    .tShirt:hover {
      cursor: pointer;
      background-color: #fed66b;
    }

    @media only screen and (max-width: 768px) {
        .box {
            flex-wrap: wrap;
            justify-content: start;
        }
    }
  `]
})
export class TShirtComponent implements IValidationSubComponent, OnInit {
  @ViewChild('select') select;
  @ViewChild('selectChrome') selectChrome;
  @Input() metadata: IComponent;

  isIE = false;
  selectedTshirt: any;
  tShirts = null;
  validated = false;

  constructor(
    public storeService: StoreService){
  }

  ngOnInit() {
    this.isIE = window.navigator.userAgent.toLowerCase().indexOf('trident') > -1;
    const currentState = this.storeService.getCurrentState();
    const noTshirtOption = { description: 'I do not want a training shirt' };
    this.tShirts = _.clone(currentState.formDefault.shirtSizeDTOs);
    if (this.isIE) {
      this.selectedTshirt = currentState.tShirt?.description;
    } else {
      this.selectedTshirt = currentState.tShirt?.description || 'Shirt size';
    }

    if (this.storeService.getCurrentState().pgConfig.programGroupName === 'tnt') {
      this.tShirts.push(noTshirtOption);
    }
  }

  validate(): boolean {
    this.validated = true;
    this.validateTshirt();
    return !!this.storeService.getCurrentState().tShirt;
  }

  close() {
    this.validateTshirt();
  }

  selectTShirt(value: string) {
    if (value) {
      const selectedTshirt = this.tShirts.find(o => o.description === value);
      this.selectedTshirt = selectedTshirt.description;
      this.storeService.updateState({ tShirt: selectedTshirt });
    } else {
      this.selectedTshirt = null;
      this.storeService.updateState({ tShirt: null });
    }
    this.validateTshirt();
  }

  private validateTshirt() {
    if (!this.select && !this.selectChrome) { return; }
    const elem = this.select ? this.select.element : this.selectChrome.nativeElement;

    elem.classList.add('ng-touched');
    if (!this.selectedTshirt || this.selectedTshirt === 'Shirt size') {
      elem.classList.remove('ng-valid');
      elem.classList.add('ng-invalid');
    } else {
      elem.classList.remove('ng-invalid');
      elem.classList.add('ng-valid');
    }
  }
}
