import { Component, Input } from '@angular/core';
import { IComponent } from '../../../../../../types/step-component.interface';
import { StoreService } from '../../../../../../services/store.service';
import * as moment from 'moment';

@Component({
  selector: 'app-selected-candidate-details',
  template: `
    <ng-container *ngIf="(storeService.vm$ | async) as vm">
      <h4><b>{{metadata.attributes.HEADING}}</b></h4>
      <div class="mt-3 detail" *ngIf="vm.selectedTeamLoading">
        Loading ...
      </div>
      <div class="mt-3 detail" *ngIf="!vm.selectedTeamLoading">
        <h6 class="mb-0"><b>{{ vm.selectedTeam.captainName }}, {{ vm.selectedTeam.walksiteName }}</b></h6>
      </div>
    </ng-container>
  `,
  styles: [`
    .detail {
      padding: 0.75em;
      border: 1px solid #D1D1D1;
    }
  `]
})
export class SelectedCandidateDetailsComponent {
  @Input() metadata: IComponent;

  constructor(public storeService: StoreService) {}

  convertDate(date) {
    return moment.unix(date / 1000).format('dddd, MMMM DD, YYYY');
  }
}
