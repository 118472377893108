<form class="form-row" (ngSubmit)="submit()" #form="ngForm">
  <div class="form-group col-md-12">
    <h4>Forgot Your Password?</h4>
    <p>Enter your email address and click Recover Password to receive an email with further password reset instructions.</p>
  </div>
  <div class="form-group col-md-8">
    <input type="text"
      class="form-control"
      name="emailAddress"
      placeholder="Email Address"
      [(ngModel)]="email"
      #username="ngModel"
      email
      required
    >
    <control-errors [control]="username" [name]="'Email address'"></control-errors>
  </div>
  <div class="form-group col-md-4">
    <button type="submit" class="btn btn-secondary">Recover Password</button>
  </div>
</form>
<div class="mt-3 medium-fontsize">
  <a href="javascript: void(0);" (click)="openRegisterForm()">Don't have an account? Create a new one</a>
</div>
