import { Component, Input } from '@angular/core';
import { IComponent } from '../../../../../../types/step-component.interface';
import { StoreService } from '../../../../../../services/store.service';
import { WayToParticipate } from '../../../../../../types/way-to-participate.type';

@Component({
  selector: 'app-no-team-result-found',
  template: `
    <div class="detail">
      <h4 [innerHTML]="metadata.attributes.HEADING"></h4>
      You can search for another team name or <a (click)="goToCreateTeam()">Create your own</a>.
    </div>
  `,
  styles: [`
    .detail {
      padding: 0.75em;
      border: 1px solid #D1D1D1;
    }

    a {
        cursor: pointer;
        text-underline: none;
    }
  `]
})
export class NoTeamResultFoundComponent {
  @Input() metadata: IComponent;

  constructor(private storeService: StoreService) {
  }

  goToCreateTeam() {
    this.storeService.updateState({wayToParticipate: WayToParticipate.CREATE_TEAM});
  }
}
