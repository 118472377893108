import { EventSearchComponent } from '../components/steps/event-search/event-search.component';
import { ConfirmationComponent } from '../components/steps/confirmation/confirmation.component';
import { SignInComponent } from '../components/steps/sign-in/sign-in.component';
import { EventSpecificInfoComponent } from '../components/steps/event-specific-information/event-specific-info.component';
import { PersonalInfoComponent } from '../components/steps/personal-info/personal-info.component';
import { PaymentInfoComponent } from '../components/steps/payment/payment-info.component';

export const ComponentMappings = {
  EVENT_SEARCH: EventSearchComponent,
  EVENT_SPECIFIC_INFORMATION: EventSpecificInfoComponent,
  SIGN_IN: SignInComponent,
  PERSONAL_INFORMATION: PersonalInfoComponent,
  PAYMENT_INFO: PaymentInfoComponent,
  CONFIRMATION: ConfirmationComponent
};
