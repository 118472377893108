import { Directive } from '@angular/core';
import { UntypedFormControl, NG_VALIDATORS, Validator } from '@angular/forms';
import { StoreService } from '../../services/store.service';

@Directive({
  selector: '[signature]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: SignatureValidator, multi: true }
  ]
})
export class SignatureValidator implements Validator {

  constructor(private storeService: StoreService) {
  }

  validate(control: UntypedFormControl) {
    const currentState = this.storeService.getCurrentState();
    const contactInfo = currentState.contactInfo;
    if (currentState.under18) {
      return null;
    }
    if (!contactInfo || control.value !== contactInfo.firstName + ' ' + contactInfo.lastName) {
      return { signature: true };
    }
    return null;
  }
}
