import { Component, Input } from '@angular/core';
import { IComponent } from '../../../../types/step-component.interface';
import { StoreService } from '../../../../services/store.service';

@Component({
  selector: 'app-registered-event-registration-fee',
  template: `
    <ng-container *ngIf="(storeService.vm$ | async) as vm">
      <h3 class="mt-3">Registration Fee</h3>
      <div class="mt-3">Your credit card has been charged {{vm.finalRegFeeAmount | currency}}.</div>
    </ng-container>
  `
})
export class RegistrationFeeComponent {
  @Input() metadata: IComponent;

  constructor(public storeService: StoreService) {}
}
