import { Component, Input, OnInit } from '@angular/core';
import { IComponent } from '../../../../../../types/step-component.interface';
import { HttpClient } from '@angular/common/http';
import { StoreService } from '../../../../../../services/store.service';
import { ToastrService } from 'ngx-toastr';
import { NationalTeam } from "../../../../../../types/national-team.interface";
import { PROGRAM_GROUP_INFO } from '../../../../../../shared/constants';

@Component({
  selector: 'app-national-team',
  template: `
    <div>
      <label *ngIf="teamType !== 'SCHOOL'" for="select">{{ metadata.attributes.LABEL }} (optional)</label>
      <label *ngIf="teamType === 'SCHOOL'" for="select">Select Your {{ schoolLabel }}</label>
      <select id="select"
          class="form-control form-select"
          [(ngModel)]="selectedNationalTeamName"
          (change)="selectOption()"
      >
        <ng-container *ngIf="isLoading">
          <option disabled selected [value]="undefined">Loading...</option>
        </ng-container>
        <ng-container *ngIf="!isLoading">
          <option *ngFor="let option of options" [value]="option.teamName">{{option.teamName}}</option>
        </ng-container>
      </select>
      <label *ngIf="teamSubType === 'CLASSROOM'" for="select">Don't see your school? <a href="mailto:{{programGroupInfo['ltn'].contactMail}}" target="_blank">Email us</a> or call: {{programGroupInfo['ltn'].callNumber}}</label>
      <label *ngIf="teamType === 'SCHOOL' && teamSubType === ''" for="select">Select 'LLS National Supporter District' if you don’t see your district listed.</label>
    </div>
  `
})
export class NationalTeamComponent implements OnInit {
  @Input() metadata: IComponent;

  isLoading = true;
  options: any[];
  allTeams: any[];
  allNatTeams: any[];
  teamType = 'FRIEND_FAMILY';
  teamSubType = '';
  selectedNationalTeamName = undefined;
  schoolLabel = 'School';
  programGroupInfo = PROGRAM_GROUP_INFO;

  constructor(
    private http: HttpClient,
    private storeService: StoreService,
    private toastrService: ToastrService
  ) {
  }

  ngOnInit() {
    this.getOptions();
    this.storeService.createTeam$.subscribe((result: any) => {
      if (this.teamType != result.teamType || this.teamSubType != result.teamSubType) {
        this.teamType = result.teamType;
        this.teamSubType = result.teamSubType;
        this.filterOptions();
      }
    });
    this.storeService.teams$.subscribe((result: any) => {
      this.allTeams = result;
      this.isLoading = !(this.allTeams && this.allNatTeams);
      this.restoreSelection();
      this.filterOptions();
    });
  }

  selectOption() {
    const nationalTeam = this.options.find((o: any) => o.teamName === this.selectedNationalTeamName);
    if (nationalTeam.teamResultDTOID && !nationalTeam.teamID) {
       nationalTeam.teamID = nationalTeam.teamResultDTOID;
    }
    this.storeService.updateState({ nationalTeam });
  }

  getOptions() {
    this.http.get('/OnlineRegistration/service/api/nationalTeams?pgName=LTN').subscribe((res: any) => {
      this.allNatTeams = res;
      this.isLoading = !(this.allTeams && this.allNatTeams);
      this.restoreSelection();
      this.filterOptions();
    }, () => {
      this.toastrService.error('Something went wrong.');
    });
  }

  filterOptions() {
    if (!this.isLoading) {
      if (this.teamType == 'SCHOOL') {
        if (this.teamSubType == 'CLASSROOM') {
          this.schoolLabel = 'School';
          if (this.allTeams) {
            this.options = this.allTeams.filter((team) => team.isSchool);
          }
          this.options.unshift({ teamName: 'My school is not yet registered / My classroom is participating alone' });
        } else {
          this.schoolLabel = 'District';
          if (this.allNatTeams) {
            this.options = this.allNatTeams.filter((nTeam) => nTeam.teamType == this.translateOption(this.teamType));
          }
        }
      } else {
        if (this.allNatTeams) {
          this.options = this.allNatTeams.filter((nTeam) => nTeam.teamType == this.translateOption(this.teamType));
        }
        this.options.unshift({ teamName: 'Not part of National Partner Program' });
      }
    }
  }

  translateOption(type: string): string {
    if (type == 'FRIEND_FAMILY') {
        return 'NATIONAL_F_F';
    } else if (type == 'CORPORATE') {
        return 'NATIONAL_CORP';
    } else if (type == 'SCHOOL') {
        return 'SCHOOL_DISTRICT';
    } else {
        return type;
    }
  }

  restoreSelection() {
    if (!this.isLoading) {
      const teamId = this.storeService.getCurrentState()?.nationalTeam?.teamID || null;
      let selectedTeam: NationalTeam;
      if (this.teamType == 'SCHOOL') {
        if (this.teamSubType == 'CLASSROOM') {
          if (teamId) {
            selectedTeam = this.allTeams.find(team => team.teamResultDTOID === teamId);
            this.selectedNationalTeamName = selectedTeam ? selectedTeam.teamName : 'My school is not yet registered / My classroom is participating alone';
          } else {
            this.selectedNationalTeamName = this.storeService.getCurrentState()?.nationalTeam?.teamName || 'My school is not yet registered / My classroom is participating alone';
          }
        } else { // School
          if (teamId) { // for a School, this is required
            selectedTeam = this.allNatTeams.find(team => team.teamID === teamId);
            this.selectedNationalTeamName = selectedTeam.teamName;
          }
        }
      } else {
        if (teamId) {
          selectedTeam = this.allNatTeams.find(team => team.teamID === teamId);
          this.selectedNationalTeamName = selectedTeam ? selectedTeam.teamName : 'Not part of National Partner Program';
        } else {
          this.selectedNationalTeamName = this.storeService.getCurrentState()?.nationalTeam?.teamName || 'Not part of National Partner Program';
        }
      }
    }
  }
}
