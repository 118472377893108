import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { IComponent } from '../../../../types/step-component.interface';
import { StoreService } from '../../../../services/store.service';
import { IValidationSubComponent } from '../../../../types/subcomponent-validation.interface';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { Location } from '@angular/common';
import { scrollIntoView } from '../../../../shared/utils';

@Component({
  selector: 'app-training-location',
  template: `
    <ng-container *ngIf="(storeService.vm$ | async) as vm">
      <h3 id="training">{{metadata.attributes.HEADING}}</h3>
      <p>{{metadata.attributes.DESCRIPTION}}</p>

      <app-table
        *ngIf="(rows$ | async) as trainingLocations"
        [rows]="trainingLocations"
        tableStorageKey="training-location"
        (selectRow)="onSelectRow($event)"
      >
        <ng-container appColumnDef="Select Your Training Community">
          <span *appCellDef="let row">{{ row.name }}</span>
        </ng-container>
      </app-table>
      <div *ngIf="!rows$" class="spinner-border">
        <span class="sr-only">Loading...</span>
      </div>
      <div *ngIf="validated && !vm.trainingLocation" class="alert alert-danger" role="alert">
        Choosing Training Location is required.
      </div>
    </ng-container>
  `
})
export class TrainingLocationComponent implements IValidationSubComponent, OnInit, AfterViewInit {
  @Input() metadata: IComponent;

  rows$: Observable<any>;
  validated = false;

  constructor(
    public location: Location,
    public storeService: StoreService
  ) {}

  ngOnInit() {
    this.rows$ = this.storeService.trainingLocations$;
  }

  ngAfterViewInit() {
    if (!this.storeService.getCurrentState().fundraisingLevels) {
      if (this.storeService.getCurrentState().sportCourses) {
        this.storeService.sportCourse$.pipe(
          switchMap(val => {
            return this.rows$;
          })
        ).subscribe((o) => {
          if (o) {
            scrollIntoView('training');
          }
        });
      }
    } else {
      this.storeService.fundraisingLevel$.pipe(
        switchMap(val => {
          return this.rows$;
        })
      ).subscribe((o) => {
        if (o) {
          scrollIntoView('training');
        }
      });
    }
  }

  validate(): boolean {
    this.validated = true;
    return !!this.storeService.getCurrentState().trainingLocation;
  }

  onSelectRow(row: any) {
    this.storeService.updateState({ trainingLocation: row });
  }
}
