import { Directive } from '@angular/core';
import { AbstractControl, AsyncValidator, NG_ASYNC_VALIDATORS, ValidationErrors } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import {StoreService} from "../../services/store.service";

@Directive({
  selector: '[primaryContactEmailAvailability]',
  providers: [
    { provide: NG_ASYNC_VALIDATORS, useExisting: PrimaryContactEmailAvailabilityValidator, multi: true }
  ]
})
export class PrimaryContactEmailAvailabilityValidator implements AsyncValidator {
  private inputTimeout: any;

  constructor(private http: HttpClient, private toastrService: ToastrService, private storeService: StoreService) {}

  validate(
    control: AbstractControl
  ): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> {
    clearTimeout(this.inputTimeout);

    return new Promise(resolve => {

      /* If there is a signinResult with a username, we don't have to validate the primary contact email, since the returned
       * username will be used in lieu of the Primary Contact Email
       */
      if (this.storeService.getCurrentState() && this.storeService.getCurrentState().signinResult && this.storeService.getCurrentState().signinResult.username) {
        resolve(null);
      } else {
        this.inputTimeout = setTimeout(() => {
          this.http.get('/OnlineRegistration/service/api/isUsernameAvailable', {
            params: {
              username: encodeURIComponent(control.value)
            }
          }).subscribe((result: any) => {
            resolve(result.isAvailable ? null : {emailAvailability: true});
          }, () => {
            this.toastrService.error('Something went wrong.');
          });
        }, 500);
      }
    });
  }
}
