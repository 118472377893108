import { Directive } from '@angular/core';
import { UntypedFormControl, NG_VALIDATORS, Validator } from '@angular/forms';

@Directive({
  selector: '[validCharsOnly][ngModel]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: ValidCharsOnlyValidator, multi: true }
  ]
})
export class ValidCharsOnlyValidator implements Validator {

  invalidCharsRegex = /[^a-zA-Z\d!-~€£¥¢]/;
  validate(control: UntypedFormControl) {
    if (control.value && this.invalidCharsRegex.test(control.value)) {
      return { validCharsOnly: true };
    }
    return null;
  }
}
