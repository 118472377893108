import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';
import { StoreService } from '../../services/store.service';

@Directive({
  selector: '[equalOrGreaterThanFundraisingCommitment]',
  providers: [{provide: NG_VALIDATORS, useExisting: EqualOrGreaterThanFundraisingCommittmentValidator, multi: true}]
})
export class EqualOrGreaterThanFundraisingCommittmentValidator implements Validator {
  constructor(private storeService: StoreService) {}

  validate(control: AbstractControl): ValidationErrors | null {
    if (!control.value) {
      return null;
    }

    const state = this.storeService.getCurrentState();
    if (state.pgConfig.programGroupName !== 'tnt') {
      return null;
    }

    if (state.fundraisingGoalPersonal) {
      if (state.fundraisingGoalPersonal > control.value) {
        return { equalOrGreaterThanFundraisingCommitment: true };
      }
    }

    const fundraisingLevel = state.fundraisingLevel;
    if (!fundraisingLevel?.amount) {
      return null;
    }

    return fundraisingLevel.amount > control.value ? { equalOrGreaterThanFundraisingCommitment: true } : null;
  }
}
