import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Team } from '../types/team.interface';
import { StoreService } from './store.service';
import { ProgressService } from './progress.service';
import { Event } from '../types/event.interface';
import { catchError, map, tap } from 'rxjs/operators';
import { FundraisingLevel, SportCourse } from '../types/event-specific';
import { of } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class BackendService {
  constructor(
    private http: HttpClient,
    private progressService: ProgressService,
    private storeService: StoreService,
    private toastrService: ToastrService
  ) {}

  loadTeams() {
    const programGroupName = this.storeService.getCurrentState().pgConfig.programGroupName;
    const eventId = this.storeService.getCurrentState().selectedEvent
      ? this.storeService.getCurrentState().selectedEvent.eventID : '';
    const params = new HttpParams().set('eventID', String(eventId)).set('programGroupName', programGroupName);
    const endpoint = `/OnlineRegistration/service/api/teams`;
    this.progressService.block();
    this.storeService.updateState({ teamsLoading: true });
    return this.http.get(endpoint, {params}).pipe(
      catchError(err => {
        this.toastrService.error('Something went wrong.');
        return of(null);
      }),
      tap((res: Team[]) => {
        res.forEach(o => {
          delete o.uid;
          delete o.properties;
        });
        this.storeService.updateState({ teamsLoading: false });
        this.storeService.updateState({teams: res});
        this.progressService.unblock();
      })
    );
  }

  loadEvents(zipCode?: string) {
    const pgName = this.storeService.getCurrentState().pgConfig.programGroupName;
    let params = new HttpParams().set('programGroupName', pgName.toUpperCase());
    if (zipCode) {
      params = params.set('searchTearm', zipCode);
    }
    this.progressService.block();
    return this.http.get(`/OnlineRegistration/service/api/events`, {params})
      .pipe(
        catchError(err => {
          this.toastrService.error('Something went wrong.');
          return of(null);
        }),
        tap((res: Event[]) => {
          this.storeService.updateState({events: res});
          this.progressService.unblock();
        })
      );
  }

  loadFormDefault(eventId) {
    const pgName = this.storeService.getCurrentState().pgConfig?.programGroupName;
    const params = new HttpParams().set('eventID', String(eventId)).set('pgName', pgName);
    this.storeService.updateState({ formDefaultLoading: true });
    this.http.get(`/OnlineRegistration/service/api/formDefault`, {params}).subscribe((res: any) => {
      this.storeService.updateState({ formDefaultLoading: false });
      this.storeService.updateState({formDefault: res});
    }, () => {
      this.toastrService.error('Something went wrong.');
    });
  }

  loadSportCourseOptions() {
    const eventId = this.storeService.getCurrentState().selectedEvent.eventID;
    const marketId = this.storeService.getCurrentState().selectedEvent.marketID;
    const params = new HttpParams().set('eventId', String(eventId)).set('marketID', String(marketId));
    return this.http.get(`/OnlineRegistration/service/api/sportCourse`, {params})
      .pipe(
        map((res: SportCourse[]) => {
          if (res && res.length > 0) {
            const sportCourses = res.map(o => {
              const splitted = o.name.split(',');
              return {
                id: o.id,
                sport: splitted[0],
                course: splitted[1]
              };
            });
            this.storeService.updateState({ sportCourses });
          }
        })
      );
  }

  loadFundraisingLevelOptions() {
    const eventId = this.storeService.getCurrentState().selectedEvent.eventID;
    const marketId = this.storeService.getCurrentState().selectedEvent.marketID;
    const params = new HttpParams().set('eventId', String(eventId)).set('marketID', String(marketId));
    return this.http.get(`/OnlineRegistration/service/api/getFRPackages`, {params})
      .pipe(
        map((res: FundraisingLevel[]) => {
          if (res && res.length > 0) {
            const fundraisingLevels = res.map(row => {
              // uid always keep changing and isEquals do not work because of it
              delete row.uid;
              delete row.properties;
              return row;
            });
            this.storeService.updateState({ fundraisingLevels });
          }
        })
      );
  }

  loadTrainingLocationOptions() {
    const eventId = this.storeService.getCurrentState().selectedEvent.eventID;
    const params = new HttpParams().set('eventId', String(eventId));
    return this.http.get(`/OnlineRegistration/service/api/trainingTeam`, {params})
      .pipe(
        map((res: any[]) => {
          const onlineWithCoachesRow = res?.find((o: any) => o.name.includes('(TNT Flex)'));
          onlineWithCoachesRow.name = 'Online With Coaches';
          onlineWithCoachesRow.description = 'Online With Coaches';
          if (res && res.length > 0) {
            this.storeService.updateState({trainingLocations: res});
          }
        })
      );
  }
}
