import { Component, Input, ViewChild } from '@angular/core';
import { IComponent } from '../../../../types/step-component.interface';
import { StoreService } from '../../../../services/store.service';
import { NgForm } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PolicyModalComponent } from '../../../../shared/components/policy-modal.component';

@Component({
  selector: 'app-terms-conditions',
  template: `
    <h3 id="terms-conditions" *ngIf="showHeading">{{metadata.attributes.HEADING}}</h3>
    <form #form="ngForm">
      <input
        type="checkbox"
        name="tc"
        id="tc"
        required
        tc
        ngModel
        (ngModelChange)="storeService.updateState({termsAndConditions: $event})"
        #tc="ngModel">
      <label for="tc" class="mb-3 d-inline">
        I agree that electronic submission constitutes signature, and in doing so, I acknowledge
        and represent that I have read and understand these <a (click)="openPolicyModal($event)" style="cursor: pointer; text-decoration: underline">terms and conditions</a> and agree to
        them voluntarily.
      </label>
      <div *ngIf="tc.errors?.tc" class="invalid-feedback">
        You have to agree to 'Terms & Conditions'.
      </div>
    </form>
  `
})
export class TermsConditionsComponent {
  @Input() metadata: IComponent;
  @ViewChild('form') form: NgForm;

  showHeading: boolean;

  constructor(
    public storeService: StoreService,
    private modalService: NgbModal,
  ) {
    this.showHeading = this.storeService.getCurrentState().pgConfig.programGroupName !== 'mwoy';
  }

  openPolicyModal(e) {
    e.preventDefault();
    const modalRef = this.modalService.open(PolicyModalComponent, {size: 'lg', backdrop : 'static', keyboard: false});
    modalRef.componentInstance.description = this.metadata.attributes.DESCRIPTION;
    modalRef.componentInstance.heading = this.metadata.attributes.HEADING;
  }
}
