import { Directive } from '@angular/core';
import { AbstractControl, AsyncValidator, NG_ASYNC_VALIDATORS, ValidationErrors } from '@angular/forms';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { StoreService } from '../../services/store.service';
import { ToastrService } from 'ngx-toastr';

@Directive({
  selector: '[teamNameAvailability]',
  providers: [
    { provide: NG_ASYNC_VALIDATORS, useExisting: TeamNameAvailabilityValidator, multi: true }
  ]
})
export class TeamNameAvailabilityValidator implements AsyncValidator {
  private inputTimeout: any;

  constructor(
    private http: HttpClient,
    private storeService: StoreService,
    private toastrService: ToastrService
  ) {}

  validate(
    control: AbstractControl
  ): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> {
    clearTimeout(this.inputTimeout);

    return new Promise(resolve => {
      this.inputTimeout = setTimeout(() => {
        const eventId = this.storeService.getCurrentState().selectedEvent.eventID;
        const pgName = this.storeService.getCurrentState().pgConfig.programGroupName;
        const params = new HttpParams()
          .set('eventId', String(eventId))
          .set('pgName', pgName.toUpperCase())
          .set('teamId', '0')
          .set('teamName', control.value);
        this.http.get('/OnlineRegistration/service/api/checkTeamNameAvailability', {params})
          .subscribe((result: any) => {
          resolve(result.isAvailable ? null : { teamNameAvailability: true });
        }, () => {
            this.toastrService.error('Something went wrong.');
          });
      }, 500);
    });
  }
}
