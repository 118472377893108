import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { IComponent } from '../../../../types/step-component.interface';
import { StoreService } from '../../../../services/store.service';

declare var window: any;

@Component({
  selector: 'app-double-the-donation',
  template: `
    <div class="mt-3 mb-3">
      <h3>See if your employer will match your donation!</h3>
      <label>Enter the name of your company:</label>
      <div #dtdInput></div>
    </div>
  `
})
export class DoubleTheDonationComponent implements AfterViewInit {
  @Input() metadata: IComponent;
  @ViewChild('dtdInput') dtdInput: ElementRef;

  constructor(
    public storeService: StoreService
  ) {}

  ngAfterViewInit() {
    if (window.doublethedonation) {
      try {
        window.doublethedonation.plugin.load_streamlined_input(this.dtdInput.nativeElement, window.DDCONF);
      } catch (e) {
        console.log('Error initializing DTD: ' + e);
      }
    }
  }
}
