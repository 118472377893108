import {AfterViewInit, Component, Input, OnDestroy, OnInit} from '@angular/core';
import { IComponent } from '../../../../../types/step-component.interface';
import { StoreService } from '../../../../../services/store.service';
import { IValidationSubComponent } from '../../../../../types/subcomponent-validation.interface';
import { Location } from '@angular/common';
import { BackendService } from '../../../../../services/backend.service';
import { scrollIntoView } from '../../../../../shared/utils';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-event-search-result',
  templateUrl: './event-search-result.component.html',
  styleUrls: ['./event-search-result.component.scss']
})
export class EventSearchResultComponent implements OnDestroy, IValidationSubComponent, OnInit, AfterViewInit {
  @Input() metadata: IComponent;

  enabledColumns: string[];
  programGroupName: string;
  validated = false;
  loginError = false;
  obs: Observable<boolean>;
  private sub: Subscription;

  constructor(
    public storeService: StoreService,
    public location: Location,
    private http: HttpClient,
    private backendService: BackendService,
  ) {}

  ngOnInit() {
    this.enabledColumns = this.metadata.attributes.COLUMN_NAMES.split(' && ');
    this.enabledColumns = this.enabledColumns.map((column: string) => column.toLowerCase());
    this.programGroupName = this.storeService.getCurrentState().pgConfig.programGroupName;
    if (this.storeService.getCurrentState().selectedEvent) {
      this.onSelectRow(this.storeService.getCurrentState().selectedEvent, false, true);
    }
  }

  ngAfterViewInit() {
    this.storeService.zipCodePresent$.subscribe((o) => {
      if (o) {
        setTimeout(() => {
          scrollIntoView('event-search-result');
        }, 0);
      }
    });
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  hasColumn(name: string) {
    return this.enabledColumns.includes(name);
  }

  validate(): boolean | Observable<boolean> {
    this.validated = true;
    const currentState = this.storeService.getCurrentState();
    if (currentState.signinResult && currentState.signinResult.constituentID) {
      return this.obs;
    } else {
      return !!this.storeService.getCurrentState().selectedEvent;
    }
  }

  onSelectRow(row: any, shouldUpdateTheUrl = true, forceUpdate = false) {
    const currentState = this.storeService.getCurrentState();

    if (currentState?.selectedEvent?.eventID === row?.eventID && !forceUpdate) {
      return;
    }

    const tableData = currentState.tableData;
    tableData.forEach((data) => {
      if (data.tableStorageKey === 'team') {
        data.selectedRow = null;
        data.currentPage = 1;
      }
    });
    this.storeService.updateState({selectedEvent: row, selectedTeam: null, wayToParticipate: null, tableData});

    if (shouldUpdateTheUrl) {
      if (currentState.zipCode) {
        this.location.go(`event-zip-search/${currentState.zipCode}/event-selected/${row.eventID}`);
      } else {
        this.location.go(`event-selected/${row.eventID}`);
      }
    }

    if (currentState.signinResult && currentState.signinResult.constituentID) {
      const params = new HttpParams().set('eventID', row.eventID).set('constituentID', currentState.signinResult.constituentID);
      this.obs = this.http.get('OnlineRegistration/service/api/isUserRegistered', {params}).pipe(
        map((res: any) => {
          this.loginError = res;
          return !res;
        })
      );
      this.backendService.loadFormDefault(row.eventID);
    } else {
      this.backendService.loadFormDefault(row.eventID);
    }

    this.sub = this.storeService.eventDetailsFetched$.subscribe(o => {
      setTimeout(() => {
        if (o) {
          scrollIntoView('step-controls');
        }
      }, 5);
    });
  }
}
