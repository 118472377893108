import { Injectable } from '@angular/core';
import { StoreService } from './store.service';
import * as _ from 'lodash';
import { FinalData } from '../types/final-data.interface';
import { DeviceDetectorService } from 'ngx-device-detector';
import { WayToParticipate } from '../types/way-to-participate.type';

@Injectable()
export class BusinessService {
  constructor(private deviceService: DeviceDetectorService, private storeService: StoreService) {}

  getOrderAmount() {
    const finalRegFeeAmount = this.storeService.getCurrentState().finalRegFeeAmount;
    if (finalRegFeeAmount === 0) {
      return null;
    } else {
      return finalRegFeeAmount;
    }
  }

  getDonationAmount() {
    const amount = this.storeService.getCurrentState().amount;
    if (amount === 0) {
      return null;
    } else {
      return amount;
    }
  }

  getPostData() {
    const currentState = this.storeService.getCurrentState();

    const deviceInfo = this.deviceService.getDeviceInfo();
    let deviceName = this.deviceService.isMobile() ? deviceInfo.device : 'desktop';
    deviceName += ' ' + deviceInfo.os_version || deviceInfo.os;

    const signinResult = _.cloneDeep(currentState.signinResult);
    if (signinResult) {
      delete signinResult.dateOfBirth;
      delete signinResult.loginStatus;
      delete signinResult.teamID;
      if (!signinResult.socialMediaSiteName) {
        signinResult.socialMediaSiteName = '';
      }
      if (!signinResult.socialMediaToken) {
        signinResult.socialMediaToken = '';
      }

      //It is possible that social logins may not return a username, this replicates the behavior of the previous registration version
      if (!signinResult.username) {
        signinResult.username = currentState.contactInfo.emailAddress;
      }
    }

    let finalResult: Partial<FinalData> = _.merge(
      signinResult,
      currentState.contactInfo,
      {
        acceptsTermsAndConditions: true,
        alumniFlag: false,
        browserVersion: deviceInfo.browser + ' ' + deviceInfo.browser_version,
        deviceName,
        couponCodeID: currentState.coupon?.couponCodeID,
        eventID: currentState.selectedEvent?.eventID,
        fundraisingPackageID: currentState.fundraisingLevel?.fundraisingMinimumID,
        stateID: currentState.contactInfo.stateID,
        survivorTypeId: currentState.contactInfo.survivorTypeId,
        subscribeToNewsletter: false,
        isBillingAddressTheSame: !currentState.useAlternateBilling,
        isInterestedInAdvocacy: false,
        includePersonalDonation: currentState.showKickstartForm,
        programGroupName: currentState.pgConfig.programGroupName.toUpperCase(),
        shirtSizeDTOID: currentState.tShirt?.id ? '' + currentState.tShirt?.id : undefined,
        phoneTypeName: 'HOME',
        emailTypeName: 'HOME',
        isInterestedInVolunteering: false,
        addressTypeName: 'HOME',
        paperBalloonRequested: false,
        isVerifiedAlumni: false,
        mailWelcomeKitFlag: false,
        nationalTeamID: currentState.nationalTeam?.teamID,
        nationalTeamName: currentState.nationalTeam?.teamName,
        sportCourseID: currentState.sportCourse?.id,
        reCaptchaResponse: currentState.lastCaptchaResponse,
        teamType: 'FRIEND_FAMILY',
        urlParams: currentState.urlParams,
        wayToParticipate: currentState.wayToParticipate,
        matchingCompanyName: currentState.matchingCompanyName,
        individualGoal: currentState.fundraisingGoalPersonal,
        teamGoal: currentState.fundraisingGoalTeam,
        trainingTeamID: currentState.trainingLocation?.id
      }
    );

    if (currentState.signinResult?.constituentID) {
      finalResult.$fromServer = true;
    }

    if (currentState.dateOfBirth) {
      finalResult.dateOfBirth = `${String(currentState.dateOfBirth.year).padStart(2, '0')}-${String(currentState.dateOfBirth.month).padStart(2, '0')}-${String(currentState.dateOfBirth.day).padStart(2, '0')}T00:00:00.000Z`;
    }

    if (currentState.selectedEvent.registrationFee > 0) {
      finalResult.registrationFeeAmount = currentState.selectedEvent.registrationFee;
    } else {
      finalResult.registrationFeeAmount = null;
    }

    if (currentState.coupon?.isValid && currentState.coupon?.couponAmountReduction > 0) {
      const deductedAmount = currentState.selectedEvent.registrationFee - currentState.coupon.couponAmountReduction;
      finalResult.registrationFeeAmount = Math.max(deductedAmount, 0);
    }

    if (currentState.amount > 0) {
      finalResult.donationAmount = currentState.amount;
    } else {
      finalResult.donationAmount = null;
    }

    if (currentState.useAlternateBilling) {
      finalResult = {
        ...finalResult,
        billingAddress1: currentState.alternateBillingInfo?.address1,
        billingAddress2: currentState.alternateBillingInfo?.address2,
        billingCity: currentState.alternateBillingInfo?.city,
        billingStateID: currentState.alternateBillingInfo?.state,
        billingPostalCode: currentState.alternateBillingInfo?.postalCode,
        billingFirstName: currentState.alternateBillingInfo?.firstName,
        billingLastName: currentState.alternateBillingInfo?.lastName,
      };
    }

    switch (currentState.wayToParticipate) {
      case WayToParticipate.JOIN_TEAM:
        finalResult.teamName = currentState.selectedTeam.teamName;
        finalResult.teamID = currentState.selectedTeam.teamResultDTOID;
        break;
      case WayToParticipate.WALK_AS_INDIVIDUAL:
        finalResult.teamCreationRequested = false;
        break;
      case WayToParticipate.CREATE_TEAM:
        finalResult.nationalTeamType = currentState.nationalTeam?.teamType;
        finalResult.teamCreationRequested = true;
        finalResult.teamImagePath = currentState.createTeam.teamImagePath || null;
        finalResult.teamName = currentState.createTeam.teamName;
        if (currentState.createTeam.teamType) {
          finalResult.teamType = currentState.createTeam.teamType;
        }
        finalResult.fundraisingActivityStartMonth = currentState.createTeam.fundraisingActivityStartMonth;
        finalResult.fundraisingActivityEndMonth = currentState.createTeam.fundraisingActivityEndMonth;
        // @TODO national team sets
        // if (!angular.isUndefined(onlineRegistrationDTO.nationalTeam)
        // && !angular.isUndefined(onlineRegistrationDTO.nationalTeam.teamID)) {
        //   onlineRegistrationDTO.nationalTeamID = onlineRegistrationDTO.nationalTeam.teamID;
        //   onlineRegistrationDTO.nationalTeamName = onlineRegistrationDTO.nationalTeam.teamName;
        // }
        break;
    }

    return finalResult;
  }
}
