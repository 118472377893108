import { RouterModule, Routes } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';
import { InitResolver } from './resolvers/init.resolver';
import { WizardComponent } from './components/core/wizard/wizard.component';
import { DonationPageConfigResolver } from './resolvers/donation-page-config.resolver';
import { StepDefinitionsResolver } from './resolvers/step-definitions.resolver';

// preprocessing deep links, then redirecting to appropriate route
export function redirect() {
  if (location.host.includes('lightthenight')) {
    if (/^\/event-selected\/(\d+)\/team-selected\/(\d+)$/.test(location.pathname)) {
      return location.pathname;
    } else if (/^\/national-team-selected\/(\d+)\/national-team-type\/(.+)\/create-team$/.test(location.pathname)) {
      return location.pathname;
    } else if (!/^\/event-selected\/(\d+)$/.test(location.pathname)) {
      return 'event-search';
    }
  } else if (location.host.includes('teamintraining')) {
    if (/^\/event-selected\/(\d+)\/team-selected\/(\d+)$/.test(location.pathname)) {
      return location.pathname;
    } else if (!/^\/event-zip-search\/(\d+)$/.test(location.pathname)
      && !/^\/event-zip-search\/(\d+)\/event-selected\/(\d+)$/.test(location.pathname)) {
      return 'event-search';
    }
  } else if (location.host.includes('llsvisionaries')) {
    if (/^\/event-selected\/(\d+)\/team-selected\/(\d+)$/.test(location.pathname)) {
      return location.pathname.substr(location.pathname.indexOf('/team-selected'));
    }
    if (!/^\/team-selected\/(\d+)$/.test(location.pathname)) {
      return 'join-team';
    }
  }
  return location.pathname;
}

const appRoutes: Routes = [
  {
    path: '',
    resolve: {
      initData: InitResolver,
    },
    children: [
      {
        path: '',
        resolve: {
          donationPageConfig: DonationPageConfigResolver,
          stepDefinitions: StepDefinitionsResolver
        },
        children: [
          {path: '**', redirectTo: redirect()},
          {
            path: 'event-search',
            component: WizardComponent,
            data: {
              deepLinking: 'event-search',
            },
          },
          {
            path: 'event-selected/:eventId/team-selected/:teamId',
            component: WizardComponent,
            data: {
              deepLinking: 'event-selected-team-selected',
            }
          },
          {
            path: 'national-team-selected/:teamId/national-team-type/:teamType/create-team',
            component: WizardComponent,
            data: {
              deepLinking: 'national-team-selected',
            }
          },
          {
            path: 'event-selected/:eventId',
            component: WizardComponent,
            data: {
              deepLinking: 'ltn-event-selected',
            }
          },
          {
            path: 'event-zip-search/:zipCode',
            component: WizardComponent,
            data: {
              deepLinking: 'tnt-event-search-by-zip',
            }
          },
          {
            path: 'event-zip-search/:zipCode/event-selected/:eventId',
            component: WizardComponent,
            data: {
              deepLinking: 'tnt-selected-event',
            }
          },
          {
            path: 'join-team',
            component: WizardComponent,
            data: {
              deepLinking: 'mwoy-team-search',
            }
          },
          {
            path: 'team-selected/:teamId',
            component: WizardComponent,
            data: {
              deepLinking: 'mwoy-team-selected',
            }
          },
        ]
      },
    ]
  }
];

export const routing: ModuleWithProviders<RouterModule> = RouterModule.forRoot(appRoutes, {
    paramsInheritanceStrategy: 'always',
    relativeLinkResolution: 'legacy'
});
