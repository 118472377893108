import { Injectable } from '@angular/core';
import { StoreService, StoreState } from './store.service';
import * as _ from 'lodash';

interface CurrentStepData {
  currentStep: number;
  currentState: StoreState;
}

@Injectable()
export class WizardService {
  stepsData: CurrentStepData[] = [];

  constructor(
    private storeService: StoreService,
  ) {
  }

  nextStep() {
    const currentState = _.cloneDeep(this.storeService.getCurrentState());

    //if there is a signinResult we've already advanced past the login page, but have moved back to page 1
    if (currentState.currentStep === 0 && currentState.signinResult) {
      this.stepsData[0].currentState = currentState;
      this.updateStep(2);
    //do not add the data from the login page to the list of steps data
    } else if (currentState.currentStep === 0 || currentState.currentStep > 1) {
      this.stepsData.push({
        currentStep: currentState.currentStep,
        currentState,
      });
      this.updateStep(1);
    }

    //since we're not putting the data from the login page in the steps data, we have to massage the state
    if (currentState.currentStep === 1) {
      currentState.currentStep = 0;
      this.stepsData[0].currentState = currentState;
      this.updateStep(1);
    }

    this.logStepsData();
  }

  previousStep() {
    const currentState = this.storeService.getCurrentState();
    let storeState: any;
    //if we're on the page after the login page, we want to move back to the first page and skip the login page
    if (currentState.currentStep === 2) {
      storeState = this.stepsData[0].currentState;

      //handle the sign-in link when the primary contact e-mail is in use
      if (!currentState.signinResult) {
        //we do want to fully clear out any previous login
        storeState.signinResult = null;

        //we don't want to adjust the step stored in the stepsData or previous on page 1 won't work right
        storeState = _.cloneDeep(storeState);
        storeState.currentStep = 1; //without this, we still skip back to the first page
      }

      this.storeService.updateState(storeState);
      //this doesn't pop the step, because when the current step is 2, there is only 1 entry in the steps data, which is needed for the first page

    //find the page that we want to go to in the steps data, update the state for that step
    } else {
      storeState = this.stepsData.find((step: any) => step.currentStep === currentState.currentStep - 1).currentState;
      this.storeService.updateState(storeState);
      this.stepsData.pop();
    }

    this.logStepsData();
  }

  private updateStep(operation: number) {
    const currentStep = this.storeService.getCurrentState().currentStep;
    this.storeService.updateState({ currentStep: currentStep + operation, nextIsDisabled: false });
    window.scroll(0, 0);
  }

  private logStepsData() {
    if ((<any>window).verboseLogging) {
      let customizer = (v,k) => {
        if (k && !_.isNumber(k) && k.toLowerCase().includes('password')) {
          return '********';
        }
      }

      console.log('New Steps Data: ', _.cloneDeepWith(this.stepsData, customizer));
    }
  }
}
