import { Component, Input } from '@angular/core';
import { IComponent } from '../../../../../types/step-component.interface';
import { StoreService } from '../../../../../services/store.service';
import { WayToParticipate } from '../../../../../types/way-to-participate.type';
import { BackendService } from '../../../../../services/backend.service';

@Component({
  selector: 'app-participate-join-team',
  template: `
    <ng-container *ngIf="(storeService.vm$ | async) as vm">
      <button
          class="btn btn-secondary mr-2"
          [class.active]="vm.wayToParticipate === 'join_team'"
          (click)="showJoinTeam()">
        {{metadata.attributes.LABEL}}
      </button>
    </ng-container>
  `
})
export class ParticipateJoinTeamComponent {
  @Input() metadata: IComponent;

  constructor(
    public storeService: StoreService,
    private backendService: BackendService
  ) {}

  showJoinTeam() {
    if (!this.storeService.getCurrentState().teams) {
      this.backendService.loadTeams().subscribe(() => {});
    }
    this.storeService.updateState({wayToParticipate: WayToParticipate.JOIN_TEAM});
  }
}
