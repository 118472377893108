<form #form="ngForm">
  <div class="form-row">
    <div class="form-group col-md-6" id="contact-firstName">
      <label for="firstName">First Name</label>
      <input
        type="text"
        class="form-control"
        id="firstName"
        name="firstName"
        [(ngModel)]="profileData.firstName"
        required
        #firstName="ngModel">
      <control-errors [control]="firstName" name="First Name"></control-errors>
    </div>
    <div class="form-group col-md-6" id="contact-lastName">
      <label for="lastName">Last Name</label>
      <input
        type="text"
        class="form-control"
        id="lastName"
        name="lastName"
        [(ngModel)]="profileData.lastName"
        required
        #lastName="ngModel">
      <control-errors [control]="lastName" name="Last Name"></control-errors>
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-md-6" id="contact-address1">
      <label for="address1">Address</label>
      <input
        type="text"
        class="form-control"
        name="address1"
        id="address1"
        [(ngModel)]="profileData.address1"
        required
        #address1="ngModel">
      <control-errors [control]="address1" name="Address"></control-errors>
    </div>
    <div class="form-group col-md-6">
      <label for="address2">Apt., Suite, Unit (optional)</label>
      <input type="text" class="form-control" name="address2" id="address2" [(ngModel)]="profileData.address2">
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-md-3" id="contact-postalCode">
      <label for="postalCode">Zip Code</label>
      <input
        type="text"
        class="form-control"
        name="postalCode"
        id="postalCode"
        maxlength="5"
        [pattern]="zipValidator"
        [(ngModel)]="profileData.postalCode"
        required
        #postalCode="ngModel">
      <control-errors [control]="postalCode" name="Zip Code"></control-errors>
    </div>
    <div class="form-group col-md-6" id="contact-city">
      <label for="city">City</label>
      <input
        type="text"
        class="form-control"
        name="city"
        id="city"
        [(ngModel)]="profileData.city"
        required
        #city="ngModel">
      <control-errors [control]="city" name="City"></control-errors>
    </div>
    <div class="form-group col-md-3" id="contact-stateID">
      <label for="state">State</label>
      <select class="form-control form-select" name="stateID" id="state" [(ngModel)]="profileData.state" required #state="ngModel">
        <option class="form-control form-select" *ngFor="let state of states" [ngValue]="state.name">{{state.description}}</option>
      </select>
      <div *ngIf="state.touched && state.invalid" class="invalid-feedback">
        State is required
      </div>
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-md-12" id="contact-emailAddress">
      <label for="emailAddress">Primary Contact Email</label>
      <input
        type="email"
        class="form-control"
        id="emailAddress"
        name="emailAddress"
        [(ngModel)]="profileData.emailAddress"
        email
        required
        primaryContactEmailAvailability
        #emailAddress="ngModel">
      <control-errors [control]="emailAddress" name="Contact Email"></control-errors>
      <div *ngIf="emailAddress.touched && emailAddress.errors?.emailAvailability" class="invalid-feedback">
        An email login already exists with this email address.
        Please enter a different email, or
        <a href="javascript: void(0);" (click)="openLoginForm()">Sign In</a>
        using this email address.
        Then going forward, you may login using either your Social Network account or email address.
      </div>
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-md-6" id="contact-phoneNumber">
      <label for="phoneNumber">Phone Number</label>
      <input
        type="text"
        mask="000-000-0000"
        placeholder="111-111-1111"
        class="form-control"
        id="phoneNumber"
        name="phoneNumber"
        [pattern]="phoneValidator"
        [(ngModel)]="profileData.phoneNumber"
        required
        #phoneNumber="ngModel">
      <control-errors [control]="phoneNumber" name="Phone Number"></control-errors>
      <p class="input-annotation">You will receive texts and calls at this number about this event and upcoming LLS events, fundraisers, and offerings. You will have the ability to opt out once communication begins.</p>
    </div>
    <div class="form-group col-md-6">
      <label for="companyName">Employer (optional)</label>
      <input type="text" class="form-control" id="companyName" name="companyName" [(ngModel)]="profileData.companyName">
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-md-6" id="contact-survivorTypeId">
      <label for="state">Participation Type</label>
      <select class="form-control form-select" name="survivorTypeId" id="survivorTypeId" [(ngModel)]="profileData.survivorTypeId">
        <option class="form-control" *ngFor="let survivorType of survivorTypes" [ngValue]="survivorType.id">{{survivorType.description}}</option>
      </select>
    </div>
  </div>
</form>
