import { Directive } from '@angular/core';
import { UntypedFormControl, NG_VALIDATORS, Validator } from '@angular/forms';

@Directive({
  selector: '[atLeastOneSpecialChar][ngModel]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: AtLeastOneSpecialCharValidator, multi: true }
  ]
})
export class AtLeastOneSpecialCharValidator implements Validator {
  validate(control: UntypedFormControl) {
    if (!/[!-/:-@\[-`\{-~}€£¥¢]/.test(control.value)) {
      return { atLeastOneSpecialChar: true };
    }
    return null;
  }
}
