import { Injectable } from '@angular/core';

@Injectable()
export class ProgressService {
  private inProgressCount = 0;

  isBlocked = false;

  block() {
    this.inProgressCount++;
    this.isBlocked = this.inProgressCount > 0;
  }

  unblock() {
    this.inProgressCount--;
    this.isBlocked = this.inProgressCount > 0;
  }
}
