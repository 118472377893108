import { Directive } from '@angular/core';
import { UntypedFormControl, NG_VALIDATORS, Validator } from '@angular/forms';

@Directive({
  selector: '[year]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: YearValidator, multi: true }
  ]
})
export class YearValidator implements Validator {
  validate(control: UntypedFormControl) {
    if ( !/^[0-9]*$/.test(control.value) || control.value < 1900) {
      return { year: true };
    }
    return null;
  }
}
