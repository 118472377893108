import {Injectable} from '@angular/core';
import {FacebookLogin} from '@capacitor-community/facebook-login';
import * as _ from "lodash";

@Injectable()
export class FacebookService {

    async login(params: any = {}) {
        let result: any;
        let errResult: any;

        let options = this.getLoginOptions(params);

        result = await FacebookLogin.login(options).catch((err) => {
            errResult = err;
        });

        if (errResult) {
            result = errResult;
        }

        console.log(result);
        return !!(result?.accessToken?.token);
    }

    async getProfile() {
        const result: any = await FacebookLogin.getProfile({ fields: ['email'] });
        console.log(result);
        return result;
    }

    async getAccessToken() {
        let result: any;
        let errResult: any;

        result = await FacebookLogin.getCurrentAccessToken().catch((err) => {
            errResult = err;
        });

        if (errResult) {
            result = errResult;
        }

        console.log(result);
        return result
    }

    private getLoginOptions(params: any = {}) {
        if (!_.has(params, 'permissions')) {
          (params as any).permissions = [];
        }

        //need to explicitly request e-mail
        params.permissions = _.union(params.permissions, ['email']);

        return { permissions: params.permissions };
    }
}
