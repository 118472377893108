import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { IComponent } from '../../../../types/step-component.interface';
import { StoreService } from '../../../../services/store.service';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-amount',
  template: `
    <form #form="ngForm">
      <label for="amount">
        Amount
      </label>
      <input
        id="amount"
        type="text"
        class="form-control"
        name="amount"
        required
        amount
        ngModel
        (ngModelChange)="amountChanged($event)"
        #amount="ngModel"
      >
      <div *ngIf="amount.touched && amount.errors?.amount" class="invalid-feedback">
        Amount should be valid number.
      </div>
    </form>
  `
})
export class AmountComponent {
  @ViewChild('form') form: NgForm;
  @Input() metadata: IComponent;

  constructor(
    public element: ElementRef,
    public storeService: StoreService,
  ) {}

  amountChanged(amount: string) {
    if (this.form.controls.amount.valid) {
      this.storeService.updateState({amount: +(+amount.match(/[+-]?\d+(\.\d+)?/g)[0]).toFixed(2)});
    } else {
      this.storeService.updateState({amount: null});
    }
  }
}
