import { Directive } from '@angular/core';
import { UntypedFormControl, NG_VALIDATORS, Validator } from '@angular/forms';

@Directive({
  selector: '[day]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: DayValidator, multi: true }
  ]
})
export class DayValidator implements Validator {
  validate(control: UntypedFormControl) {
    if ( !/^[0-9]*$/.test(control.value) || control.value < 1 || control.value > 31) {
      return { day: true };
    }
    return null;
  }
}
