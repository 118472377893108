import { ContentChild, Directive, Input } from '@angular/core';
import { AppCellDefDirective } from './cell-def.directive';

@Directive({
  selector: '[appColumnDef]'
})
export class AppColumnDefDirective {
  @ContentChild(AppCellDefDirective) cell: AppCellDefDirective;
  @Input('className') class: string;
  @Input('appColumnDef') name: string;
  @Input('sortField') sortField: string | string[];
}
