import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { IComponent } from '../../../../../../types/step-component.interface';
import { StoreService } from '../../../../../../services/store.service';
import { IValidationSubComponent } from '../../../../../../types/subcomponent-validation.interface';
import { scrollIntoView } from '../../../../../../shared/utils';

@Component({
  selector: 'app-upload-image',
  template: `
    <ng-container *ngIf="(storeService.vm$ | async) as vm">
      <div class="mb-4 mt-4" id="upload-image">
        <label for="upload-file">{{metadata.attributes.LABEL}}</label>
        <input type="file" class="form-control d-none" id="upload-file"
               accept=".png, .jpg, .jpeg" (change)="uploadFile($event)" #input>
        <label for="upload-file" class="d-block box">
          <img src="assets/images/image-upload.svg">
        </label>
        <div>
          <img #img class="avatar mr-3" [class.is-image-selected]="isImageSelected">
          <button *ngIf="isImageSelected" class="button" (click)="removeUploadFile()">Remove Uploaded Image</button>
        </div>
      </div>
      <div *ngIf="!wrongFormat && validated && !isValid()" class="alert alert-danger" role="alert">
        Team Logo is required.
      </div>
      <div *ngIf="wrongFormat" class="alert alert-danger" role="alert">
        You can select only image files.
      </div>
    </ng-container>
  `,
  styles: [`
    .box {
      padding: 0.5em;
      background-color: #eee;
      border: 1px solid #dadada;
      border-radius: 6px;
      text-align: center;
    }

    .avatar {
      width: 50px;
      height: 50px;
      border-radius: 25px;
      display: none;
    }

    .is-image-selected {
      display: inline-block;
    }

    .button {
      color: #656565;
      background-color: #eee;
      border: 1px solid #dadada;
      border-radius: 6px;
      font-size: 1.1rem;
      font-weight: 300;
      outline: none;
      padding: 0.675rem 1.2rem;
    }
  `]
})
export class UploadTeamImageComponent implements IValidationSubComponent, AfterViewInit {
  @Input() metadata: IComponent;
  @ViewChild('img') image: ElementRef;
  @ViewChild('input') input: ElementRef;

  isImageSelected = !!this.storeService.getCurrentState().teamImageSrc;
  validated = false;
  wrongFormat = false;

  constructor(public storeService: StoreService) {
  }

  ngAfterViewInit() {
    if (this.isImageSelected) {
      this.image.nativeElement.src = this.storeService.getCurrentState().teamImageSrc;
    }
  }

  uploadFile(event) {
    if (event.target.files[0]) {
      if (event.target.files[0].type !== 'image/png' && event.target.files[0].type !== 'image/jpeg') {
        if (this.storeService.getCurrentState().createTeam) {
          this.removeUploadFile();
        }
        this.wrongFormat = true;
        return;
      } else {
        this.wrongFormat = false;
        this.handleUpload(event);
        this.isImageSelected = true;
        this.image.nativeElement.src = URL.createObjectURL(event.target.files[0]);
        this.storeService.updateState({ teamImageSrc: URL.createObjectURL(event.target.files[0]) });
      }
    } else {
      return;
    }
  }

  removeUploadFile() {
    this.input.nativeElement.value = '';
    this.isImageSelected = false;
    const createTeam = this.storeService.getCurrentState().createTeam;
    createTeam.base64Image = '';
    this.storeService.updateState({createTeam: Object.assign({}, createTeam), teamImageSrc: '' });
  }

  isValid() {
    if (!!this.storeService.getCurrentState().teamImageSrc) {
      return true;
    } else {
      if (this.storeService.getCurrentState().createTeam?.teamName) {
        scrollIntoView('upload-image');
      }
      return false;
    }
  }

  validate(): boolean {
    if (this.storeService.getCurrentState().staticConfig.imageUploadRequired) {
      this.validated = true;
      return this.isValid();
    } else {
      return true;
    }
  }

  private handleUpload(event) {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const createTeam = this.storeService.getCurrentState().createTeam || {};
      createTeam.base64Image = (reader.result as string).split(',')[1];
      this.storeService.updateState({createTeam: Object.assign({}, createTeam)});
    };
  }
}
