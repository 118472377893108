import { Component, Input } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IComponent } from '../../../../../../types/step-component.interface';
import { StoreService } from '../../../../../../services/store.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-team-search-by-keyword',
  template: `
    <ng-container *ngIf="(storeService.vm$ | async) as vm">
      <label for="team-search-keyword">{{metadata.attributes.LABEL}}</label>
      <div *ngIf="vm.pgConfig.programGroupName === 'ltn'">
        <input id="isSchoolCb" type="checkbox" [checked]="isSchool" (change)="toggleSchool($event)">
        <label for="isSchoolCb" class="d-inline"> Show Schools Only</label>
      </div>
      <input type="text" [value]="teamSearchKeyword" class="form-control" id="team-search-keyword" placeholder="{{metadata.attributes.INPUT_PLACEHOLDER}}" (keyup)="onChange($event)">
    </ng-container>
  `
})
export class JoinTeamSearchComponent {
  @Input() metadata: IComponent;
  teamSearchKeyword = this.storeService.getCurrentState().teamSearchKeyword;
  isSchool = this.storeService.getCurrentState().isSchoolFilter;
  subscription: Subscription;

  constructor(
    private http: HttpClient,
    public storeService: StoreService
  ) {}

  onChange(event) {
    this.teamSearchKeyword = event.target.value;
    this.filterTeams();
  }

  toggleSchool(event) {
    this.isSchool = event.target.checked;
    this.storeService.updateState({ isSchoolFilter: this.isSchool });
    let tableData = this.storeService.getCurrentState()?.tableData;
    const teamTableData = tableData.find(o => o.tableStorageKey === 'team');
    if (teamTableData) { // Reset the paging
      teamTableData.currentPage = 1;
      tableData.push(teamTableData);
      this.storeService.updateState({ tableData });
    }
    this.filterTeams();
  }

  filterTeams() {
    const location = this.storeService.getCurrentState().pgConfig.programGroupName === 'mwoy' ? 'join-team' : undefined;
    this.subscription = this.storeService.unselectTableRow(
      'team',
      this.storeService.filteredTeams$,
      {
        teamSearchKeyword: this.teamSearchKeyword,
        isSchool: this.isSchool,
      },
      'selectedTeam',
      location
    );
    this.subscription.unsubscribe();
  }
}
