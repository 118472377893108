import { Component, Input, OnInit } from '@angular/core';
import { IComponent } from '../../../../../../types/step-component.interface';
import { StoreService } from '../../../../../../services/store.service';

@Component({
  selector: 'app-create-team-type',
  template: `
    <div class="mb-4 mt-4">
      <label for="select">{{ metadata.attributes.LABEL }}</label>
      <select id="select"
          class="form-control form-select"
          required
          [(ngModel)]="teamType"
          (change)="selectOption($event)"
      >
        <option *ngFor="let option of options" [value]="option.val">{{option.label}}</option>
      </select>
    </div>
  `
})
export class CreateTeamTypeComponent implements OnInit {
  @Input() metadata: IComponent;

  options = [
    { val: 'FRIEND_FAMILY', label: 'Friend/Family' },
    { val: 'CORPORATE', label: 'Corporate' },
    { val: 'SCHOOL', label: 'School' },
    { val: 'CLASSROOM', label: 'Classroom' }
  ];
  teamType: any;

  constructor(
    private storeService: StoreService
  ) {}

  ngOnInit() {
    let type = this.storeService.getCurrentState().createTeam?.teamType;
    let subType = this.storeService.getCurrentState().createTeam?.teamSubType;
    if (subType == 'CLASSROOM') {
       type = subType;
    }

    if (type) {
       this.teamType = type;
    } else {
      let nType = this.storeService.getCurrentState().nationalTeam?.teamType;
      if (nType) {
        this.teamType = this.translateType(nType);
      } else {
        this.teamType = this.options[0].val
      }
      this.updateTeamType(this.teamType);
    }
  }

  selectOption(event: any) {
    this.updateTeamType(event.target.value);
  }

  updateTeamType(value: string) {
    const createTeam = this.storeService.getCurrentState().createTeam || {};
    if (value == 'CLASSROOM') {
      createTeam.teamType = 'SCHOOL';
      createTeam.teamSubType = value;
    } else {
      createTeam.teamType = value;
      createTeam.teamSubType = '';
    }
    this.storeService.updateState({createTeam: Object.assign({}, createTeam)});
  }

  /* The national team type can be deep-linked.
     When this happens, select the appropriate default when creating a team. */
  translateType(type: string): string {
    if (type == 'NATIONAL_F_F') {
      return 'FRIEND_FAMILY';
    } else if (type == 'NATIONAL_CORP') {
      return 'CORPORATE';
    } else if (type == 'SCHOOL_DISTRICT') {
      return 'SCHOOL';
    } else if (type == 'SCHOOL') {
        return 'CLASSROOM';
    } else {
      return type;
    }
  }
}
