import { Directive } from '@angular/core';
import { AbstractControl, AsyncValidator, NG_ASYNC_VALIDATORS, ValidationErrors } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

@Directive({
  selector: '[emailAvailability]',
  providers: [
    { provide: NG_ASYNC_VALIDATORS, useExisting: EmailAvailabilityValidator, multi: true }
  ]
})
export class EmailAvailabilityValidator implements AsyncValidator {
  private inputTimeout: any;

  constructor(private http: HttpClient, private toastrService: ToastrService) {}

  validate(
    control: AbstractControl
  ): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> {
    clearTimeout(this.inputTimeout);

    return new Promise(resolve => {
      this.inputTimeout = setTimeout(() => {
        this.http.get('/OnlineRegistration/service/api/isUsernameAvailable', {
          params: {
            username: encodeURIComponent(control.value)
          }
        }).subscribe((result: any) => {
          resolve(result.isAvailable ? null : { emailAvailability: true });
        }, () => {
          this.toastrService.error('Something went wrong.');
        });
      }, 500);
    });
  }
}
