import { Component, Input, OnInit } from '@angular/core';
import { IComponent } from '../../../../types/step-component.interface';
import { StoreService } from '../../../../services/store.service';
import { IValidationSubComponent } from '../../../../types/subcomponent-validation.interface';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-sport-course',
  template: `
    <ng-container *ngIf="(storeService.vm$ | async) as vm">
      <h3>{{metadata.attributes.HEADING}}</h3>
      <p>{{metadata.attributes.DESCRIPTION}}</p>

      <app-table
        *ngIf="(rows$ | async) as sportCourses"
        [rows]="sportCourses"
        tableStorageKey="sport-course"
        (selectRow)="onSelectRow($event)"
      >
        <ng-container appColumnDef="Sport">
          <span *appCellDef="let row">{{ row.sport }}</span>
        </ng-container>
        <ng-container appColumnDef="Course">
          <span *appCellDef="let row">{{ row.course }}</span>
        </ng-container>
      </app-table>
      <div *ngIf="!rows$" class="spinner-border">
        <span class="sr-only">Loading...</span>
      </div>
      <div *ngIf="validated && !vm.sportCourse" class="alert alert-danger" role="alert">
        Choosing Sport Course is required.
      </div>
    </ng-container>
  `
})
export class SportCourseComponent implements IValidationSubComponent, OnInit {
  @Input() metadata: IComponent;

  rows$: Observable<any>;
  validated = false;

  constructor(public storeService: StoreService){
  }

  ngOnInit() {
    this.rows$ = this.storeService.sportCourses$;
  }

  validate(): boolean {
    this.validated = true;
    return !!this.storeService.getCurrentState().sportCourse;
  }

  onSelectRow(row) {
    const tableData = this.storeService.getCurrentState().tableData;
    tableData.forEach((data) => {
      if (['fundraising-level', 'training-location'].includes(data.tableStorageKey)) {
        data.selectedRow = null;
      }
    });
    this.storeService.updateState({
      sportCourse: row,
      fundraisingLevel: null,
      trainingLocation: null,
      tableData
    });
  }
}
