import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'cvv-modal',
  template: `
    <div class="modal-content">
      <div class="modal-header">
        <h2>What’s a CVV?</h2>
        <button type="button" class="close" (click)="activeModal.dismiss()">
          <span>&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p>
          For MasterCard, Visa, or Discover, it's the last three digits in the signature
          area on the back of your card. For American Express, it's the four digits on
          the front of the card.
        </p>
        <img alt="Credit Card CVV Explanation" src="/assets/images/cvv.svg" class="credit-card">
      </div>
    </div>
  `,
  styleUrls: ['./cvv-modal.component.scss']
})
export class CvvModalComponent {
  constructor(public activeModal: NgbActiveModal) {}
}
