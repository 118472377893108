import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { StoreService } from '../../../../../services/store.service';
import { NgForm } from '@angular/forms';
import { IComponent } from '../../../../../types/step-component.interface';
import {WizardService} from "../../../../../services/wizard.service";

@Component({
  selector: 'app-contact-info',
  templateUrl: 'contact-info.component.html'
})
export class ContactInfoComponent implements OnInit {
  @Input() metadata: IComponent;
  @ViewChild('form') ngForm: NgForm;

  profileData: any;
  zipValidator = /^\d{5}(?:[-\s]\d{4})?$/;
  phoneValidator = /^\d{10}$/;
  states = this.storeService.getCurrentState().states;
  survivorTypes = this.storeService.getCurrentState().survivorTypes;

  constructor(public storeService: StoreService, private wizardService: WizardService) {
  }

  ngOnInit() {
    const signinResult = this.storeService.getCurrentState().signinResult;
    const contactInfo = this.storeService.getCurrentState().contactInfo;
    this.profileData = {
      firstName: contactInfo?.firstName || signinResult.firstName,
      lastName: contactInfo?.lastName || signinResult.lastName,
      emailAddress: contactInfo?.emailAddress || signinResult.emailAddress || signinResult.username,
      address1: contactInfo?.address1 || signinResult.address1,
      address2: contactInfo?.address2 || signinResult.address2,
      postalCode: contactInfo?.postalCode || signinResult.postalCode,
      city: contactInfo?.city || signinResult.city,
      state: contactInfo?.stateID || signinResult.stateID || null,
      phoneNumber: contactInfo?.phoneNumber || signinResult.phoneNumber,
      companyName: contactInfo?.companyName || signinResult.companyName,
      survivorTypeId: contactInfo?.survivorTypeId || signinResult.survivorTypeId || null
    };
  }

  openLoginForm() {
    this.storeService.updateState({ signinState: 'sign-in', signinResult: null });
    this.wizardService.previousStep();
  }
}
