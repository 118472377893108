import { Component, Input, ViewChild } from '@angular/core';
import { StoreService } from '../../../../services/store.service';
import { IComponent } from '../../../../types/step-component.interface';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-signature',
  template: `
    <div id="signature" *ngIf="(storeService.vm$ | async) as vm">
      <form #form="ngForm">
        <label *ngIf="!vm.under18" for="signature">
          Participant's Signature (please enter exactly as shown, including capitalization):
          {{vm.contactInfo?.firstName}} {{vm.contactInfo?.lastName}}
        </label>
        <label *ngIf="vm.under18" for="signature">
          Parent / Legal Guardian: Please enter your full name here
        </label>
        <input
          type="text"
          class="form-control"
          placeholder="Type your name here"
          name="signature"
          ngModel
          (ngModelChange)="storeService.updateState({ signature: $event })"
          required
          signature
          #signature="ngModel">
        <div *ngIf="signature.touched && signature.errors?.signature" class="invalid-feedback">
          Signature do not match
        </div>
      </form>
    </div>
  `
})
export class SignatureComponent {
  @ViewChild('form') form: NgForm;
  @Input() metadata: IComponent;

  constructor(
    public storeService: StoreService
  ) {}
}
