import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { StoreService } from '../../../../services/store.service';
import { IComponent } from '../../../../types/step-component.interface';
import { IValidationSubComponent } from '../../../../types/subcomponent-validation.interface';
import { WayToParticipate } from '../../../../types/way-to-participate.type';

@Component({
  selector: 'app-fundraising-goal',
  template: `
    <ng-container *ngIf="(storeService.vm$ | async) as vm">
      <h3 id="fundraising">{{metadata.attributes.HEADING}}</h3>
      <p>{{metadata.attributes.DESCRIPTION}}</p>

      <form id="fundraising-goal-form" #form="ngForm">
        <label for="fundraising-goal-personal">Personal Fundraising Goal</label>
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text">$</span>
          </div>
          <input type="text"
                 class="form-control"
                 name="fundraising-goal-personal"
                 pattern="\\d*"
                 maxlength="10"
                 equalOrGreaterThanFundraisingCommitment
                 [ngModel]="vm.fundraisingGoalPersonal"
                 (change)="updateFundraisingGoalPersonal($event)"
                 #fundraisingGoalPersonal="ngModel"
          >
        </div>
        <div *ngIf="fundraisingGoalPersonal.touched && fundraisingGoalPersonal.errors?.pattern"
             class="alert alert-danger mt-2"
             role="alert"
        >
          Only numeric values are allowed
        </div>
        <div *ngIf="fundraisingGoalPersonal.touched && fundraisingGoalPersonal.errors?.equalOrGreaterThanFundraisingCommitment"
             class="alert alert-danger mt-2"
             role="alert"
        >
          Personal Goal can not be lower than the required fundraising minimum
        </div>

        <ng-container *ngIf="shouldShowTeamGoal">
          <label for="fundraising-goal-team">Team Fundraising Goal</label>
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">$</span>
            </div>
            <input type="text"
                   class="form-control"
                   name="fundraising-goal-team"
                   pattern="\\d*"
                   maxlength="10"
                   equalOrGreaterThanFundraisingCommitment
                   [ngModel]="vm.fundraisingGoalTeam"
                   (change)="updateFundraisingGoalTeam($event)"
                   #fundraisingGoalTeam="ngModel"
            >
          </div>
          <div *ngIf="fundraisingGoalTeam.touched && fundraisingGoalTeam.errors?.equalOrGreaterThanFundraisingCommitment"
               class="alert alert-danger mt-2"
               role="alert"
          >
            Team Goal can not be lower than the required fundraising minimum or personal goal
          </div>
        </ng-container>
      </form>
    </ng-container>
  `,
  styles: [`
    .input-group-text {
      height: 100%;
    }
  `]
})
export class FundraisingGoalComponent implements OnInit, IValidationSubComponent {
  @Input() metadata: IComponent;
  @ViewChild('form') form: NgForm;

  get shouldShowTeamGoal() {
    const state = this.storeService.getCurrentState();
    return state.pgConfig.programGroupName === 'tnt' && state.wayToParticipate === WayToParticipate.CREATE_TEAM;
  }

  constructor(public storeService: StoreService) {}

  ngOnInit() {
    const state = this.storeService.getCurrentState();
    if (!state.fundraisingGoalPersonal && state.pgConfig.programGroupName === 'ltn') {
      this.storeService.updateState({ fundraisingGoalPersonal: 500 });
    }
  }

  updateFundraisingGoalPersonal(event: any) {
    this.storeService.updateState({ fundraisingGoalPersonal: event.target.value ? +event.target.value : null });
  }

  updateFundraisingGoalTeam(event: any) {
    this.storeService.updateState({ fundraisingGoalTeam: event.target.value ? +event.target.value : null });
  }

  validate(): boolean {
    this.form.form.get('fundraising-goal-personal').markAsTouched();
    return this.form.valid;
  }
}
