import { AfterViewChecked, Component, Input, NgZone } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { StoreService } from '../../../../services/store.service';
import { switchMap } from 'rxjs/operators';
import { WizardService } from '../../../../services/wizard.service';
import { SigninResult } from '../../../../types/auth-types';
import { ProgressService } from '../../../../services/progress.service';
import { ToastrService } from 'ngx-toastr';
import { uppercaseFirstLetter, isIE } from '../../../../shared/utils';
import { Angulartics2 } from 'angulartics2';
import { ViewChild } from '@angular/core';
import { Renderer2 } from '@angular/core';
import { FacebookService } from "../../../../services/facebook.service";
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';

declare var google: any;

@Component({
  selector: 'app-sign-in-social',
  template: `
    <div class="mb-2">
      <div class="mb-2">
        <a class="d-block btn btn-facebook" style="width: 400px; font-size: var(--bs-btn-font-size);" (click)="socialLogin('facebook')">
          <i class="fab fa-facebook-f" [ngClass]="signinState === 'register' ? 'mr-4' : 'mr-5'"></i>
          {{signinState === 'register' ? 'Create an Account with Facebook' : 'Sign In with Facebook'}}
        </a>
      </div>
      <div class="d-block d-flex" *ngIf="signinState === 'register';else googleSignInContainer" id="googleCreateAccountContainer">
      </div>
      <ng-template #googleSignInContainer>
        <div class="d-block d-flex" id="googleSignInContainer">
        </div>
      </ng-template>
      <div *ngIf="loginError" class="mt-3 alert alert-danger" role="alert" [innerHTML]="loginError"></div>
    </div>

    <!-- IE Message for unsupported Social Login -->
    <div class="ie-modal modal" *ngIf="socialLoginMsgIsVisible" tabindex="-1" role="dialog" style="z-index: 20000; background: rgba(0,0,0,0.5);" #iemodal>
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Unsupported Browser</h5>
            <button type="button" class="close" (click)="closeModal()" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <p>Social Media login is no longer supported on Internet Explorer. Please install another web browser.</p>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" (click)="closeModal()" data-dismiss="modal">Close</button>
          </div>
        </div>
      </div>
    </div>
  `
})
export class SocialLoginComponent implements AfterViewChecked {
  @Input() signinState: string;
  loginError: string;
  public socialLoginMsgIsVisible: boolean = true;
  @ViewChild('iemodal') ie_modal;
  googleLoaded = false;

  constructor(
    public storeService: StoreService,
    private angulartics2: Angulartics2,
    private zone: NgZone,
    private http: HttpClient,
    private progressService: ProgressService,
    private wizardService: WizardService,
    private toastrService: ToastrService,
    private facebookService: FacebookService,
    private renderer: Renderer2
  ) {}

  ngAfterViewChecked() {

    if (google && !this.googleLoaded) {
      this.googleLoaded = true;

      if (this.signinState === 'register') {
        google.accounts.id.renderButton(
          document.getElementById('googleCreateAccountContainer'),
          {
            type: 'standard',
            theme: 'outline',
            size: 'large',
            text: 'continue_with',
            width: '400',
            use_fedcm_for_prompt: true,
            //dirty hack, there's only one instance of the google object, but we need it to go to multiple components
            click_listener: function() { GoogleAuth.setSignInCallback(this.googleLoginInternal.bind(this)); }.bind(this)
          }
        );

      } else {
        google.accounts.id.renderButton(
          document.getElementById('googleSignInContainer'),
          {
            type: 'standard',
            theme: 'outline',
            size: 'large',
            text: 'signin_with',
            width: '400',
            use_fedcm_for_prompt: true,
            //dirty hack, there's only one instance of the google object, but we need it to go to multiple components
            click_listener: function() { GoogleAuth.setSignInCallback(this.googleLoginInternal.bind(this)); }.bind(this)
          }
        );
      }
    }
  }

  closeModal():void{

    this.renderer.setStyle(this.ie_modal.nativeElement, 'display', 'none');

  }

  socialLogin(provider: string) {
    if (isIE()) {
      this.socialLoginMsgIsVisible = true;
      this.renderer.setStyle(this.ie_modal.nativeElement, 'display', 'block');
      return;
    }

    if (provider == 'facebook') {
      this.facebookLogin();
    }
  }

  async facebookLogin() {
    let profile: any = {};
    if (await this.facebookService.login()) {
      const fbUser = await this.facebookService.getProfile();
      const result = await this.facebookService.getAccessToken();
      if (result) {
          fbUser.accessToken = result.accessToken;
      }
      profile.provider = 'facebook';
      profile.user = fbUser;
      this.handleSocialResponse(profile);
    } else {
        this.toastrService.error("Error Logging Into Facebook");
    }
  }

  googleLoginInternal(googleUser) {
    let profile: any = {};
    if (googleUser) {
        profile.provider = 'google';
        profile.user = googleUser;
        this.handleSocialResponse(profile);
    } else {
        this.toastrService.error("Error Logging Into Google");
    }
  }

  private handleSocialResponse(res) {
    this.postSocialLogin(res).subscribe((result: SigninResult) => {
      if (result.loginStatus === 'STATUS_LOGIN_FAILED') {
        this.loginError = 'Invalid Username or Password';
        this.zone.run(() => {});
        return;
      } else {
        if (!result.userRegistered) {
          // @TODO: some prefill code from legacy
        } else {
          this.loginError = 'User already registered.';
          this.zone.run(() => {});
          return;
        }
      }
      result.username = result.username || result.emailAddress;
      this.storeService.updateState({ signinResult: result });
      const action = this.storeService.getCurrentState().signinState === 'register' ? 'Sign Up' : 'Sign In';
      this.angulartics2.eventTrack.next({
        action,
        properties: {
          gtmCustom: {
            category: 'Social Media',
            label: uppercaseFirstLetter(res.provider),
            value: res.provider
          }
        },
      });
      this.wizardService.nextStep();
      this.zone.run(() => {});
    }, (err) => {
      console.log(err);
      this.toastrService.error('Something went wrong.');
    });
  }

  postSocialLogin(socialResponse) {
    return this.http.post('/OnlineRegistration/service/api/signin', {
      programGroupName: this.storeService.getCurrentState().pgConfig.programGroupName,
      eventID: this.storeService.getCurrentState().selectedEvent?.eventID,
      user: socialResponse.user,
      provider: socialResponse.provider,
      smSiteName: this.getSiteDomain()
    });
  }

  private getSiteDomain() {
    const parts = window.location.hostname.split('.');
    return parts.slice(-2).join('.');
  }
}
