import { HttpParams } from '@angular/common/http';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import * as moment from 'moment';

export function getParamValueQueryString(paramName) {
  const url = window.location.href;
  let paramValue;
  if (url.includes('?')) {
    const httpParams = new HttpParams({ fromString: url.split('?')[1] });
    paramValue = httpParams.get(paramName);
  }
  return paramValue;
}

export function validateForm(formGroup: UntypedFormGroup) {
  getAllFormControls(formGroup).forEach(control => {
    if (!control.asyncValidator) {
      control.updateValueAndValidity({ emitEvent: false });
    }
  });
}

export function getAllFormControls(formGroup: UntypedFormGroup | UntypedFormArray, heap: UntypedFormControl[] = []): UntypedFormControl[] {
  Object.keys(formGroup.controls).forEach(field => {
    const control = formGroup.get(field);
    if (control instanceof UntypedFormControl) {
      heap.push(control);
    } else if (control instanceof UntypedFormGroup) {
      this.getFormControls(control, heap);
    } else if (control instanceof UntypedFormArray) {
      control.controls.forEach((c: UntypedFormGroup) => {
        this.getFormControls(c, heap);
      });
    }
  });
  return heap;
}

export function markAllFieldsAsTouched(form: UntypedFormGroup) {
  getAllFormControls(form).forEach((control: UntypedFormControl) => {
    control.markAsTouched();
  });
}

export function getControlName(control: UntypedFormControl) {
  let controlName = '';
  const parent = control.parent;

  Object.keys(parent.controls).forEach((name) =>
  {
    if (control === parent.controls[name]) {
      controlName = name;
    }
  });
  return controlName;
}

export function under18(year: string, month: string, day: string, service) {
  const age = moment().diff(moment(`${year}${month}${day}`, 'YYYYMMDD'), 'years');
  if (age < 18) {
    service.updateState({under18: true});
    return;
  }
  service.updateState({under18: false});
}

export function scrollIntoView(id) {
  const el = document.getElementById(id);
  el?.scrollIntoView({ behavior: 'smooth', block: 'start' });
}

export function scrollToErrorField(form: UntypedFormGroup, ids: string[], identifier: string) {
  let isScrollTo = true;
  ids.forEach((id: string) => {
    if (form.get(id).invalid && isScrollTo) {
      scrollIntoView(identifier + id);
      isScrollTo = false;
    }
  });
}

export function loadJsScript(url: string, callback?: any) {
  const node = document.createElement('script');
  node.src = url;
  node.type = 'text/javascript';
  node.async = true;
  if (callback) {
    node.addEventListener('load', callback);
  }
  document.getElementsByTagName('head')[0].appendChild(node);
}

export function uppercaseFirstLetter(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function isIE() {
  const ua = window.navigator.userAgent; //Check the userAgent property of the window.navigator object
  const msie = ua.indexOf('MSIE '); // IE 10 or older
  const trident = ua.indexOf('Trident/'); //IE 11

  return (msie > 0 || trident > 0);
}