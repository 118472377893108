import { Component, Input } from '@angular/core';
import { IComponent } from '../../../../types/step-component.interface';
import { StoreService } from '../../../../services/store.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-event-search-by-keyword',
  template: `
    <div *ngIf="(storeService.vm$ | async) as vm">
      <h3 *ngIf="metadata.attributes.HEADING">{{metadata.attributes.HEADING}}</h3>
      <div *ngIf="metadata.attributes.DESCRIPTION">{{metadata.attributes.DESCRIPTION}}</div>
      <label *ngIf="metadata.attributes.LABEL" for="event-search-keyword">{{metadata.attributes.LABEL}}</label>
      <input type="text"
             class="form-control"
             [value]="vm.eventSearchKeyword"
             id="event-search-keyword"
             placeholder="Start typing to filter"
             (keyup)="onChange($event)">
    </div>
  `
})
export class EventSearchByKeywordComponent {
  @Input() metadata: IComponent;
  subscription: Subscription;

  constructor(
    public storeService: StoreService
  ) {}

  onChange(event) {
    const location = this.storeService.getCurrentState().zipCode ?
      `event-zip-search/${this.storeService.getCurrentState().zipCode}` : 'event-search';
    this.subscription = this.storeService.unselectTableRow(
      'eventSearch',
      this.storeService.filteredEvents$,
      {
        eventSearchKeyword: event.target.value,
      },
      'selectedEvent',
      location
    );
    this.subscription.unsubscribe();
  }
}
