import {Component, Input, OnInit} from '@angular/core';
import { IComponent } from '../../../../types/step-component.interface';
import { StoreService } from '../../../../services/store.service';

@Component({
  selector: 'app-youtube-video',
  template: `
    <div>
      <div>
        <h3>{{metadata.attributes.HEADING}}</h3>
      </div>
      <div>
        <iframe
          [src]="source | safe"
          [style.width.px]="width"
          [style.height.px]="height"
        ></iframe>
      </div>
    </div>
  `,
  styles: [`
    h3 {
      text-align: center;
    }

    iframe {
        display: block;
        margin: 0 auto;
        width: 100% !important;
    }
  `]
})
export class YoutubeVideoComponent implements OnInit {
  @Input() metadata: IComponent;
  width: string;
  height: string;
  source: string;

  constructor(public storeService: StoreService) {}

  ngOnInit() {
    const data = this.metadata.attributes.DATA.split('|');
    this.width = data[0];
    this.height = data[1];
    this.source = `https://www.youtube.com/embed/${data[2]}?rel=0&amp;controls=0${data[3]}`;
  }
}
