import { Component, Input } from '@angular/core';
import { IComponent } from '../../../../../../types/step-component.interface';

@Component({
  selector: 'app-no-candidate-found',
  template: `
    <div class="detail">
      <h3>{{metadata.attributes.HEADING}}</h3>
      <div [innerHTML]="metadata.attributes.DESCRIPTION"></div>
    </div>
  `,
  styles: [`
    .detail {
      padding: 0.75em;
      border: 1px solid #D1D1D1;
    }
  `]
})
export class NoCandidateFoundComponent {
  @Input() metadata: IComponent;
}
