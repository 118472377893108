import { AfterViewInit, Component, Input, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { IComponent } from '../../../../../types/step-component.interface';
import { StoreService } from '../../../../../services/store.service';
import * as _ from 'lodash';
import * as braintree from 'braintree-web';
import { from, Observable, Subscription } from 'rxjs';
import { AlternateBillingComponent } from './alternate-billing/alternate-billing.component';
import { BusinessService } from '../../../../../services/business.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CvvModalComponent } from '../../../../../shared/components/cvv-modal.component';
import { scrollIntoView } from '../../../../../shared/utils';
import { BackendService } from '../../../../../services/backend.service';
import { HttpClient } from '@angular/common/http';
import { switchMap } from 'rxjs/operators';
import { NgForm } from '@angular/forms';
import { environment } from '../../../../../../environments/environment';

export class UnsupportedCreditCardType extends Error {
  reason = 'unsupported_credit_card_type';
}

@Component({
  selector: 'app-payment-form',
  templateUrl: './payment-form.component.html',
  styleUrls: [`./payment-form.component.scss`]
})
export class PaymentFormComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('form') form: NgForm;
  @Input() metadata: IComponent;
  @ViewChild('alternateBilling') alternateBilling: AlternateBillingComponent;

  numberValidationMessage: string;
  monthValidationMessage: string;
  yearValidationMessage: string;
  cvvValidationMessage: string;

  private brainTreeCreated: boolean;
  private hostedFieldsInstance: braintree.HostedFields;
  private pageSetup$: Observable<any>;
  private subscription: Subscription;
  private hostedFieldsData = {
    'card-number': {
      label: 'Credit Card Number',
      'validation-message-variable': 'numberValidationMessage',
      definition: 'number'
    },
    'expiration-month': {
      label: 'Expiration Month',
      'validation-message-variable': 'monthValidationMessage',
      definition: 'expirationMonth'
    },
    'expiration-year': {
      label: 'Expiration Year',
      'validation-message-variable': 'yearValidationMessage',
      definition: 'expirationYear'
    },
    cvv: {
      label: 'CVV',
      'validation-message-variable': 'cvvValidationMessage',
      definition: 'cvv'
    }
  };

  constructor(
    public storeService: StoreService,
    private backendService: BackendService,
    private businessService: BusinessService,
    private http: HttpClient,
    private modalService: NgbModal,
    private zone: NgZone,
  ){
  }

  ngOnInit() {
    const state = this.storeService.getCurrentState();
    const pgName = state.pgConfig?.programGroupName;
    this.pageSetup$ = this.http.get(`https://${state.envConfig.envPrefix}donate.lls.org/charger/get-config/${pgName}/`);
    this.storeService.updateState({ nextIsDisabled: true });
  }

  ngAfterViewInit(): void {
    if (!this.brainTreeCreated) {
      this.brainTreeCreated = true;
      this.createBraintreeUI();
    }
  }

  ngOnDestroy(): void {
    this.storeService.updateState({ nextIsDisabled: false });
    this.subscription.unsubscribe();
  }

  openCvvModal(e) {
    e.preventDefault();
    this.modalService.open(CvvModalComponent, {size: 'lg', backdrop : 'static', keyboard: false});
  }

  createBraintreeUI() {
    this.subscription = this.pageSetup$.subscribe((pageSetup: any) => {
      if (pageSetup) {
        braintree.client.create({
          authorization: pageSetup.clientToken
        }).then((clientInstance) => {
          braintree.dataCollector.create({
              client: clientInstance,
              kount: false,
              paypal: false
            }, (dataCollectorErr, dataCollectorInstance) => {
              if (dataCollectorErr) {
                return;
              }
            }
          );

          braintree.hostedFields.create({
            client: clientInstance,
            styles: {
              input: {
                'font-size': '16px',
                color: '#525252',
              },
            },
            fields: {
              number: {
                selector: '#card-number'
              },
              cvv: {
                selector: '#cvv',
              },
              expirationMonth: {
                selector: '#expiration-month',
                placeholder: 'MM'
              },
              expirationYear: {
                selector: '#expiration-year',
                placeholder: 'YY',
              }
            }
          }).then((hostedFieldsInstance) => {
            this.hostedFieldsInstance = hostedFieldsInstance;

            hostedFieldsInstance.on('blur', (event) => {
              const field = event.fields[event.emittedBy];
              this.validateHostedFields(field);
              document.getElementById(field.container.id).classList.add('invalid-braintree-hosted-fields');
            });

            hostedFieldsInstance.on('validityChange', (event) => {
              const field = event.fields[event.emittedBy];
              this.validateHostedFields(field);
            });

            this.storeService.updateState({ nextIsDisabled: false });
          });
        });
      }
    });
  }

  isValid(isScrollIntoView = false) {
    let invalidFieldId = '';
    const fields = this.hostedFieldsInstance.getState().fields;
    for (const key in fields) {
      const id = this.getHostedFieldIdByDefinition(key);
      if (fields[key].isEmpty) {
        this[this.hostedFieldsData[id]['validation-message-variable']] = `${this.hostedFieldsData[id].label} is required`;
        document.getElementById(id).classList.add('invalid-braintree-hosted-fields');
        if (!invalidFieldId) {
          invalidFieldId = id;
        }
      } else if (!fields[key].isValid) {
        this[this.hostedFieldsData[id]['validation-message-variable']] = `${this.hostedFieldsData[id].label} is not valid`;
        document.getElementById(id).classList.add('invalid-braintree-hosted-fields');
        if (!invalidFieldId) {
          invalidFieldId = id;
        }
      }
    }

    if (invalidFieldId) {
      if (isScrollIntoView) {
        scrollIntoView(invalidFieldId);
      }
      return false;
    } else {
      return true;
    }
  }

  tokenizeUserDetails() {
    return from(this.hostedFieldsInstance.tokenize()).pipe(
      switchMap((payload) => {
        const postData: any = this.getPostData();
        postData.nonce = payload.nonce;
        postData.selectedCreditCardType = this.mapCreditCardType(payload.details.cardType);

        if (postData.selectedCreditCardType === 'Unknown') {
          throw new UnsupportedCreditCardType('Unsupported credit card type');
        }

        postData.number = payload.details.lastFour;
        const pgName = this.storeService.getCurrentState().pgConfig?.programGroupName;

        if (environment.production) {
          const envConfig = this.storeService.getCurrentState().envConfig;
          return this.http.post(`https://${envConfig.envPrefix}donate.lls.org/charger/card/${pgName}/`, postData);
        } else {
          // we need to proxy through and change referrer/origin for local dev
          return this.http.post(`/charger/card/${pgName}/`, postData);
        }
      }),
    );
  }

  private mapCreditCardType(btType: string) {
    switch (btType.toLowerCase()) {
      case 'american express':
        return 'AMEX';
      case 'visa':
        return 'VISA';
      case 'mastercard':
        return 'MASTERCARD';
      case 'discover':
        return 'DISCOVER';
    }
    return 'Unknown';
  }

  private validateHostedFields(field: any) {
    if (field.isEmpty) {
      this[this.hostedFieldsData[field.container.id]['validation-message-variable']] = `${this.hostedFieldsData[field.container.id].label} is required`;
    } else if (!field.isValid) {
      this[this.hostedFieldsData[field.container.id]['validation-message-variable']] = `${this.hostedFieldsData[field.container.id].label} is not valid`;
    } else {
      this[this.hostedFieldsData[field.container.id]['validation-message-variable']] = '';
    }
    this.zone.run(() => {});
  }

  private getHostedFieldIdByDefinition(definition: string): string {
    for (const key in this.hostedFieldsData) {
      if (this.hostedFieldsData[key].definition === definition) {
        return key;
      }
    }
  }

  private getPostData() {
    const state = this.storeService.getCurrentState();
    let billingInfo: any;
    if (state.useAlternateBilling) {
      billingInfo = _.cloneDeep(state.alternateBillingInfo);
      delete billingInfo.firstName;
      delete billingInfo.lastName;
      billingInfo.country = 'USA';
    } else {
      billingInfo = this.getPersonalInfoAddress();
    }
    return {
      amount: this.businessService.getOrderAmount(),
      billingAddress: billingInfo,
      captcha: state.lastCaptchaResponse,
      coupon: {},
      emailAddress: state.contactInfo.emailAddress,
      firstName: state.contactInfo.firstName,
      lastName: state.contactInfo.lastName,
      mailingAddress: this.getPersonalInfoAddress(),
      regInfo: this.businessService.getPostData(),
      phone: state.contactInfo.phoneNumber,
      referredURL: document.referrer,
      shoppingCartItems: [],
      donationPageURL: window.location.toString(),
      matchingCompanyName: state.matchingCompanyName,
    };
  }

  private getPersonalInfoAddress() {
    const state = this.storeService.getCurrentState().contactInfo;
    return {
      address1: state.address1,
      address2: state.address2,
      city: state.city,
      country: 'USA',
      postalCode: state.postalCode,
      state: state.stateID
    };
  }
}
