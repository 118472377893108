import { Component, Input } from '@angular/core';
import { IComponent } from '../../../types/step-component.interface';
import { AbstractStepComponent } from '../../core/abstract-step-component';
import { StoreService } from '../../../services/store.service';

@Component({
  selector: 'app-confirmation-step',
  template: `
    <div *ngIf="(storeService.vm$ | async) as vm">
      <subcomponent [parentMetadata]="metadata" name="REGISTERED_EVENT_DETAILS"></subcomponent>
      <subcomponent [parentMetadata]="metadata" name="FUNDRAISING_MATTERS"></subcomponent>
      <subcomponent [parentMetadata]="metadata" name="REGISTERED_CANDIDATE_DETAILS"></subcomponent>
      <subcomponent [parentMetadata]="metadata" name="REGISTRATION_FEE"></subcomponent>
      <subcomponent [parentMetadata]="metadata" name="YOUTUBE_VIDEO"></subcomponent>
    </div>
  `
})
export class ConfirmationComponent extends AbstractStepComponent {
  @Input() metadata: IComponent;
  hasStepControls = false;

  constructor(public storeService: StoreService) {
    super();
  }

  canGoToNextStep(): boolean {
    return this.storeService.getCurrentState().isRegistered;
  }

  saveHandler(): void {
  }
}
