import {
  AfterContentInit,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
} from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { StoreService } from '../../../services/store.service';
import { AppColumnDefDirective } from '../../../directives/column-def.directive';
import * as _ from 'lodash';
import { TableData } from '../../../types/table-data.interface';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent implements AfterContentInit, OnDestroy, OnInit {
  @ContentChildren(AppColumnDefDirective) columnDefsQuery: QueryList<AppColumnDefDirective>;
  @Input() selectedRow: any;
  @Input() rows: any[];
  @Input() tableStorageKey: string;
  @Input() compareIdentifier: string;
  @Output() selectRow = new EventEmitter();

  activeSort = {column: null, dir: null};
  columnDefs: AppColumnDefDirective[];
  columnSortingStates = [];
  itemsPerPage = 6;
  selectedRowElement: any;
  currentData: TableData;

  private onDestroy$ = new Subject<void>();

  constructor(public storeService: StoreService) {
  }

  ngOnInit() {
    const tableData = this.storeService.getCurrentState().tableData;
    const isCurrentTableAdded = !!tableData.find((data: any) => data.tableStorageKey === this.tableStorageKey);
    if (!isCurrentTableAdded) {
      tableData.push({
        tableStorageKey: this.tableStorageKey,
        currentPage: 1,
        activeSort: {
          column: '',
          dir: ''
        },
        selectedRow: null,
        hasPagination: this.rows.length > this.itemsPerPage,
      });
    }
    this.storeService.updateState({ tableData });

    this.storeService.tableData$.pipe(
      takeUntil(this.onDestroy$)
    ).subscribe(freshTableData => {
      this.currentData = freshTableData.find((data: any) => data.tableStorageKey === this.tableStorageKey);
    });
  }

  ngAfterContentInit(): void {
    this.columnDefs = this.columnDefsQuery.toArray();

    if (this?.currentData?.activeSort?.column) {
      this.activeSort = this.currentData.activeSort;
      this.columnSortingStates.push({
        column: this.activeSort.column,
        dir: this.activeSort.dir
      });
    } else {
      this.columnDefs.forEach(col => {
        if (col.sortField) {
          this.columnSortingStates.push({
            column: col,
            dir: 'desc'
          });
        }
      });

      if (this.columnSortingStates.length > 0) {
        this.activeSort = {
          column: this.columnSortingStates[0].column,
          dir: this.columnSortingStates[0].dir,
        };
      }
    }
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  onSelectRow(row, event) {
    if (this.selectedRowElement && this.selectedRowElement.closest('tr.is-selected')) {
      this.selectedRowElement.closest('tr.is-selected').classList.remove('is-selected');
    }
    this.selectedRowElement = event.target.parentElement;
    event.target.closest('tr').classList.add('is-selected');
    this.updateTableState({selectedRow: row});
    this.selectRow.emit(row);
  }

  isSortableColumn(header: AppColumnDefDirective) {
    return this.columnSortingStates.find((o: any) => o.column.name === header.name);
  }

  getSortDir(header: AppColumnDefDirective) {
    return this.columnSortingStates.find((o: any) => o.column.name === header.name).dir;
  }

  sortBy(event, header: AppColumnDefDirective) {
    if (!event) {
      return;
    }
    const sortCol = this.columnSortingStates.find((o: any) => o.column.name === header.name);
    sortCol.dir = sortCol.dir === 'asc' ? 'desc' : 'asc';
    this.updateTableState({
      activeSort: {
        column: sortCol.column,
        dir: sortCol.dir,
      }
    });
  }

  isEqual(a: any, b: any) {
    if (a && b && this.compareIdentifier) {
      return a[this.compareIdentifier] === b[this.compareIdentifier];
    } else {
      return _.isEqual(a, b);
    }
  }

  onPageChange(event) {
    this.updateTableState({ currentPage: event });
  }

  private updateTableState(obj: any) {
    const tablesData = this.storeService.getCurrentState().tableData;
    const currentTableData = tablesData.find((data: any) => data.tableStorageKey === this.tableStorageKey);
    for (const key in obj) {
      currentTableData[key] = obj[key];
    }
    this.storeService.updateState({ tableData: tablesData });
  }
}
