import { Component, Input } from '@angular/core';
import { IComponent } from '../../../../types/step-component.interface';

@Component({
  selector: 'app-no-event-found',
  template: `
    <div class="detail" [innerHTML]="metadata.attributes.DESCRIPTION"></div>
    <div *ngIf="showValidationMessage" class="alert alert-danger" role="alert">
      Please select an event.
    </div>
  `,
  styles: [`
    .detail {
      padding: 0.75em;
      border: 1px solid #D1D1D1;
    }
  `]
})
export class NoEventFoundComponent {
  @Input() metadata: IComponent;
  showValidationMessage = false;
}
