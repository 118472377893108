import { Component, ComponentFactoryResolver, Input, OnInit, ViewChild } from '@angular/core';
import { IComponent } from '../../types/step-component.interface';
import { StepDefinition } from '../../types/step-definition';
import { SubcomponentMappings } from '../../shared/subcomponent-mappings';
import { SubcomponentHostDirective } from '../../directives/subcomponent-host.directive';

@Component({
  selector: 'subcomponent',
  template: `
    <div class="mt-2 mb-2">
      <ng-template subcomponent-host></ng-template>
    </div>
  `
})
export class SubcomponentComponent implements OnInit {
  @Input() parentMetadata: IComponent;
  @Input() name: string;
  @ViewChild(SubcomponentHostDirective, { static: true }) subcomponentHost: SubcomponentHostDirective;

  component: IComponent;
  componentInstance: any;
  isActive: boolean;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver
  ) {}

  ngOnInit() {
    this.component = this.getSubComponent(this.name);
    this.isActive = !!this.component;

    if (this.isActive) {
      const resolver = this.componentFactoryResolver.resolveComponentFactory(this.resolveComponentClass(this.name));
      const componentRef = this.subcomponentHost.viewContainerRef.createComponent(resolver);
      (componentRef.instance as any).metadata = this.component;
      this.componentInstance = componentRef.instance;
    }
  }

  private resolveComponentClass(componentName: string) {
    return SubcomponentMappings[componentName];
  }

  private getSubComponent(componentName: string) {
    return this.parentMetadata
      .subcomponents.find((component: StepDefinition) => component.sysComponentName === componentName);
  }
}
