import { Component, Input } from '@angular/core';
import { IComponent } from '../../../../types/step-component.interface';

@Component({
  selector: 'app-fundraising-matters',
  template: `
    <div>
      Your fundraising matters! Here are a few amazing things we can do because of fundraisers like you!
    </div>
    <ul class="mb-2">
      <li>
        $300 could help fund one week of LLS-sponsored group support for families dealing with blood cancer treatment.
      </li>
      <li>
        $500 could help defray the costs of treatment-related travel for a patient.
      </li>
      <li>
        $1,000 could help fund general lab supplies for five weeks to help LLS-funded researchers conduct
        laboratory work needed to develop blood cancer treatments.
      </li>
      <li>
        $7,500 could help fund the work of an LLS-funded research fellow for six weeks.
      </li>
      <li>
        $10,000 can allow donor direct funding of one of nine disease-specific research portfolios.
      </li>
    </ul>
  `
})
export class FundraisingMattersComponent {
  @Input() metadata: IComponent;
}
