import { AfterViewInit, Component, Input, OnDestroy, QueryList, ViewChildren } from '@angular/core';
import { IComponent } from '../../../../../types/step-component.interface';
import { StoreService } from '../../../../../services/store.service';
import { IValidationSubComponent } from '../../../../../types/subcomponent-validation.interface';
import { SubcomponentComponent } from '../../../../core/subcomponent.component';
import { Subscription } from 'rxjs';
import { scrollIntoView } from '../../../../../shared/utils';

@Component({
  selector: 'app-ways-to-participant',
  template: `
    <ng-container *ngIf="(storeService.vm$ | async) as vm">
      <h3 id="ways-to-participate">{{metadata.attributes.HEADING}}</h3>
      <div class="detail" [innerHTML]="metadata.attributes.DESCRIPTION"></div>

      <div class="d-flex">
        <subcomponent [parentMetadata]="metadata" name="WAYS_TO_PARTICIPATE_JOIN_TEAM"></subcomponent>
        <subcomponent [parentMetadata]="metadata" name="WAYS_TO_PARTICIPATE_CREATE_TEAM"></subcomponent>
        <subcomponent [parentMetadata]="metadata" name="WAYS_TO_PARTICIPATE_INDIVIDUAL"></subcomponent>
      </div>
      <hr style="border-top: 1px solid #e1e1e1"/>
      <div *ngIf="(storeService.vm$ | async) as vm">
        <subcomponent #joinTeam *ngIf="vm.wayToParticipate === 'join_team' || metadata.subcomponents.length === 1" [parentMetadata]="metadata" name="JOIN_TEAM"></subcomponent>
        <subcomponent #createTeam *ngIf="vm.wayToParticipate === 'create_team'" [parentMetadata]="metadata" name="CREATE_TEAM"></subcomponent>
      </div>
      <div *ngIf="validated && !vm.wayToParticipate" class="alert alert-danger" role="alert">
        Please select a way to participate.
      </div>
    </ng-container>
  `
})
export class WaysToParticipateComponent implements IValidationSubComponent, AfterViewInit, OnDestroy {
  @Input() metadata: IComponent;
  @ViewChildren('joinTeam') joinTeam: QueryList<SubcomponentComponent>;
  @ViewChildren('createTeam') createTeam: QueryList<SubcomponentComponent>;
  private subscription: Subscription;

  validated = false;

  constructor(
    public storeService: StoreService
    ) {}

  ngAfterViewInit() {
    const currentState = this.storeService.getCurrentState();
    if (!currentState.trainingLocations) {
      if (!currentState.fundraisingLevels) {
        if (currentState.sportCourses) {
          this.subscription = this.storeService.sportCourse$.subscribe((o) => {
            if (o) {
              scrollIntoView('ways-to-participate');
            }
          });
        }
      } else {
        this.subscription = this.storeService.fundraisingLevel$.subscribe((o) => {
          if (o) {
            scrollIntoView('ways-to-participate');
          }
        });
      }
    } else {
      this.subscription = this.storeService.trainingLocation$.subscribe((o) => {
        if (o) {
          scrollIntoView('ways-to-participate');
        }
      });
    }
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  validate(): boolean {
    let result = true;

    if (this.joinTeam?.last) {
      result = result && this.joinTeam.last.componentInstance.validate();
    }
    if (this.createTeam?.last) {
      result = result && this.createTeam.last.componentInstance.validate();
    }

    this.validated = true;
    return result && !!this.storeService.getCurrentState().wayToParticipate;
  }
}
