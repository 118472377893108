import { Component, DoCheck, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import * as _ from 'lodash';

// Ordering is important as well, top ones will appear before last ones
const errorMessages = [
  { validator: 'required', message: '%s is required' },
  { validator: 'nospaces', message: '%s cannot include spaces' },
  { validator: 'validCharsOnly', message: '%s has invalid characters' },
  { validator: 'minlength', message: '%s is too short' },
  { validator: 'pattern', message: 'Invalid %s' },
  { validator: 'atLeastOneNumber', message: '%s should contain at least one number' },
  { validator: 'atLeastOneLowercaseChar', message: '%s should contain at least one lowercase character' },
  { validator: 'atLeastOneUppercaseChar', message: '%s should contain at least one uppercase character' },
  { validator: 'atLeastOneSpecialChar', message: '%s should contain at least one special character' },
  { validator: 'maxlength128', message: '%s is too long' },
  { validator: 'email', message: 'Invalid email address' },
];

@Component({
  selector: 'control-errors',
  template: `
    <div *ngIf="msg" class="invalid-feedback">{{ msg }}</div>
  `
})
export class ControlErrorsComponent implements DoCheck {
  @Input() control: UntypedFormControl;
  @Input() name: string;

  msg: string;

  ngDoCheck() {
    if (this.control.touched && this.control.errors) {
      const errorKeys = _.keys(this.control.errors);
      const error = errorMessages.find((item) => {
        return errorKeys.indexOf(item.validator) !== -1;
      });

      if (!error) {
        return;
      }

      this.msg = error.message.replace('%s', this.name);
      return;
    }
    this.msg = null;
  }
}
