import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable, of } from 'rxjs';
import { StoreService } from '../services/store.service';
import { catchError, tap } from 'rxjs/operators';
import { DonationPageConfig } from '../types/donation-page-config.interface';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class DonationPageConfigResolver implements Resolve<Observable<any>> {
  constructor(
    private http: HttpClient,
    private storeService: StoreService,
    private toastrService: ToastrService
  ) {}

  resolve() {
    return this.http.get(
      '/OnlineRegistration/service/api/getDonationPageConfig?donationType='
        + this.storeService.getCurrentState().pgConfig.programGroupName
    ).pipe(
      catchError(err => {
        this.toastrService.error('Something went wrong.');
        return of(null);
      }),
      tap((donationPageConfig: DonationPageConfig) => {
        this.storeService.updateState({ donationPageConfig });
      })
    );
  }
}
