import { Component, OnInit } from '@angular/core';
import { PROGRAM_GROUP_INFO } from '../../../shared/constants';
import { StoreService } from '../../../services/store.service';

@Component({
  selector: 'app-header',
  template: `
    <div class="container lls-container">
      <img [src]="logoUrl"/>
    </div>
  `,
  styles: [`
    :host {
      display: block;
      padding: 20px 0;
    }

    img {
      max-width: 300px;
      width: 100%;
      height: auto;
      display: block;
    }
  `]
})
export class HeaderComponent implements OnInit {
  headerText: string;
  logoUrl: string;

  constructor(private storeService: StoreService) {}

  ngOnInit() {
    const pgName = this.storeService.getCurrentState().pgConfig.programGroupName;
    this.headerText = pgName;
    this.logoUrl = 'assets/images/' + PROGRAM_GROUP_INFO[pgName].logo;
  }
}
