<div *ngIf="(storeService.vm$ | async) as vm">
  <div>
    <h2 [innerHTML]="metadata.attributes.HEADING" class="mb-3"></h2>
    <div [innerHTML]="metadata.attributes.DESCRIPTION"></div>
  </div>

  <subcomponent [parentMetadata]="metadata" name="EVENT_SEARCH_BY_ZIP"></subcomponent>
  <subcomponent *ngIf="vm.events" [parentMetadata]="metadata" name="EVENT_SEARCH_BY_KEYWORD"></subcomponent>
  <subcomponent *ngIf="vm.events" [parentMetadata]="metadata" name="EVENT_SEARCH_BY_EVENT_TYPE"></subcomponent>
  <subcomponent #searchResult *ngIf="vm.filteredEvents && vm.filteredEvents.length > 0" [parentMetadata]="metadata" name="EVENT_SEARCH_RESULT"></subcomponent>
  <subcomponent *ngIf="vm.events && vm.selectedEvent" [parentMetadata]="metadata" name="SELECTED_EVENT_DETAILS"></subcomponent>
  <subcomponent *ngIf="vm.events && vm.selectedEvent" [parentMetadata]="metadata" name="SELECTED_EVENT_DETAILS_W_REG_FEE"></subcomponent>
  <subcomponent #noEventFound *ngIf="vm.filteredEvents && vm.filteredEvents.length === 0" [parentMetadata]="metadata" name="NO_EVENT_FOUND"></subcomponent>
</div>
