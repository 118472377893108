<div *ngIf="(storeService.vm$ | async) as vm" class="mb-3">
  <div *ngIf="vm.signinState === 'sign-in' && vm.recoverPassword" class="notification mb-2">
    Thank you. An email with further instructions has been sent to this address. Please check your inbox.
  </div>
</div>

<h4>Existing LLS Account</h4>
<p class="mb-2">If you have participated in an event with The Leukemia & Lymphoma Society, enter your username and password.</p>

<app-sign-in-social></app-sign-in-social>

<h4 class="mt-3">Or, Login using LLS Account below:</h4>

<form #form="ngForm">
  <div class="mb-3">
    <label>Email address</label>
    <input type="text"
           class="form-control"
           name="username"
           [(ngModel)]="creds.username"
           #username="ngModel"
           email
           required
    >
    <control-errors [control]="username" [name]="'Email address'"></control-errors>
  </div>
  <div class="mb-2">
    <label>Password</label>
    <input type="password"
           class="form-control"
           name="password"
           [(ngModel)]="creds.password"
           #password="ngModel"
           required
    >
    <control-errors [control]="password" name="Password"></control-errors>
  </div>

  <div *ngIf="loginError" class="alert alert-danger" role="alert" [innerHTML]="loginError"></div>

  <div class="mb-2">
    <button class="btn btn-primary" (click)="credSignin()" [disabled]="form.invalid || disabled"
            [ngClass]="{ 'disabled': form.invalid }"
    >Sign In</button>
  </div>

  <div class="mb-2 medium-fontsize">
    <a href="javascript: void(0);" (click)="openTemporaryPasswordForm()">Forgot your email or password?</a>
  </div>

</form>
