import { AfterViewInit, Component, Input, QueryList, ViewChildren, OnInit } from '@angular/core';
import { IComponent } from '../../../../../../types/step-component.interface';
import { StoreService } from '../../../../../../services/store.service';
import { BackendService } from '../../../../../../services/backend.service';
import { Team } from '../../../../../../types/team.interface';
import { SubcomponentComponent } from '../../../../../core/subcomponent.component';
import { IValidationSubComponent } from '../../../../../../types/subcomponent-validation.interface';
import { scrollIntoView } from '../../../../../../shared/utils';

@Component({
  selector: 'app-create-team',
  template: `
    <div id="create-team" *ngIf="(storeService.vm$ | async) as vm">
      <h3>{{metadata.attributes.HEADING}}</h3>
      <div [innerHTML]="metadata.attributes.DESCRIPTION"></div>
      <subcomponent #teamName [parentMetadata]="metadata" name="CREATE_TEAM_NAME"></subcomponent>
      <subcomponent #uploadImage [parentMetadata]="metadata" name="UPLOAD_TEAM_IMAGE"></subcomponent>
      <subcomponent [parentMetadata]="metadata" name="CREATE_TEAM_SELECT_TEAM_TYPE"></subcomponent>
      <subcomponent [parentMetadata]="metadata" name="CREATE_TEAM_SELECT_NATIONAL_TEAM"></subcomponent>
      <subcomponent #frStartEnd [parentMetadata]="metadata" name="CREATE_TEAM_SELECT_FR_STARTEND"></subcomponent>
    </div>
  `
})
export class CreateTeamComponent implements IValidationSubComponent, AfterViewInit, OnInit {
  @Input() metadata: IComponent;
  @ViewChildren('teamName') teamName: QueryList<SubcomponentComponent>;
  @ViewChildren('teamType') teamType: QueryList<SubcomponentComponent>;
  @ViewChildren('frStartEnd') frStartEnd: QueryList<SubcomponentComponent>;
  @ViewChildren('uploadImage') uploadImage: QueryList<SubcomponentComponent>;

  teams: Team[];

  constructor(
    public storeService: StoreService,
    private backendService: BackendService
  ) {}

  ngOnInit() {
    // In addition to Join Team, we also load the teams in the Create Team flow.
    // This is done since the call is used to populate Schools in the national team component as well.
    if (this.storeService.getCurrentState().pgConfig.programGroupName == 'ltn' && !this.storeService.getCurrentState().teams && !this.storeService.getCurrentState().teamsLoading) {
      this.backendService.loadTeams().subscribe(() => {});
    }
  }

  validate() {
    let result = true;
    if (this.teamName?.last) {
      const cmpResult = this.teamName.last.componentInstance.validate();
      result = result && cmpResult;
    }
    if (this.uploadImage?.last) {
      const cmpResult = this.uploadImage.last.componentInstance.validate();
      result = result && cmpResult;
    }
    if (this.frStartEnd?.last?.isActive) {
      const cmpResult = this.frStartEnd.last.componentInstance.validate();
      result = result && cmpResult;
    }
    return result;
  }

  ngAfterViewInit() {
    scrollIntoView('create-team');
  }
}
