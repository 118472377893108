import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'policy-modal',
  template: `
    <div class="modal-content">
      <div class="modal-header">
        <h2>{{heading}}</h2>
        <button type="button" class="close" (click)="activeModal.dismiss()">
          <span>&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div [innerHTML]="description | safeHtml"></div>
      </div>
    </div>
  `,
  styleUrls: ['./policy-modal.component.scss']
})
export class PolicyModalComponent {
  @Input() description: string;
  @Input() heading: string;

  constructor(public activeModal: NgbActiveModal) {}
}
