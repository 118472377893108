import { Component, Input, OnInit, QueryList, ViewChildren } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { forkJoin } from 'rxjs';
import { BackendService } from '../../../services/backend.service';
import { ProgressService } from '../../../services/progress.service';
import { StoreService } from '../../../services/store.service';
import { IComponent } from '../../../types/step-component.interface';
import { WayToParticipate } from '../../../types/way-to-participate.type';
import { AbstractStepComponent } from '../../core/abstract-step-component';
import { SubcomponentComponent } from '../../core/subcomponent.component';
import { WaysToParticipateComponent } from './subcomponents/ways-to-participant/ways-to-participate.component';

@Component({
  selector: 'app-event-specific-info-step',
  template: `
    <div class="container lls-container">
      <div *ngIf="!progressService.isBlocked && (storeService.vm$ | async) as vm">
        <subcomponent *ngIf="shouldShowSportCourses" #sportCourse [parentMetadata]="metadata"
                      name="SPORT_COURSE"></subcomponent>
        <subcomponent #fundraising *ngIf="shouldShowFundraisingLevels"
                      [parentMetadata]="metadata"
                      name="FUNDRAISING_LEVEL"></subcomponent>
        <subcomponent *ngIf="(vm.fundraisingLevel || !vm.fundraisingLevels) && vm.trainingLocations"
                      #training [parentMetadata]="metadata"
                      name="TRAINING_LOCATION"></subcomponent>
        <subcomponent
          *ngIf="shouldShowTheBottomFormControls"
          #waysToParticipant
          [parentMetadata]="metadata"
          name="WAYS_TO_PARTICIPATE"
        ></subcomponent>
        <subcomponent
          *ngIf="shouldShowTheBottomFormControls"
          #fundraisingGoal
          [parentMetadata]="metadata"
          name="FUNDRAISING_GOAL"
        ></subcomponent>
        <subcomponent
          *ngIf="shouldShowTheBottomFormControls"
          #tShirt
          [parentMetadata]="metadata"
          name="T_SHIRT_SIZE"
        ></subcomponent>
      </div>
    </div>
  `
})
export class EventSpecificInfoComponent extends AbstractStepComponent implements OnInit {
  @ViewChildren('fundraising') fundraising: QueryList<SubcomponentComponent>;
  @ViewChildren('sportCourse') sportCourse: QueryList<SubcomponentComponent>;
  @ViewChildren('training') training: QueryList<SubcomponentComponent>;
  @ViewChildren('waysToParticipant') waysToParticipant: QueryList<SubcomponentComponent>;
  @ViewChildren('fundraisingGoal') fundraisingGoal: QueryList<SubcomponentComponent>;
  @ViewChildren('tShirt') tShirt: QueryList<SubcomponentComponent>;
  @Input() metadata: IComponent;

  get shouldShowSportCourses() {
    const vm = this.storeService.getCurrentState();
     return vm.sportCourses || (vm.pgConfig.programGroupName === 'tnt' && !vm.selectedEvent.eventName.includes('DIY:')
      && !vm.selectedEvent.eventName.includes('TNT Your Way')  && !vm.selectedEvent.eventName.includes('Cheer Squad') )  ;
  }

  get shouldShowFundraisingLevels() {
    const vm = this.storeService.getCurrentState();
    return (vm.sportCourse || (!vm.sportCourses && (vm.selectedEvent == null || vm.selectedEvent.eventName.includes('DIY:')
      || vm.selectedEvent.eventName.includes('TNT Your Way')  || vm.selectedEvent.eventName.includes('Cheer Squad'))))
      && (vm.fundraisingLevels || vm.pgConfig.programGroupName === 'tnt');
  }

  get shouldShowTheBottomFormControls() {
    const vm = this.storeService.getCurrentState();
    return vm.pgConfig.programGroupName !== 'tnt'
      || ( vm.fundraisingLevel );
  }

  constructor(
    public progressService: ProgressService,
    public storeService: StoreService,
    private backendService: BackendService
  ) {
    super();
  }

  ngOnInit() {
    this.progressService.block();
    if (this.storeService.getCurrentState().pgConfig.programGroupName === 'tnt') {
      forkJoin(
        this.backendService.loadSportCourseOptions(),
        this.backendService.loadFundraisingLevelOptions(),
        this.backendService.loadTrainingLocationOptions()
      ).subscribe((res) => {
        this.storeService.updateState({showWaysToParticipate: true});
        this.progressService.unblock();
      }, () => {
        this.storeService.updateState({showWaysToParticipate: true});
        this.progressService.unblock();
      });
    } else {
      this.progressService.unblock();
    }
  }

  canGoToNextStep(): boolean {
    const validationComponents = [];
    validationComponents.push(this.sportCourse.last?.componentInstance);
    validationComponents.push(this.fundraising.last?.componentInstance);
    validationComponents.push(this.training.last?.componentInstance);
    validationComponents.push(this.waysToParticipant.last?.componentInstance);
    validationComponents.push(this.fundraisingGoal.last?.componentInstance);
    validationComponents.push(this.tShirt.last?.componentInstance);
    validationComponents.push(this.fundraising.last?.componentInstance);

    let result = true;
    validationComponents.filter(o => !!o).forEach(o => {
      const cmpResult = o.validate();
      result = result && cmpResult;
    });
    return result;
  }

  saveHandler(): void {
    const waysToParticipateComponent = this.waysToParticipant?.last?.componentInstance as WaysToParticipateComponent;
    const wayToParticipateValue = this.storeService.getCurrentState().wayToParticipate;
    if (waysToParticipateComponent && wayToParticipateValue) {
      switch (wayToParticipateValue) {
        case WayToParticipate.JOIN_TEAM:
          this.storeService.updateState({ createTeam: null });
          break;
        case WayToParticipate.CREATE_TEAM:
          this.storeService.updateState({ selectedTeam: null });
          break;
        case WayToParticipate.WALK_AS_INDIVIDUAL:
          this.storeService.updateState({ selectedTeam: null });
          this.storeService.updateState({ createTeam: null });
          break;
      }
    }
  }
}
