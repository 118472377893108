import { Directive } from '@angular/core';
import { UntypedFormControl, NG_VALIDATORS, Validator } from '@angular/forms';

@Directive({
  selector: '[birthday]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: BirthdayValidator, multi: true }
  ]
})
export class BirthdayValidator implements Validator {
  validate(control: UntypedFormControl) {
    const year = control.value.year;
    const month = control.value.month;
    const day = control.value.day;
    if (year && month && day && new Date(year, month - 1, day) > new Date()) {
      return { birthday: true };
    }
    return null;
  }
}
