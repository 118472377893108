import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { StoreService } from '../../../../services/store.service';
import { IComponent } from '../../../../types/step-component.interface';
import { NgForm } from '@angular/forms';
import { DateOfBirth } from '../../../../types/date-of-birth.interface';
import { Subscription } from 'rxjs';
import { under18 } from '../../../../shared/utils';

@Component({
  selector: 'app-date-of-birth',
  template: `
    <div *ngIf="(storeService.vm$ | async) as vm">
      <h3>{{metadata.attributes.HEADING}}</h3>
      <form #form="ngForm" birthday>
        <div class="form-row">
          <div class="form-group col-md-4">
            <label for="month">Month</label>
            <input
                    type="text"
                    placeholder="MM"
                    class="form-control"
                    id="month"
                    name="month"
                    [(ngModel)]="dateOfBirth.month"
                    required
                    month
                    maxlength="2"
                    #month="ngModel">
            <div *ngIf="month.touched && month.errors?.month" class="invalid-feedback">
              Month is invalid.
            </div>
          </div>
          <div class="form-group col-md-4">
            <label for="day">Day</label>
            <input
                    type="text"
                    placeholder="DD"
                    class="form-control"
                    id="day"
                    name="day"
                    [(ngModel)]="dateOfBirth.day"
                    required
                    day
                    maxlength="2"
                    #day="ngModel">
            <div *ngIf="day.touched && day.errors?.day" class="invalid-feedback">
              Day is invalid.
            </div>
          </div>
          <div class="form-group col-md-4">
            <label for="year">Year</label>
            <input
                    type="text"
                    placeholder="YYYY"
                    class="form-control"
                    id="year"
                    name="year"
                    [(ngModel)]="dateOfBirth.year"
                    required
                    year
                    maxlength="4"
                    #year="ngModel">
            <div *ngIf="year.touched && year.errors?.year" class="invalid-feedback">
              Year is invalid.
            </div>
          </div>
          <div *ngIf="form.touched && form.errors?.birthday" class="invalid-feedback">
            It seems that you have entered a date in future.
          </div>
          <div *ngIf="vm.under18" class="under18">
            If you are under 18, you will need a parent or guardian's signature to register for this event.
          </div>
        </div>
      </form>
    </div>
  `,
  styles: [`
    .under18 {
        margin: 8px;
        font-size: 17px;
    }
  `]
})
export class DateOfBirthComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('form') form: NgForm;
  @Input() metadata: IComponent;
  dateOfBirth: DateOfBirth = {
    month: '',
    day: '',
    year: '',
  };
  private subscription: Subscription;

  constructor(
    public storeService: StoreService) {
  }

  ngOnInit() {
    const storeDateOfBirth = this.storeService.getCurrentState().dateOfBirth;
    const signinDateOfBirth = this.storeService.getCurrentState().signinResult.dateOfBirth;

    if (storeDateOfBirth) {
      this.dateOfBirth.month = storeDateOfBirth.month;
      this.dateOfBirth.day = storeDateOfBirth.day;
      this.dateOfBirth.year = storeDateOfBirth.year;
    } else if (signinDateOfBirth) {
      const data = signinDateOfBirth.split('-');
      this.dateOfBirth.month = data[1];
      this.dateOfBirth.day = data[2];
      this.dateOfBirth.year = data[0];
    }
    under18(this.dateOfBirth.year, this.dateOfBirth.month, this.dateOfBirth.day, this.storeService);
  }

  ngAfterViewInit() {
    this.subscription = this.form.valueChanges.subscribe((data) => {
      if (this.form.valid && data.year && data.month && data.day) {
        const year = data.year;
        const month = data.month.length < 2 ? `0${data.month}` : data.month;
        const day = data.day.length < 2 ? `0${data.day}` : data.day;
        under18(year, month, day, this.storeService);
      }
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
