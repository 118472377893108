import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { IComponent } from '../../../../types/step-component.interface';
import { StoreService } from '../../../../services/store.service';
import { RecaptchaComponent } from 'ng-recaptcha';

@Component({
  selector: 'app-captcha',
  template: `
    <div *ngIf="(storeService.vm$ | async) as vm">
      <re-captcha (resolved)="resolved($event)" [siteKey]="captchaKey" #captchaElem></re-captcha>
      <div *ngIf="!vm.lastCaptchaResponse && vm.submitted" class="invalid">
        Captcha is required
      </div>
    </div>
  `,
  styles: [`
      .invalid {
          width: 100%;
          margin-top: 0.25rem;
          font-size: 80%;
          color: #dc3545
      }
  `]
})
export class CaptchaComponent {
  @ViewChild('captchaElem') captchaElem: RecaptchaComponent;
  @Input() metadata: IComponent;

  captchaKey: string;

  constructor(
    public element: ElementRef,
    public storeService: StoreService
  ) {
    this.captchaKey = this.storeService.getCurrentState().envConfig.reCaptchaKey;
  }

  resolved(event) {
    this.storeService.updateState({ lastCaptchaResponse: event });
  }

  reset() {
    this.captchaElem.reset();
  }
}
