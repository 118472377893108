import { Component } from '@angular/core';
import { AbstractStepComponent } from '../../core/abstract-step-component';
import { StoreService } from '../../../services/store.service';
import { WizardService } from '../../../services/wizard.service';

@Component({
  selector: 'app-sign-in-step',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent extends AbstractStepComponent {
  hasStepControls = false;

  constructor(
    public storeService: StoreService,
    private wizardService: WizardService
  ) {
    super();
  }

  previousStep() {
    this.wizardService.previousStep();
  }

  canGoToNextStep(): boolean {
    return true;
  }

  saveHandler(): void {}
}
