import { Component, Input, OnInit } from '@angular/core';
import { IComponent } from '../../../../../../types/step-component.interface';
import { StoreService } from '../../../../../../services/store.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Location } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import {map, switchMap} from 'rxjs/operators';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'app-team-search-result',
  template: `
    <div *ngIf="(storeService.vm$ | async) as vm">
      <h4>{{ metadata.attributes.HEADING }}</h4>
      <app-table
        [rows]="vm.filteredTeams"
        tableStorageKey="team"
        (selectRow)="onSelectRow($event)"
      >
        <ng-container *ngIf="hasColumn('team')" appColumnDef="Team" sortField="teamName">
          <div *appCellDef="let row" class="d-flex align-items-center">
            <div class="mr-3"><img
              src="{{row.teamImagePath ? row.teamImagePath : 'assets/images/default-avatar-black.png'}}"
              class="avatar"></div>
            <div>{{ row.teamName }}</div>
          </div>
        </ng-container>
        <ng-container *ngIf="hasColumn('captain')" appColumnDef="Captain" sortField="captainName">
          <span *appCellDef="let row">{{ row.captainName }}</span>
        </ng-container>
        <ng-container *ngIf="hasColumn('candidate')" appColumnDef="Candidate" sortField="captainName">
          <div *appCellDef="let row" class="d-flex align-items-center">
            <div class="mr-3"><img
              src="{{row.teamImagePath ? row.teamImagePath : 'assets/images/default-avatar-black.png'}}"
              class="avatar"></div>
            <div>{{ row.captainName }}</div>
          </div>
        </ng-container>
        <ng-container *ngIf="hasColumn('event & location')" appColumnDef="Event & Location">
          <div *appCellDef="let row">
            <div>{{ row.walksiteName }}</div>
            <div>{{ row.walksiteLocationCity }}, {{ row.walksiteLocationState }}</div>
          </div>
        </ng-container>
      </app-table>
    </div>
  `,
  styleUrls: ['./search-result.component.scss']
})
export class TeamSearchResultComponent implements OnInit {
  @Input() metadata: IComponent;
  enabledColumns: string[];
  programGroupName: string;
  obs: Observable<boolean> = of(false);

  constructor(
    public location: Location,
    public storeService: StoreService,
    private http: HttpClient,
    private toastrService: ToastrService
  ) {}

  ngOnInit() {
    this.enabledColumns = this.metadata.attributes.COLUMN_NAMES.split(' && ');
    this.enabledColumns = this.enabledColumns.map((column: string) => column.toLowerCase());
    this.programGroupName = this.storeService.getCurrentState().pgConfig.programGroupName;
  }

  hasColumn(name: string) {
    return this.enabledColumns.includes(name);
  }

  onSelectRow(row) {
    const currentState = this.storeService.getCurrentState();
    if (currentState.joinTeamCheckRegStatus && currentState.signinResult && currentState.signinResult.constituentID) {
      const params = new HttpParams().set('eventID', row.walksiteID).set('constituentID', currentState.signinResult.constituentID);
      this.obs = this.http.get('OnlineRegistration/service/api/isUserRegistered', {params}).pipe(
        map((res: any) => {
          this.storeService.updateState({isUserNotRegisteredForTeam: !res});
          return res;
        })
      );
    }

    this.obs.pipe(
      switchMap((res: boolean) => {
        if (!res) {
          this.storeService.updateState({ selectedTeamLoading: true });
          return this.http.get(`/OnlineRegistration/service/api/event?eventId=${row.walksiteID}`);
        } else {
          return of(false);
        }
      })
    ).subscribe((res: any) => {
      if (res) {
        if (this.storeService.getCurrentState().pgConfig.programGroupName === 'mwoy') {
          this.storeService.updateState({ selectedEvent: res });
        }
        this.storeService.updateState({ selectedTeamLoading: false });
        this.storeService.updateState({ selectedTeam: row });
      }
    }, () => {
      this.storeService.updateState({ selectedTeamLoading: false });
      this.toastrService.error('Something went wrong.');
    });
    if (this.metadata.attributes.MODIFY_URL_ON_JOIN_TEAM) {
      this.location.go(`team-selected/${row.teamResultDTOID}`);
    }
  }
}
