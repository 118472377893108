import { Component, Input, ViewChild } from '@angular/core';
import { IComponent } from '../../../../../../types/step-component.interface';
import { StoreService } from '../../../../../../services/store.service';
import { IValidationSubComponent } from '../../../../../../types/subcomponent-validation.interface';
import { NgModel } from '@angular/forms';
import { scrollIntoView } from '../../../../../../shared/utils';

@Component({
  selector: 'app-create-team-name',
  template: `
    <form *ngIf="(storeService.vm$ | async) as vm" id="create-team-name">
      <div class="mb-4 mt-4">
        <label class="top-label">{{metadata.attributes.LABEL}}</label>
        <label class="sub-label">{{metadata.attributes.LABEL2}}</label>
        <input type="text"
               class="form-control"
               name="team-name"
               required
               teamNameAvailability
               [(ngModel)]="createTeam.teamName"
               (change)="updateTeamName($event)"
               #teamName="ngModel"
        >
        <div *ngIf="teamName.touched && teamName.errors?.required" class="alert alert-danger mt-2" role="alert">
          Team name is required.
        </div>
        <div *ngIf="teamName.touched && teamName.errors?.teamNameAvailability" class="alert alert-danger mt-2" role="alert">
          Sorry, this team name is already in use for this event.
        </div>
      </div>
    </form>
  `,
  styles: [`
      .top-label {
        margin-bottom: 0px;
      }
      .sub-label {
        display: block;
        font-size: 16px;
        font-weight: 600;
      }
  `]
})
export class CreateTeamNameComponent implements IValidationSubComponent {
  @Input() metadata: IComponent;
  @ViewChild('teamName') teamNameElement: NgModel;

  createTeam = this.storeService.getCurrentState().createTeam || {teamName: ''};

  constructor(public storeService: StoreService) {
  }

  validate(): boolean {
    this.teamNameElement.control.markAsTouched();
    if (this.teamNameElement.control.valid) {
      return true;
    } else {
      scrollIntoView('create-team-name');
      return false;
    }
  }

  updateTeamName(event: any) {
    this.createTeam = this.storeService.getCurrentState().createTeam || this.createTeam;
    this.createTeam.teamName = event.target.value;
    this.storeService.updateState({createTeam: Object.assign({}, this.createTeam)});
  }
}
