import { Component, Input } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { StoreService } from '../../../../services/store.service';
import { IComponent } from '../../../../types/step-component.interface';

@Component({
  template: `
    <div *ngIf="(storeService.vm$ | async) as vm">
      <h3>{{metadata.attributes.HEADING}}</h3>
      <ng-container *ngIf="vm.selectedEvent.registrationFee > 0">
        <div *ngIf="metadata.attributes.DESCRIPTION" [innerHTML]="metadata.attributes.DESCRIPTION"></div>
        <div>
          <label for="promoCode">Promo Code (optional):</label>
          <div class="input-group">
            <input
              id="promoCode"
              type="text"
              class="form-control"
              name="promoCode"
              ngModel
              #promoCode="ngModel">

            <div class="input-group-append">
              <button (click)="checkCouponCode(promoCode.value)" class="btn btn-secondary">Apply Code</button>
            </div>
          </div>

          <div *ngIf="isPromoCodeInvalid" class="coupon-validation">
            Coupon is not valid
          </div>
        </div>
      </ng-container>
    </div>
  `,
  styles: [`
      .coupon-validation {
          width: 100%;
          margin-top: 0.25rem;
          font-size: 80%;
          color: #dc3545;
      }
  `]
})
export class PromoCodeComponent {
  @Input() metadata: IComponent;

  isPromoCodeInvalid: boolean;

  constructor(private http: HttpClient, public storeService: StoreService) {}

  checkCouponCode(promoCode: string) {
    // clear coupon
    if (!promoCode) {
      this.storeService.updateState({ coupon: null });
      return;
    }

    this.http.get('/OnlineRegistration/service/api/validateCouponCode', {
      params: {
        couponCode: promoCode,
        eventId: String(this.storeService.getCurrentState().selectedEvent.eventID),
        programGroupName: 'TNT',
      }
    }).subscribe((res: any) => {
      if (res.couponAmountReduction && res.couponAmountReduction <= 0) {
        this.storeService.updateState({ coupon: null });
        return;
      }

      this.isPromoCodeInvalid = !res.isValid;
      if (res.isValid) {
        this.storeService.updateState({ coupon: res });
      }
    });
  }
}
