import { Directive } from '@angular/core';
import { UntypedFormControl, NG_VALIDATORS, Validator } from '@angular/forms';

@Directive({
  selector: '[atLeastOneUppercaseChar][ngModel]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: AtLeastOneUppercaseCharValidator, multi: true }
  ]
})
export class AtLeastOneUppercaseCharValidator implements Validator {
  validate(control: UntypedFormControl) {
    if (control.value && control.value === control.value.toLowerCase()) {
      return { atLeastOneUppercaseChar: true };
    }
    return null;
  }
}
