<h4 class="mt-3">Create a New LLS Account</h4>
<p class="mb-2">To complete your registration, please create a new LLS account:</p>

<app-sign-in-social signinState="register"></app-sign-in-social>

<h4 class="mt-3">Or, Create a new LLS Account below:</h4>

<form #form="ngForm" (ngSubmit)="submit()">
  <div class="form-row">
    <div class="form-group col-md-6" id="account-firstName">
      <label>First Name</label>
      <input type="text"
             class="form-control"
             name="firstName"
             required
             ngModel
             #firstName="ngModel"
      >
      <control-errors [control]="firstName" name="First name"></control-errors>
    </div>
    <div class="form-group col-md-6" id="account-lastName">
      <label>Last Name</label>
      <input type="text"
             class="form-control"
             name="lastName"
             required
             ngModel
             #lastName="ngModel"
      >
      <control-errors [control]="lastName" name="Last name"></control-errors>
    </div>
  </div>
  <div class="mb-2" id="account-email">
    <label>Account Login Email</label>
    <input type="text"
      class="form-control"
      name="email"
      required
      ngModel
      email
      emailAvailability
      #email="ngModel"
    >
    <control-errors [control]="email" name="Email"></control-errors>
    <div *ngIf="email.touched && email.errors?.emailAvailability" class="invalid-feedback">
      Sorry, this email address is already being used by an LLS account. Please try signing in, or
      <a href="javascript: void(0);" (click)="openResetPasswordForm(email.value)">reset your password</a> if you forgot it.
    </div>
  </div>
  <div class="mb-2 password-requirements"
       [ngClass]="showPasswordHint ? 'show-password-hint' : 'hide-password-hint'">
    <b>Password needs to contain at least:</b>
    <ul>
      <li [class.checked]="!password.errors?.required && !password.errors?.minlength">
        8 characters
      </li>
      <li [class.checked]="!password.errors?.required && !password.errors?.atLeastOneLowercaseChar">
        1 lowercase letter
      </li>
      <li [class.checked]="!password.errors?.required && !password.errors?.atLeastOneUppercaseChar">
        1 uppercase letter
      </li>
      <li [class.checked]="!password.errors?.required && !password.errors?.atLeastOneNumber">
        1 number
      </li>
      <li [class.checked]="!password.errors?.required && !password.errors?.atLeastOneSpecialChar">
        1 special character
      </li>
    </ul>
  </div>
  <div class="mb-2 form-row">
    <div class="form-group col-md-6" id="account-password">
      <label>Password</label>
      <input type="password"
             class="form-control"
             name="password"
             ngModel
             #password="ngModel"
             required
             validCharsOnly
             nospaces
             minlength="8"
             atLeastOneNumber
             atLeastOneLowercaseChar
             atLeastOneUppercaseChar
             atLeastOneSpecialChar
             maxlength128
             (focus)="showPasswordHint = true"
             (blur)="showPasswordHint = false"
             [ngClass]="{ 'invalid-password-confirmation': password.value !== passwordConfirm.value && password.touched && !password.errors }"
      >
      <control-errors [control]="password" name="Password"></control-errors>
    </div>
    <div class="form-group col-md-6" id="account-passwordConfirm">
      <label>Confirm Password</label>
      <input type="password"
             class="form-control"
             ngModel
             #passwordConfirm="ngModel"
             name="passwordConfirm"
             required
             (focus)="showPasswordHint = true"
             (blur)="showPasswordHint = false"
             [ngClass]="{ 'invalid-password-confirmation': password.value !== passwordConfirm.value && password.touched && !password.errors }"
      >
      <control-errors [control]="passwordConfirm" name="Confirm Password"></control-errors>
    </div>
  </div>
  <div *ngIf="password.touched && !password.errors && password.value !== passwordConfirm.value" class="notification-error form-group col-md-12">
    Your password and confirmation do not match.
  </div>
  <div>
    <button type="submit" class="btn btn-primary" [disabled]="!form.valid || password.value !== passwordConfirm.value"
            [ngClass]="{ 'disabled': !form.valid || password.value !== passwordConfirm.value }">Create My Account</button>
  </div>
</form>
