import { AfterViewInit, Component } from '@angular/core';
import { StoreService } from '../../../../services/store.service';
import { HttpClient } from '@angular/common/http';
import { SigninResult } from '../../../../types/auth-types';
import { WizardService } from '../../../../services/wizard.service';
import { ProgressService } from '../../../../services/progress.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-sign-in-login',
  templateUrl: 'login.component.html',
  styles: [`
      .notification {
          background-color: #a8e0bf;
          color: #2a784a;
          border-color: #3eb16d;
          font-weight: 600;
          line-height: 1.2;
          border-radius: 4px;
          padding: 1.5em 1em;
      }
  `]
})
export class LoginComponent implements AfterViewInit {
  creds = {
    username: '',
    password: ''
  };
  loginError: string;
  disabled = false;

  constructor(
    public storeService: StoreService,
    private http: HttpClient,
    private progressService: ProgressService,
    private wizardService: WizardService,
    private toastrService: ToastrService
  ) {
  }

  ngAfterViewInit() {
    const recoverPassword = this.storeService.getCurrentState().recoverPassword;
    setTimeout(() => {
      if (recoverPassword) {
        this.storeService.updateState({recoverPassword: false});
      }
    }, 10000);
  }

  credSignin() {
    this.disabled = true;
    this.progressService.block();
    this.http.post('/OnlineRegistration/service/api/signin', {
      username: this.creds.username,
      password: this.creds.password,
      programGroupName: this.storeService.getCurrentState().pgConfig.programGroupName,
      eventID: this.storeService.getCurrentState().selectedEvent?.eventID
    }).subscribe((res: SigninResult) => {
      this.progressService.unblock();

      if (res.loginStatus === 'STATUS_LOGIN_FAILED') {
        this.loginError = 'Invalid Username or Password';
        this.disabled = false;
        return;
      } else {
        this.disabled = false;
        if (!res.userRegistered) {
          // @TODO: some prefill code from legacy
        } else {
          this.loginError = 'User already registered.';
          return;
        }
      }
      this.loginError = null;
      this.storeService.updateState({ signinResult: res });
      this.wizardService.nextStep();
      this.disabled = false;
    }, () => {
      this.progressService.unblock();
      this.toastrService.error('Something went wrong.');
    });
  }

  openTemporaryPasswordForm() {
    this.storeService.updateState({ signinState: 'forgot-password' });
  }
}
