// Subcomponents
import { EventSearchByZipComponent } from '../components/steps/event-search/subcomponents/event-search-by-zip.component';
import { EventSearchByKeywordComponent } from '../components/steps/event-search/subcomponents/event-search-by-keyword.component';
import { EventSearchByTypeComponent } from '../components/steps/event-search/subcomponents/event-search-by-type.component';
import { EventSearchResultComponent } from '../components/steps/event-search/subcomponents/event-search-result/event-search-result.component';
import { SelectedEventDetailsComponent } from '../components/steps/event-search/subcomponents/selected-event-details.component';
import { NoEventFoundComponent } from '../components/steps/event-search/subcomponents/no-event-found.component';
import { SelectedEventDetailsWRegFeeComponent } from '../components/steps/event-search/subcomponents/selected-event-details-w-reg-fee.component';
import { FundraisingGoalComponent } from '../components/steps/event-specific-information/subcomponents/fundraising-goal.component';
import { SportCourseComponent } from '../components/steps/event-specific-information/subcomponents/sport-course.component';
import { FundraisingLevelComponent } from '../components/steps/event-specific-information/subcomponents/fundraising-level.component';
import { TrainingLocationComponent } from '../components/steps/event-specific-information/subcomponents/training-location.component';
import { WaysToParticipateComponent } from '../components/steps/event-specific-information/subcomponents/ways-to-participant/ways-to-participate.component';
import { ParticipateJoinTeamComponent } from '../components/steps/event-specific-information/subcomponents/ways-to-participant/participate-join-team.component';
import { ParticipateCreateTeamComponent } from '../components/steps/event-specific-information/subcomponents/ways-to-participant/participate-create-team.component';
import { ParticipateIndividualComponent } from '../components/steps/event-specific-information/subcomponents/ways-to-participant/participate-individual.component';
import { NoTeamResultFoundComponent } from '../components/steps/event-specific-information/subcomponents/ways-to-participant/join-team/no-team-result.component';
import { JoinTeamComponent } from '../components/steps/event-specific-information/subcomponents/ways-to-participant/join-team/join-team.component';
import { JoinTeamSearchComponent } from '../components/steps/event-specific-information/subcomponents/ways-to-participant/join-team/team-search.component';
import { TeamSearchResultComponent } from '../components/steps/event-specific-information/subcomponents/ways-to-participant/join-team/search-result.component';
import { SelectedTeamDetailsComponent } from '../components/steps/event-specific-information/subcomponents/ways-to-participant/join-team/team-details.component';
import { NoTeamFoundComponent } from '../components/steps/event-specific-information/subcomponents/ways-to-participant/join-team/no-team-found.component';
import { TShirtComponent } from '../components/steps/event-specific-information/subcomponents/t-shirt.component';
import { CreateTeamComponent } from '../components/steps/event-specific-information/subcomponents/ways-to-participant/create-team/create-team.component';
import { CreateTeamNameComponent } from '../components/steps/event-specific-information/subcomponents/ways-to-participant/create-team/create-team-name.component';
import { CreateTeamTypeComponent } from '../components/steps/event-specific-information/subcomponents/ways-to-participant/create-team/create-team-type.component';
import { CreateTeamFundraiserStartEnd } from '../components/steps/event-specific-information/subcomponents/ways-to-participant/create-team/create-team-frstartend.component';
import { UploadTeamImageComponent } from '../components/steps/event-specific-information/subcomponents/ways-to-participant/create-team/upload-team-image.component';
import { NationalTeamComponent } from '../components/steps/event-specific-information/subcomponents/ways-to-participant/create-team/national-team.component';
import { SelectedCandidateDetailsComponent } from '../components/steps/event-specific-information/subcomponents/ways-to-participant/join-team/candidate-detail.component';
import { ContactInfoComponent } from '../components/steps/personal-info/subcomponents/contact-info/contact-info.component';
import { TermsConditionsComponent } from '../components/steps/payment/subcomponents/terms-conditions.component';
import { SignatureComponent } from '../components/steps/payment/subcomponents/signature.component';
import { DateOfBirthComponent } from '../components/steps/personal-info/subcomponents/date-of-birth.component';
import { CaptchaComponent } from '../components/steps/payment/subcomponents/captcha.component';
import { WaiverComponent } from '../components/steps/payment/subcomponents/waiver.component';
import { KickstartComponent } from '../components/steps/payment/subcomponents/kickstart.component';
import { PaymentFormComponent } from '../components/steps/payment/subcomponents/payment-form/payment-form.component';
import { RegisteredEventDetailsComponent } from '../components/steps/confirmation/subcomponents/event-details.component';
import { PromoCodeComponent } from '../components/steps/payment/subcomponents/promo-code.component';
import { AmountComponent } from '../components/steps/payment/subcomponents/amount.component';
import { RegFeeComponent } from '../components/steps/payment/subcomponents/reg-fee.component';
import { DoubleTheDonationComponent } from '../components/steps/payment/subcomponents/double-the-donation.component';
import { FundraisingMattersComponent } from '../components/steps/confirmation/subcomponents/fundraising-matters.component';
import { RegisteredCandidateDetailsComponent } from '../components/steps/confirmation/subcomponents/candidate-details.component';
import { YoutubeVideoComponent } from '../components/steps/confirmation/subcomponents/youtube-video.component';
import { RegistrationFeeComponent } from '../components/steps/confirmation/subcomponents/registration-fee.component';
import { NoCandidateResultFoundComponent } from '../components/steps/event-specific-information/subcomponents/ways-to-participant/join-team/no-candidate-result.component';
import { NoCandidateFoundComponent } from '../components/steps/event-specific-information/subcomponents/ways-to-participant/join-team/no-candidate-found.component';

export const SubcomponentMappings = {
  EVENT_SEARCH_BY_ZIP: EventSearchByZipComponent,
  EVENT_SEARCH_BY_KEYWORD: EventSearchByKeywordComponent,
  EVENT_SEARCH_BY_EVENT_TYPE: EventSearchByTypeComponent,
  EVENT_SEARCH_RESULT: EventSearchResultComponent,
  SELECTED_EVENT_DETAILS: SelectedEventDetailsComponent,
  SELECTED_EVENT_DETAILS_W_REG_FEE: SelectedEventDetailsWRegFeeComponent,
  NO_EVENT_FOUND: NoEventFoundComponent,
  SPORT_COURSE: SportCourseComponent,
  FUNDRAISING_LEVEL: FundraisingLevelComponent,
  FUNDRAISING_GOAL: FundraisingGoalComponent,
  TRAINING_LOCATION: TrainingLocationComponent,
  WAYS_TO_PARTICIPATE: WaysToParticipateComponent,
  WAYS_TO_PARTICIPATE_JOIN_TEAM: ParticipateJoinTeamComponent,
  WAYS_TO_PARTICIPATE_CREATE_TEAM: ParticipateCreateTeamComponent,
  WAYS_TO_PARTICIPATE_INDIVIDUAL: ParticipateIndividualComponent,
  JOIN_TEAM: JoinTeamComponent,
  NO_TEAM_RESULT_FOUND: NoTeamResultFoundComponent,
  NO_CANDIDATE_RESULT_FOUND: NoCandidateResultFoundComponent,
  JOIN_TEAM_SEARCH: JoinTeamSearchComponent,
  SEARCH_ALL_TEAMS: JoinTeamSearchComponent,
  TEAM_SEARCH_RESULT: TeamSearchResultComponent,
  SELECTED_TEAM_DETAILS: SelectedTeamDetailsComponent,
  SELECTED_CANDIDATE_DETAILS: SelectedCandidateDetailsComponent,
  NO_TEAM_FOUND: NoTeamFoundComponent,
  NO_CANDIDATE_FOUND: NoCandidateFoundComponent,
  T_SHIRT_SIZE: TShirtComponent,
  CREATE_TEAM: CreateTeamComponent,
  CREATE_TEAM_NAME: CreateTeamNameComponent,
  UPLOAD_TEAM_IMAGE: UploadTeamImageComponent,
  CREATE_TEAM_SELECT_TEAM_TYPE: CreateTeamTypeComponent,
  CREATE_TEAM_SELECT_FR_STARTEND: CreateTeamFundraiserStartEnd,
  CREATE_TEAM_SELECT_NATIONAL_TEAM: NationalTeamComponent,
  CONTACT_INFO: ContactInfoComponent,
  'T&C': TermsConditionsComponent,
  SIGNATURE: SignatureComponent,
  DATE_OF_BIRTH: DateOfBirthComponent,
  CAPTCHA: CaptchaComponent,
  WAIVER: WaiverComponent,
  KICKSTART: KickstartComponent,
  PROMO_CODE: PromoCodeComponent,
  AMOUNT: AmountComponent,
  REG_FEE: RegFeeComponent,
  PAYMENT_FORM: PaymentFormComponent,
  FUNDRAISING_MATTERS: FundraisingMattersComponent,
  DOUBLE_THE_DONATION: DoubleTheDonationComponent,
  REGISTERED_EVENT_DETAILS: RegisteredEventDetailsComponent,
  REGISTERED_CANDIDATE_DETAILS: RegisteredCandidateDetailsComponent,
  YOUTUBE_VIDEO: YoutubeVideoComponent,
  REGISTRATION_FEE: RegistrationFeeComponent
};
