<div *ngIf="(storeService.vm$ | async) as vm">
  <div *ngIf="vm.signinState !== 'forgot-password'" class="signin-wrapper">
    <div class="signin-login">
      <app-sign-in-login></app-sign-in-login>
    </div>
    <div class="separator"></div>
    <div class="signin-register">
      <app-sign-in-create-account></app-sign-in-create-account>
    </div>
  </div>
  <app-sign-in-forgot-password *ngIf="vm.signinState === 'forgot-password'"></app-sign-in-forgot-password>
</div>
<div class="text-right mt-2">
  <button (click)="previousStep()" class="btn btn-secondary mr-1">Previous</button>
</div>
